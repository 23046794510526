require('./bootstrap');
// require('./main_navigation');

// Lightbox
window.GLightbox = require('glightbox');

// window.Vue = require('vue');
import Vue from 'vue';

import store from './store';

Vue.config.ignoredElements = ['prog-connect'];

// vue currency input
// import VueCurrencyInput from 'vue-currency-input';
// Vue.use(VueCurrencyInput);
// const pluginOptions = {

//     // see config reference 
//     globalOptions: { 
//         currency: 'USD', 
//         precision: 2,
//     }
// };
// Vue.use(VueCurrencyInput, pluginOptions);
// end vue currency input

import VueMask from 'v-mask';
Vue.use(VueMask);

import VModal from 'vue-js-modal';
Vue.use(VModal);

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

import Slider from './components/Slider.vue';
import Insert from './components/Insert.vue';
import PurchaseOptions from './components/PurchaseOptions.vue';
import Accordion from './components/Accordion.vue';
import LoginForm from './components/LoginForm.vue';
import UpdateEmail from './components/UpdateEmail.vue';
import ResetPassword from './components/ResetPassword.vue';
import ChangePassword from './components/ChangePassword.vue';
import RegistrationForm from './components/RegistrationForm.vue';
import MyaccountPayment from './components/MyaccountPayment.vue';
import UpdateBilling from './components/UpdateBilling.vue';
import RemoveCartItemButton from './components/RemoveCartItemButton.vue';
import ShoppingCartSummary from './components/ShoppingCartSummary.vue';
import CheckoutBilling from './components/CheckoutBilling.vue';
import CheckoutShipping from './components/CheckoutShipping.vue';
import CheckoutPayment from './components/CheckoutPayment.vue';
import ShoppingCartItems from './components/ShoppingCartItems.vue';
import ShoppingCartShipping from './components/ShoppingCartShipping.vue';
import ShoppingCartCoupon from './components/ShoppingCartCoupon.vue';
import ShoppingCartBillingShippingDetails from './components/ShoppingCartBillingShippingDetails.vue';
import MyAccountBillingShipping from './components/MyAccountBillingShipping.vue';
import MyAccountMakePayment from './components/MyAccountMakePayment.vue';
import AddToCart from './components/AddToCart.vue';
import DeliveryTracking from './components/DeliveryTracking.vue';
import ShowShieldPricing from './components/ShowShieldPricing.vue';
// import ProgConnect from 'progressive_connect.js';
import MattressPage from './components/furniture_category/MattressPage.vue';



const app = new Vue({
    store,
    el: '#app',

    components: { 
        'slider': Slider,
        'insert': Insert,
        'purchase-options': PurchaseOptions,
        'accordion': Accordion,
        'login-form': LoginForm,
        'update-email': UpdateEmail,
        'reset-password': ResetPassword,
        'change-password': ChangePassword,
        'registration-form': RegistrationForm,
        'myaccount-payment': MyaccountPayment,
        'update-billing': UpdateBilling,
        'remove-cart-item-button': RemoveCartItemButton,
        'shopping-cart-summary': ShoppingCartSummary,
        'checkout-billing': CheckoutBilling,
        'checkout-shipping': CheckoutShipping,
        'checkout-payment': CheckoutPayment,
        'shopping-cart-items': ShoppingCartItems,
        'shopping-cart-shipping': ShoppingCartShipping,
        'shopping-cart-coupon': ShoppingCartCoupon,
        'shopping-cart-billing-shipping-details': ShoppingCartBillingShippingDetails,
        'my-account-billing-shipping': MyAccountBillingShipping,
        'my-account-make-payment': MyAccountMakePayment,
        'add-to-cart': AddToCart,
        'delivery-tracking': DeliveryTracking,
        'add-to-cart': AddToCart,
        'show-shield-pricing': ShowShieldPricing,
        // 'progressive_connect': ProgConnect,
        'mattress-page': MattressPage,
    }
});