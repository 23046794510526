<template>
    <div class="max-w-screen-sm mx-auto my-8 p-1 sm:my-12 sm:p-2 sm:border sm:border-gray-500 sm:rounded sm:shadow-all-around-15">
    
        <div class="w-full px-2 sm:px-4 text-base text-gray-700">

            <h1 class="text-3xl mt-2 mb-3 text-blue-700">
                Update Email
            </h1>

            <p class="mb-5">
                Use this form to update your account email address.
            </p>

            <p class="mb-5">
                Your current email: 
                <span class="ml-1 text-red-700">
                    {{ this.current_user_email }}
                </span>
            </p>

            <div v-if="displaySuccessMessage" class="flex items-center mb-8 p-4 bg-green-200 border border-green-500 rounded">
                <div class="ml-0 sm:ml-4">
                    <h4 class="mb-2 text-xl text-green-700">Your email has been updated.</h4>
                </div>
            </div>

            <show-form-validation-errors v-if="validationErrors" :errors="validationErrors" class="my-4">

            </show-form-validation-errors>


            <form @submit.prevent="processEmailUpdate">

                <slot></slot>

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="email" name="email" v-model="form.email" placeholder="Email" required>

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="email" name="email_confirmation" v-model="form.email_confirmation" placeholder="Confirm Email" required>
                
                
                <div v-if="displaySpinner">
                <h2 class="text-2xl mb-4 text-blue-700 text-center">
                    Please wait.
                </h2>
                <div class="mb-4 text-center">

                    <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                        <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                        c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="2s" 
                                from="0 50 50"
                                to="360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                        <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                        c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="1s" 
                                from="0 50 50"
                                to="360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                        <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                        L82,35.7z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="1.5s" 
                                from="0 50 50"
                                to="-360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
            </div>

            <div v-if="! displaySpinner">
                <button role="submit" v-if="! disableButton" class="inline-block px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-600 uppercase tracking-wider text-white focus:shadow-outline focus:outline-none">
                    Update My Email
                </button>
                
                <div v-if="disableButton" class="inline-block px-6 py-2 mb-6 border border-gray-400 rounded bg-gray-300 uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                    Fill Out The Form
                </div>
            </div>

            </form>

        </div>

    </div>
</template>


<script>

import ShowFormValidationErrors from './ShowFormValidationErrors.vue';

export default {

    components: {
        'show-form-validation-errors': ShowFormValidationErrors
    },

    props: [
        'user_email',
    ],

    data: function () {

        return {
            form: {
                email: '',
                email_confirmation: '',
            },
            current_user_email: this.user_email,
            watch_email: false,
            watch_email_confirmation: false,
            disableButton: true,
            displaySpinner: false,
            validationErrors: '',
            // displayErrors: false,
            displaySuccessMessage: false,
        }
    },

    // mounted: function () {

    //     if (this.email.length > 6)
    //     {
    //         this.watch_email = true;
    //     }
    // },

    watch:{

        'form.email': function(val, oldVal)
        {
            if (this.form.email.length > 6) 
            {
                this.watch_email = true;
                this.checkFormVariables();

            } else {

                this.disableButton  = true;
                this.displaySpinner = false;
                this.watch_email    = false;
            }
        },

        'form.email_confirmation': function(val, oldVal)
        {
            if (this.form.email_confirmation.length > 1)
            {
                this.watch_email_confirmation = true;
                this.checkFormVariables();

            } else {

                this.disableButton  = true;
                this.displaySpinner = false;
                this.watch_email_confirmation = false;
            }
        },
    },

    methods:{
        checkFormVariables ()
        {
            if(this.watch_email == true && this.watch_email_confirmation == true)
            {
                this.disableButton  = false;
                // this.displaySpinner = true;
            }
        },

        processEmailUpdate ()
        {
            this.validationErrors = '';
            this.displaySpinner   = true;

            axios.post('/myaccount/update-user-email', this.form)
                .then((response) => {

                    this.displaySuccessMessage   = true;
                    this.displaySpinner          = false;
                    this.current_user_email      = response.data;
                    this.disableButton           = true;
                    this.form.email_confirmation = '';
                    this.form.email              = '';

                })

                .catch((error) => {
                    
                    this.validationErrors = error.response.data.errors;
                    this.displaySpinner   = false;
                });
        }

        // onFormSubmit ()
        // {
        //     this.displaySpinner = true;
        // },

        // showSpinner ()
        // {
        //     this.$swal.fire({
        //         title: 'Processing ...',
        //         text: 'Something went wrong!',
        //         html:
        //         '<div style="margin:30px;"><i class="fa fa-spinner fa-pulse fa-5x fa-fw" style="color: #2980b9;"></i></div>' +
        //         '<p style="font-size: 18px;">Please wait. Do not refresh the page.</p>',
        //         focusConfirm: false,
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         showConfirmButton: false,
        //     })
        // },

    }
}

</script>
