<template>
    <div class="flex flex-wrap max-w-screen-xl">
        <div class="w-full lg:w-1/2 mb-4 text-base text-gray-700">
            <div class="mx-2 my-1 sm:mx-8 lg:mx-3 border border-gray-400 rounded overflow-hidden shadow-md">
                <h2 class="mb-3 px-2 py-1 sm:px-5 bg-gray-200 text-xl text-blue-700">
                    Billing Information
                    <div @click="showConfirmBillingModal" class="inline-block float-right">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 float-right hover:cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </h2>

                <p class="mx-2 sm:px-5 mb-2">
                    {{ userBillingShipping.billing_name }}
                </p>

                <p class="mx-2 sm:px-5">
                    {{ userBillingShipping.billing_address }}
                </p>

                <p class="mx-2 sm:px-5 mb-1">
                    {{ userBillingShipping.billing_city }}, {{ userBillingShipping.billing_state }} {{ userBillingShipping.billing_zip }}
                </p>

                <p class="mx-2 sm:px-5 mb-3">
                    {{ userBillingShipping.billing_phone }}
                </p>

                <div class="m-2 mb-2 p-2 bg-blue-200 border border-blue-500 rounded"> 
                    <div class="ml-0 sm:ml-4">
                        <p class="text-base text-blue-800">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            The billing address on invoice must match the billing address on your credit card. Otherwise your payment will be declined.
                        </p>
                    </div>
                </div>
                
            </div>
        </div> 

 
        <div class="w-full lg:w-1/2 mb-4 text-base text-gray-700">
            <div class="mx-2 my-1 sm:mx-8 lg:mx-3 border border-gray-400 rounded overflow-hidden shadow-md">
                <h2 class="mb-3 px-2 py-1 sm:px-5 bg-gray-200 text-xl text-blue-700">
                    Shipping Information
                </h2>

                <p class="mx-2 sm:px-5 mb-2">
                    {{ userBillingShipping.shipping_name }}
                </p>

                <p class="mx-2 sm:px-5">
                    {{ userBillingShipping.shipping_address }}
                </p>

                <p class="mx-2 sm:px-5 mb-1">
                    {{ userBillingShipping.shipping_city }}, {{ userBillingShipping.shipping_state }} {{ userBillingShipping.shipping_zip }}
                </p>

                <p class="mx-2 sm:px-5 mb-3">
                    {{ userBillingShipping.shipping_phone }}
                </p>

                <div class="m-2 mb-2 p-2 bg-blue-200 border border-blue-500 rounded"> 
                    <div class="ml-0 sm:ml-4">
                        <p class="text-base text-blue-800">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Upon delivery or pickup customer must present original credit card.
                            A picture of the photo ID will be taken at the time of delivery or pickup.*
                        </p>
                    </div>
                </div>
                
            </div>
        </div>

        

    </div>
</template>

<script>

export default {

    props: [
        'billing_shipping_details'
    ],

    data () {
        return {
            
        }
    },

    computed: {
        userBillingShipping: {
            get() {
                return this.$store.state.cartUserBillingShipping.userBillingShipping;
            }
        },

        paymentProcess: {
            get() {
                return this.$store.state.myAccountPaymentSteps.paymentSteps;
            }
        }
    },

    mounted() {
        this.$store.dispatch('cartUserBillingShipping/initializeUserBillingShipping', this.billing_shipping_details);
    },

    methods: {

        showConfirmBillingModal: function()
        {
            this.$modal.show('my-account-confirm-billing-modal');
        },

        hideConfirmBillingModal: function()
        {
            this.$modal.hide('my-account-confirm-billing-modal');
        },

    }

}

</script>
