<template>
    <div class="mb-5 my-4">
        <button @click="isOpen = !isOpen" class="flex justify-between items-center w-full p-2 border border-blue-300 bg-blue-100 focus:outline-none text-lg text-blue-700 hover:bg-blue-500 hover:text-white">
            <h2 class="text-left">
                {{ question }}   
            </h2>

            <i v-if="isOpen" class="fa fa-angle-up fa-lg ml-1" aria-hidden="true"></i>
            <i v-if="!isOpen" class="fa fa-angle-down fa-lg ml-1" aria-hidden="true"></i>
        </button>

        <p v-if="isOpen" class="p-4 border-l border-r border-b border-blue-300">
            <slot></slot>
        </p>

    </div>
</template>

<script>
    export default {
        props: [
            'question'
        ],
        data: function () {
            return {
                isOpen: false
            }
        }
    }
</script>
