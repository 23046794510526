<template>
    <div class="mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">

        <h1 class="text-2xl text-blue-700 text-center">
            Your Shopping Cart 
            <span 
                v-if="! displayShoppingCartItems" @click="displayShoppingCartItems = !displayShoppingCartItems" 
                class="float-right mr-2 px-2 py-1 bg-blue-500 rounded border border-blue-500 shadow-md text-lg text-white hover:bg-blue-300 hover:text-gray-900 hover:cursor-pointer"
            >
                View
            </span>
        </h1>

        <div v-if="displayShoppingCartItems" class="mt-4">

            <div v-for="(item, index) in items" :key="index" class="w-full mb-6 p-2 sm:p-4 md:p-6 border border-gray-400 rounded bg-white sm:shadow-all-around-10">
                
                <div v-if="item.item_details.department === '47' &&  item.item_details.vendorcode != 'CLAS-' && shippingQuote.local_delivery_area != 'yes' " class="w-full mb-4 p-2 sm:p-4 md:p-6 bg-red-700 rounded-md sm:shadow-all-around-5">
                    <div class="text-white text-lg font-semibold tracking-wider">
                        
                        <svg class="inline-block h-8 w-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                        This item is only for pickup or local delivery. 
                        <a href="#calculate-shipping" class="hover:underline"> 
                            Check your zip code here
                        </a>.
                    </div>
                </div>


                <div v-if="item.item_details.department === '47.5' && shippingQuote.local_delivery_area != 'yes' " class="w-full mb-4 p-2 sm:p-4 md:p-6 bg-red-700 rounded-md sm:shadow-all-around-5">
                    <div class="text-white text-lg font-semibold tracking-wider">
                        
                        <svg class="inline-block h-8 w-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                        This item is only for pickup or local delivery. 
                        <a href="#calculate-shipping" class="hover:underline"> 
                            Check your zip code here
                        </a>.
                    </div>
                </div>


                <a v-bind:href="'/item/'+ item.skuserial">
                    <div class="flex flex-wrap mb-3 hover:cursor-pointer">

                        <div v-if="item.item_details.first_image === ''" class="w-full sm:w-1/4 mb-2">
                            <img class="rounded" src="/img/no-image-available.jpg" alt="The image is not available">
                        </div>

                        <div v-else class="w-full sm:w-1/4 mb-2">
                            <img class="rounded" :src="'/images/' + item.item_details.first_image" :alt="item.item_details.shortdescription">
                        </div>

                        <div class="w-full sm:w-3/4 sm:pl-4">
                            <p class="mb-2">
                                <span class="mr-2 text-gray-600 italic">SKU serial:</span> 
                                <span class="text-lg font-semibold">{{ item.skuserial }}</span>
                            </p>

                            <p v-if="item.item_details.shortdescription === ''" class="text-blue-600 text-lg sm:text-xl font-semibold leading-tight sm:leading-snug">
                                    {{ item.item_details.description }}
                            </p>

                            <p v-else class="text-blue-600 text-lg sm:text-xl font-semibold leading-tight sm:leading-snug">
                                {{ item.item_details.shortdescription }}
                            </p>
                        </div>

                    </div>
                </a>

                <div class="">
                    <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                        <p class="text-gray-600 italic">
                            Availability:
                        </p>
                        
                        <p class="text-lg font-semibold">
                            {{ item.item_details.availability }}
                        </p>     
                    </div>

                    

                    <div class="flex justify-between items-center mb-2 pb-2 border-b border-gray-400"> 
                        <div class="text-gray-600 italic">
                            Quantity:
                        </div>
                        
                        <div v-if="checkoutProcess.synchronyApproved != 'success' && checkoutProcess.progressiveApproved != 'success'" 
                            class="flex justify-center items-center text-lg font-semibold"
                        >
                            <form method="POST" action="/decrement-shopping-cart-item" class="flex justify-center"  v-on:submit="showSpinnerModal">
                                <input type="hidden" name="_token" :value="csrf">  
                                <input type="hidden" name="skuserial" :value="item.item_details.skuserial">
                                <input type="hidden" name="quantity" :value="item.shopping_cart_qty">

                                <button type="submit" class="inline-block hover:cursor-pointer hover:text-green-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </form>

                            <div class="inline-block mx-2 px-3 border-2 border-gray-400 rounded text-center">
                                {{ item.shopping_cart_qty }}
                            </div>

                            <form v-if="(parseInt(item.shopping_cart_qty) < parseInt(item.item_details.max_qty))" 
                                method="POST" action="/increment-shopping-cart-item" 
                                class="flex justify-center"  
                                v-on:submit="showSpinnerModal">
                                <input type="hidden" name="_token" :value="csrf">  
                                <input type="hidden" name="skuserial" :value="item.item_details.skuserial">
                                <input type="hidden" name="quantity" :value="item.shopping_cart_qty">

                                <button type="submit" class="inline-block hover:cursor-pointer hover:text-green-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </form>

                            <div v-if="(parseInt(item.shopping_cart_qty) >= parseInt(item.item_details.max_qty))" 
                                class="text-gray-400 hover:cursor-not-allowed">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>

                        <div v-if="checkoutProcess.synchronyApproved == 'success' || checkoutProcess.progressiveApproved == 'success'" class="text-lg font-semibold"> 
                            <div class="flex justify-end">
                                <div class="mr-2 text-gray-400 hover:cursor-not-allowed">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>

                                <div class="w-10 mr-2 border-2 border-gray-400 rounded text-center">
                                    {{ item.shopping_cart_qty }}
                                </div>

                                <div class="text-gray-400 hover:cursor-not-allowed">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                        <p class="text-gray-600 italic">
                        Price:
                        </p>
                        
                        <p class="text-lg font-semibold">
                            ${{ formatItemTotal(item.item_details.retail1 * item.shopping_cart_qty) }}
                        </p>     
                    </div>
                </div>


                <div v-if="item.item_details.shield_eligibility == 'yes'" class="mb-2 -mt-1 pt-3 border-t border-gray-400">
                    <div v-if="item.item_details.shield_selected != 'yes'" 
                        class="text-lg text-red-700 font-medium"
                    >
                        <div v-if="checkoutProcess.synchronyApproved != 'success' && checkoutProcess.progressiveApproved != 'success'" class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="block w-6 h-6 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                            </svg>

                            <p>
                                Protect your purchase!
                            </p>
                        </div>

                        <div v-if="checkoutProcess.synchronyApproved == 'success' || checkoutProcess.progressiveApproved == 'success'" class="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="block w-6 h-6 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                            </svg>

                            <p>
                                Not Protected.
                            </p>
                        </div>
                    </div>

                    <div v-if="item.item_details.shield_selected == 'yes'" 
                        class="flex items-center text-lg text-green-700 font-medium"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="block w-6 h-6 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                        </svg>
                        <p>
                            Protected.
                        </p>
                    </div>
                </div>

                
                <div v-if="checkoutProcess.synchronyApproved != 'success' && checkoutProcess.progressiveApproved != 'success'">
                    <div v-if="item.item_details.shield_eligibility == 'yes'" class="mb-6">
                        <div class="flex flex-wrap mb-2">
                            <div class="flex justify-start items-center mb-1">
                                <input class="w-5 h-5 mr-3 hover:cursor-pointer" 
                                    type="checkbox" 
                                    name="shield_protection" 
                                    id="item.skuserial" 
                                    :value="item.skuserial" 
                                    v-model="item.item_details.shield_selected" 
                                    @change="updateShieldProtection($event, index, item.skuserial, item.shopping_cart_qty, shoppingCartSummary.cart_id)"> 
                                <label for="item.skuserial" class="font-semibold">
                                    3 year accidental protection. 
                                </label>
                            </div>

                            <div class="ml-8 mr-2 mb-1">
                                <span @click="showProtectionPolicyModal" class="text-blue-800 font-semibold hover:cursor-pointer hover:underline">
                                    What's protected?
                                </span>
                            </div>
                        </div>

                        <div class="ml-8 text-sm italic">
                            (See price in Cart Summary for all protected items)
                        </div>
                    </div>
                </div>


                <div v-if="checkoutProcess.synchronyApproved == 'success' || checkoutProcess.progressiveApproved == 'success'">
                    <div v-if="item.item_details.shield_eligibility == 'yes'" class="mb-6">
                        <div class="flex flex-wrap mb-2">
                            <div class="flex justify-start items-center mb-1">
                                <div v-if="item.item_details.shield_selected == 'yes'" class="mr-3 p-1 rounded bg-blue-600 text-white hover:cursor-not-allowed">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                </div>

                                <div v-if="item.item_details.shield_selected != 'yes'" class="mr-3 w-5 h-5 rounded bg-white text-white border border-gray-500 hover:cursor-not-allowed">
                                    
                                </div>
                                
                                <span class="mr-4 font-semibold">3 year accidental protection</span>
                            </div>

                            <div class="ml-8 mr-2 mb-1">
                                <span @click="showProtectionPolicyModal" class="text-blue-800 font-semibold hover:cursor-pointer hover:underline">
                                    What's protected?
                                </span>
                            </div>
                        </div>

                        <div class="ml-8 text-sm italic">
                            (See price in Cart Summary for all protected items)
                        </div>
                    </div>
                </div>


                <div class="flex justify-end mb-3 -mt-1 sm:mb-2 md:mb-0 pt-5 border-t border-gray-400">
                    <div v-if="checkoutProcess.synchronyApproved != 'success' && checkoutProcess.progressiveApproved != 'success'">
                        <form method="POST" action="/delete-shopping-cart-item" v-on:submit="showSpinnerModal">
                            <input type="hidden" name="_token" :value="csrf">
                            <input type="hidden" name="skuserial" :value="item.item_details.skuserial">
                            <button 
                                type="submit" 
                                class="px-4 py-2 rounded border border-red-700 bg-red-50 hover:bg-red-700 text-red-900 hover:text-white font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300" >
                                Remove Item
                            </button>
                        </form>
                    </div>
                </div>
            </div>

        </div>

        <div v-if="checkoutProcess.synchronyApproved != 'success' && checkoutProcess.progressiveApproved != 'success'" class="mb-4 text-center">
            <a :href="recently_viewed_category" 
                class="px-6 py-3 rounded-full border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300" >
                Continue Shopping
            </a>
        </div>

        <protection-policy></protection-policy>

    </div>
</template>


<script>

import ProtectionPolicy from './modals/ProtectionPolicy.vue';

export default {

    components: {
        'protection-policy': ProtectionPolicy
    },

    props: [
        'cart_items',
        'recently_viewed_category'
    ],

    data: function () {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            displayMattressWarningMessage: false,
            displayShoppingCartItems: true,
            items: this.cart_items,

            cartShieldProduct: this.shieldProduct,
            itemIndex: '',
        }
    },

    computed: {
        shippingQuote: {
            get() {
                return this.$store.state.cartShipping.shippingQuote;
            }
        },

        checkoutProcess: {
            get() {
                return this.$store.state.cartCheckoutProcess.checkoutSteps;
            }
        },

        cartItems: {
            get() {
                return this.$store.state.cartItems.items;
            }
        },

        shoppingCartSummary: {
            get() {
                return this.$store.state.cartDetails.cartSummary;
            }
        },

        shieldProduct: {
            get() {
                return this.$store.state.cartShield.shieldProduct;
            }
        }
    },

    mounted() {

        this.$store.dispatch('cartItems/initializeCartItems', this.cart_items);

    },

    watch: {

        cartItems: {
            handler(val) {
                
                this.updateItems();
            },

            deep: true
        }
    },

    

    methods: {

        updateItems: function()
        {
            this.items = this.cartItems;
        },

        showSpinnerModal: function()
        {
            this.$modal.show('spinner');
        },

        hideSpinnerModal: function()
        {
            this.$modal.hide('spinner');
        },

        showProtectionPolicyModal: function()
        {
            this.$modal.show('protection-policy');
        },

        hideProtectionPolicyModal: function()
        {
            this.$modal.hide('protection-policy');
        },

        formatItemTotal (value)
        {
            return parseFloat(value).toFixed(2);
        },

        updateShieldProtection: function(e, index, item_skuserial, item_qty, cart_id)
        {
            this.itemIndex = index;

            if (e.target.checked) 
            {
                // Upholstery Checked
                axios.post('/update-cart-shield-protection', {
                    _token:   this.csrf,
                    skuserial:  item_skuserial,
                    shield_selected: 'yes',
                    shopping_cart_id: cart_id
                })

                .then(this.shieldCheckedSuccess)

                .catch(this.shieldCheckedError);

            } else {

                // Upholstery Unchecked
                axios.post('/update-cart-shield-protection', {
                    _token:   this.csrf,
                    skuserial:  item_skuserial,
                    shield_selected: 'no',
                    shopping_cart_id: cart_id
                })

                .then(this.shieldUncheckedSuccess)

                .catch(this.shieldCheckedError);
            } 
        },

        shieldCheckedSuccess: function(response)
        {
            this.cartItems[this.itemIndex].item_details.shield_selected = 'yes';
            this.$store.dispatch('cartItems/updateCartItems', this.cartItems);

            this.cartShieldProduct = response.data;
            this.$store.dispatch('cartShield/updateCartShield', this.cartShieldProduct);

            if (response.data.length != 0)
            {
                this.shoppingCartSummary.shield = response.data['shield_retail'];
                this.$store.dispatch('cartDetails/updateCartSummary', this.shoppingCartSummary);

            } else {
                
                this.shoppingCartSummary.shield = 0;
                this.$store.dispatch('cartDetails/updateCartSummary', this.shoppingCartSummary);
            } 
        },

        shieldUncheckedSuccess: function(response)
        {
            this.cartItems[this.itemIndex].item_details.shield_selected = null;
            this.$store.dispatch('cartItems/updateCartItems', this.cartItems);

            this.cartShieldProduct = response.data;
            this.$store.dispatch('cartShield/updateCartShield', this.cartShieldProduct);

            if (response.data.length != 0)
            {
                this.shoppingCartSummary.shield = response.data['shield_retail'];
                this.$store.dispatch('cartDetails/updateCartSummary', this.shoppingCartSummary);

            } else {
                
                this.shoppingCartSummary.shield = 0;
                this.$store.dispatch('cartDetails/updateCartSummary', this.shoppingCartSummary);
            } 
        },

        shieldCheckedError: function(error)
        {
            if (error.response.status == 422) {
                
                Swal.fire({
                    title: 'Error.',
                    html:
                        '<div style="margin:30px;"></div>' +
                        '<p class="text-lg leading-relaxed">' +
                        error.response.data.errors +
                        '</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(true);
                    }
                });
            }

            if (error.response.status == 419) {
                
                Swal.fire({
                    title: 'Error.',
                    html:
                        '<div style="margin:30px;"></div>' +
                        '<p class="text-lg leading-relaxed">' +
                        'Your session expired due to idle timeout. Please refresh the page.' +
                        '</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(true);
                    }
                });
            }
        },

        ProtectionPolicy: function()
        {
            this.showProtectionPolicyModal();
        }
    }
}

</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: rgb(0, 44, 126);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 10%;
  margin-left: -93px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 89%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: rgb(0, 44, 126) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
