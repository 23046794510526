<template>
    <div class="w-full px-4 text-base text-gray-700">

        <h1 class="text-3xl mt-2 mb-3 text-blue-700">
            Password Reset
        </h1>

        <p class="mb-5">
            Your password will be reset and a temporary password will be emailed to you.
        </p>

        <form action="/reset-forgotten-password" method="POST" v-on:submit="showSpinner">

            <slot></slot> 

            <div class="mb-8">
                <label for="email" class="form-label inline-block text-gray-500">
                    Email
                </label>
                <input
                    type="email"
                    name="email"
                    v-model="email"
                    class="
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-lg
                        font-normal
                        text-gray-700 
                        tracking-wider 
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-400
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 
                        focus:bg-white 
                        focus:border-blue-600 
                        focus:outline-none"
                    id="email"
                    required
                />
            </div>
            
            <div v-if="displaySpinner">
                <h2 class="text-2xl mb-4 text-blue-700 text-center">
                    Please wait.
                </h2>
                <div class="mb-4 text-center">

                    <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                        <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                        c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="2s" 
                                from="0 50 50"
                                to="360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                        <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                        c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="1s" 
                                from="0 50 50"
                                to="360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                        <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                        L82,35.7z">
                            <animateTransform 
                                attributeName="transform" 
                                attributeType="XML" 
                                type="rotate"
                                dur="1.5s" 
                                from="0 50 50"
                                to="-360 50 50" 
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
            </div>

            <div v-if="! displaySpinner">
                <button role="submit" v-if="! disableButton" class="inline-block px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-600 uppercase tracking-wider text-white focus:shadow-outline focus:outline-none">
                    Reset My Password
                </button>
                
                <div v-if="disableButton" class="inline-block px-6 py-2 mb-6 border border-gray-400 rounded bg-gray-300 uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                    Enter Your Email
                </div>
            </div>
            
            <div class="flex justify-between mb-3 pt-4 border-t border-gray-300">

                <a href="/create-account" class="text-blue-700 hover:underline">Create Account</a>
                
                <a href="/login" class="text-blue-700 hover:underline">Back to Login</a>
                
            </div>

        </form>

    </div>
</template>

<script>


export default {
    data: function () {
        return {
            email: '',
            watch_email: false,
            disableButton: true,
            disableButton: true,
            displaySpinner: false,
        }
    },

    mounted: function () {
        if (this.email.length > 7)
        {
            this.watch_email = true;
        }
    },

    watch:{

        email: function(val, oldVal)
        {
            if (this.email.length > 7)
            {
                this.watch_email = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_email = false;
            }
        },
    },

    methods:{

        checkFormVariables ()
        {
            if (this.watch_email == true)
            {
                this.disableButton = false;
            }
        },

        showSpinner ()
        {
            this.displaySpinner = true;
        },

    }
}

</script>
