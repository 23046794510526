const state = {
    paymentSteps: {
        userBillingConfirmed: 'no',
        termsAccepted: 'no',
        paymentFailed: 'no',
    }
};

const getters = {
    getPaymentSteps: state => {
        return state.paymentSteps
    }
};

const actions = {
    initializePaymentSteps({state, commit}, payload) {
        commit('SET_PAYMENT_STEPS', payload)
    },
    updatePaymentSteps({state, commit}, payload) {
        commit('SET_PAYMENT_STEPS', payload)
    }
};

const mutations = {
    SET_PAYMENT_STEPS(state, payload) {
        state.paymentSteps = payload
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}