<template>

    <div class="flex items-center mb-8 p-4 bg-red-200 border border-red-500 rounded">
        <div class="ml-0 sm:ml-4">
            <h4 class="mb-2 text-xl text-red-700">Please correct the error(s).</h4>
            <ul class="list-inside list-disc">
                <li v-for="value in validationErrors" :key="value" class="text-base text-red-800">
                    {{ value }}
                </li>
                
            </ul>
        </div>
    </div>

  
</template>

<script>

export default {
    props: [
        'errors'
    ],

    data() {
        return {
            
        }
    },
    
    computed: {
        validationErrors(){
            let errors = Object.values(this.errors);
            errors = errors.flat();
            return errors;
        }
    },
        
}

</script>