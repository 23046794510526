const state = {
    shieldProduct: {}
};

const getters = {
    getCartShield: state => {
        return state.shieldProduct
    }
};

const actions = {
    initializeCartShield({state, commit}, payload) {
        commit('SET_CART_SHIELD', payload)
    },
    updateCartShield({state, commit}, payload) {
        commit('UPDATE_CART_SHIELD', payload)
    }
};

const mutations = {
    SET_CART_SHIELD(state, payload) {
        state.shieldProduct = payload
    },
    UPDATE_CART_SHIELD(state, payload) {
        state.shieldProduct = payload
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}