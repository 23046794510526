<template>
    <modal name="my-account-confirm-billing-modal" height="auto" :adaptive="true" :scrollable="true">

        <div class="w-full px-4 text-base text-gray-700">

            <h2 class="mt-2 mb-3 text-center text-3xl text-blue-700">
                Update / Confirm Billing
            </h2>

            <p class="mb-5">
                The billing address must match the billing address on your credit card.
            </p>

            <p class="mb-5">
                <span class="italic">Billing phone: </span>
                <span class="mr-4 text-xl font-semibold">{{ this.form.billing_phone }}</span>
            </p>

            <form @submit.prevent="processConfirmBilling">

                <show-form-validation-errors v-if="validationErrors" :errors="validationErrors" class="my-4">

                </show-form-validation-errors>

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                       type="text" 
                       name="billing_firstname" 
                       v-model="form.billing_firstname" 
                       placeholder="Your First Name" 
                       autocomplete="off" 
                       required>

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                       type="text" 
                       name="billing_lastname" 
                       v-model="form.billing_lastname" 
                       placeholder="Your Last Name" 
                       autocomplete="off" 
                       required>

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                       type="text" 
                       name="billing_address" 
                       v-model="form.billing_address" 
                       placeholder="Your Address" 
                       autocomplete="off" 
                       required>

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                       type="text" 
                       name="billing_city" 
                       v-model="form.billing_city" 
                       placeholder="Your City" 
                       autocomplete="off" 
                       required>

                <select v-model="form.billing_state" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required>
                    <option value="bg-white">Choose State</option>

                    <option v-for="(all_state, index) in all_states" :value="all_state.abbr" :key="index">
                        {{ all_state.name }}
                    </option>
                </select>

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                       type="text" 
                       name="billing_zip" 
                       v-model="form.billing_zip" 
                       placeholder="Your Zip Code" 
                       autocomplete="off" 
                       required>

                <input type="hidden" name="invoice" v-model="form.invoice">
                <input type="hidden" name="billing_phone" v-model="form.billing_phone">
                <input type="hidden" name="_token" :value="csrf">


                
                <div v-if="displaySpinner">
                    <h2 class="text-2xl mb-4 text-blue-700 text-center">
                        Please wait.
                    </h2>
                    <div class="mb-4 text-center">

                        <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                            <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                            c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="2s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                            c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                            L82,35.7z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1.5s" 
                                    from="0 50 50"
                                    to="-360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                </div>

                <div v-if="! displaySpinner" class="mb-6 text-center">
                    <button role="submit" v-if="! disableButton" class="inline-block mx-auto px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-600 uppercase tracking-wider text-white focus:shadow-outline focus:outline-none">
                        Update/Confirm Billing
                    </button>
                    
                    <div v-if="disableButton" class="inline-block mx-auto px-6 py-2 mb-6 border border-gray-400 rounded bg-gray-300 uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                        Fill Out The Form
                    </div>
                </div>

            </form>

        </div>

    </modal>
</template>



<script>
import ShowFormValidationErrors from './ShowFormValidationErrors.vue';

export default {

    components: {
        'show-form-validation-errors': ShowFormValidationErrors
    },

    props: [
        'states',
        'invoice_details',
    ],

    data () {
        return {
            form: {
                billing_firstname: '',
                billing_lastname: '',
                billing_address: '',
                billing_city: '',
                billing_state: '',
                billing_zip: '',
                billing_phone: '',
                invoice: '',
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            // Logic Variables
            billing_firstname: false,
            billing_lastname: false,
            billing_address: false,
            billing_city: false,
            billing_state: false,
            billing_zip: false,
            disableButton: true,
            validationErrors: '',
            displaySpinner: false,
            all_states: this.states,

            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
    },

    computed: {
        userBillingShipping: {
            get() {
                return this.$store.state.cartUserBillingShipping.userBillingShipping;
            }
        },

        paymentProcess: {
            get() {
                return this.$store.state.myAccountPaymentSteps.paymentSteps;
            }
        }
    },

    mounted: function () {

        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            // check if we have quote from controller
            this.updateThisModal();
        })
    },

    watch:{

        'form.billing_firstname': function(val, oldVal)
        {
            if (this.form.billing_firstname.length > 1)
            {
                this.billing_firstname = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.billing_firstname = false;
            }
        },

        'form.billing_lastname': function(val, oldVal)
        {
            if (this.form.billing_lastname.length > 1)
            {
                this.billing_lastname = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.billing_lastname = false;
            }
        },

        'form.billing_address': function(val, oldVal)
        {
            if (this.form.billing_address.length > 1)
            {
                this.billing_address = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.billing_address = false;
            }
        },

        'form.billing_city': function(val, oldVal)
        {
            if (this.form.billing_city.length > 1)
            {
                this.billing_city = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.billing_city = false;
            }
        },

        'form.billing_state': function(val, oldVal)
        {
            if (this.form.billing_state.length > 1)
            {
                this.billing_state = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.billing_state = false;
            }
        },

        'form.billing_zip': function(val, oldVal)
        {
            if (this.form.billing_zip.length > 1)
            {
                this.billing_zip = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.billing_zip = false;
            }
        },

        // userBillingShipping: {

        //     handler(val) {
                
        //         this.updateThisModal();
        //     },

        //     deep: true
        // },
    },

    methods: {

        checkFormVariables ()
        {
            if (this.billing_firstname == true && this.billing_lastname == true && this.billing_address == true && this.billing_city == true && this.billing_state == true && this.billing_zip == true )
            {
                this.disableButton = false;
            }
        },

        processConfirmBilling ()
        {
            this.validationErrors = '';
            this.displaySpinner   = true;

            axios.post('/myaccount/confirm-billing', this.form)
                .then(() => {
                    // Update Checkout Steps
                    this.updateStoreBillingShipping();
                    this.updatePaymentProcess();

                    this.displaySpinner = false;

                    // this.$modal.show('confirm-shipping-modal');
                    this.$modal.hide('my-account-confirm-billing-modal');
                })

                .catch(error => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                    this.displaySpinner = false;
                });

        },

        updateThisModal ()
        {
            this.form.billing_firstname = this.userBillingShipping.billing_firstname;
            this.form.billing_lastname  = this.userBillingShipping.billing_lastname;
            this.form.billing_address   = this.userBillingShipping.billing_address;
            this.form.billing_city      = this.userBillingShipping.billing_city;
            this.form.billing_state     = this.userBillingShipping.billing_state;
            this.form.billing_zip       = this.userBillingShipping.billing_zip;
            this.form.billing_phone     = this.userBillingShipping.billing_phone;
            this.form.invoice           = this.invoice_details.orderinfo.invoicenumber;
        },

        updateStoreBillingShipping()
        {
            // Billing Info
            this.userBillingShipping.billing_firstname  = this.form.billing_firstname;
            this.userBillingShipping.billing_lastname   = this.form.billing_lastname;
            this.userBillingShipping.billing_address    = this.form.billing_address;
            this.userBillingShipping.billing_city       = this.form.billing_city;
            this.userBillingShipping.billing_state      = this.form.billing_state;
            this.userBillingShipping.billing_zip        = this.form.billing_zip;

            this.$store.dispatch('cartUserBillingShipping/updateUserBillingShipping', this.userBillingShipping);
        },

        updatePaymentProcess() 
        {
            // update store (myAccountPaymentSteps)
            this.paymentProcess.userBillingConfirmed = 'yes';
            this.$store.dispatch('myAccountPaymentSteps/updatePaymentSteps', this.paymentProcess);

            // save to the session
            // this.updateCheckoutProcessInSession();
        },

        // updateCheckoutProcessInSession()
        // {
        //     // Send axios request to update the DB
        //     // we're using it on the page refresh and shopping cart update
        //     // var that = this;
        //     axios.post('/update-checkout-process', {
        //         user_billing_confirmed: 'yes',
        //         _token: this.csrf,
        //     })

        //     .catch(function (error) {
        //         console.log(error);
        //     });
        // }
    }
}

</script>

