const state = {
    cartSummary: {}
};

const getters = {
    getCartSummary: state => {
        return state.cartSummary
    }
};

const actions = {
    initializeCartSummary({state, commit}, payload) {
        commit('SET_CART_SUMMARY', payload)
    },
    updateCartSummary({state, commit}, payload) {
        commit('SET_CART_SUMMARY', payload)
    }
};

const mutations = {
    SET_CART_SUMMARY(state, payload) {
        state.cartSummary = payload
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}