<template>
    <div>
        <mattress-breadcrumbs
            :breadcrumbs="this.breadcrumbs" >
        </mattress-breadcrumbs>

        <mattress-header
            :page_h1_header="this.page_h1_header" >
        </mattress-header>

        <laravel-vue-pagination
            :pagination="this.pagination">
        </laravel-vue-pagination>

        <mattress-filter-pills 
            :pagination="this.pagination"
            :mattress_filters="this.mattress_filters" >
        </mattress-filter-pills>

        <div class="flex max-w-screen-xl mx-auto mb-8">

            <div class="hidden lg:block w-1/4 px-4 mb-6 lg:border-r border-gray-300 text-gray-700">
                <mattress-filters
                    :pagination="this.pagination"
                    :mattress_filters="this.mattress_filters" >
                </mattress-filters>
            </div>

            <div class="w-full lg:w-3/4 px-2 sm:px-4 text-base text-gray-700">

                <div v-if="products.length != 0">
                    <div v-for="(product, index) in products" :key="index" class="mx-auto mb-8 border border-gray-300 rounded-md overflow-hidden sm:shadow-md" style="max-width: 900px;">

                        <a v-bind:href="'/item/'+ product.skuserial" class="">
                            <div class="relative flex justify-center">
                                <div>
                                    <img v-if="product.first_image == ''" src="/img/image-coming-soon.jpg" class="object-contain" alt="Product Image Coming Soon">
                                    
                                    <img v-else v-bind:src="'/images'+ product.first_image " class="object-contain" v-bind:alt="product.shortdescription">
                                </div>
                                
                                <div v-if="product.status == 'HB'" class="absolute pl-3 pr-2 py-1 bg-red-700 rounded-l-full text-white shadow-md" style="top: 7px; right: 0px;">
                                    Hot Buy
                                </div>
                                
                                <div v-else-if="product.status == 'FM'" class="absolute pl-3 pr-2 py-1 bg-yellow-700 rounded-l-full text-white shadow-md" style="top: 7px; right: 0px;">
                                    Floor Model
                                </div>
                            
                                <div v-else-if="product.status == 'SPO'" class="absolute pl-3 pr-2 py-1 bg-blue-700 rounded-l-full text-white shadow-md" style="top: 7px; right: 0px;">
                                    Special Order
                                </div>
                            </div>

                            <h2 class="mb-2 p-3 border-t-2 border-gray-300 text-xl sm:text-2xl text-blue-700 leading-tight">
                                {{ product.shortdescription }}
                            </h2>

                            <p class="px-3 pb-4 text-2xl sm:text-3xl">
                                <span class="italic text-xl sm:text-2xl text-gray-500">
                                    Price:
                                </span>

                                <span class="ml-1 text-red-700">
                                    ${{ product.retail1 }}
                                </span>
                            </p>
                        </a>
                    </div>
                </div>

                <div v-else>
                    <div class="w-full flex flex-col justify-center">
                        <p class="mb-4 text-center text-xl font-semibold">
                            No Results Found
                        </p>

                        <p class="mx-auto mb-6 text-center leading-relaxed" style="max-width: 600px;">
                            We're sorry, but we couldn't find any results that match your sorting options.
                            Please try a different search criteria or check out our recommended similar items.
                        </p>

                        <div class="flex justify-center mb-4">
                            <a href="/mattresses" 
                                class="mx-auto px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg" 
                            >
                                See similar products
                            </a>
                        </div>
                    </div>
                    <!-- Data is not available message -->
                </div>

            </div>
        </div>

        <laravel-vue-pagination
            :pagination="this.pagination">
        </laravel-vue-pagination>

    </div>
</template>

<script>

import MattressHeader from './MattressHeader.vue';
import MattressBreadcrumbs from './MattressBreadcrumbs.vue';
import MattressFilterPills from './MattressFilterPills.vue';
import MattressFilters from './MattressFilters.vue';
import LaravelVuePagination from '../LaravelVuePagination.vue';


    export default {

        components: {
            'mattress-filters': MattressFilters,
            'mattress-filter-pills': MattressFilterPills,
            'mattress-breadcrumbs': MattressBreadcrumbs,
            'mattress-header': MattressHeader,
            'laravel-vue-pagination': LaravelVuePagination
        },

        props: [
            'page_h1_header',
            'breadcrumbs',
            'products_object',
            'mattress_filters'
        ],

        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                products: this.products_object.data,
                pagination: this.products_object,
            }
        },
        
        methods:{
            
        }
    }
</script>