<template>
    <div>
        <div class="flex justify-center mb-8 text-center" style="margin-top: 20px;">
            <input class="mr-3 w-5 h-5" type="checkbox" name="acceptterms" id="acceptterms" v-model="checked"> 
            <span>I accept the Terms of Sale</span>
        </div>

        <div class="m-2 mb-4 p-2 bg-blue-200 border border-blue-500 rounded"> 
            <div class="ml-0 sm:ml-4">
                <p class="text-base text-blue-800">
                    <i class="fa fa-info fa-lg mx-2 text-blue-700"></i> 
                    In order to continue to make a payment you must accept the terms of sale.
                </p>
            </div>
        </div>

        <div class="m-2 mb-10 p-2 bg-blue-200 border border-blue-500 rounded"> 
            <div class="ml-0 sm:ml-4">
                <p class="text-base text-blue-800">
                    <i class="fa fa-info fa-lg mx-2 text-blue-700"></i> 
                    Customer billing address on invoice must match billing address on credit card. Otherwise your payment will be declined.
                </p>
            </div>
        </div>

        <div class="flex flex-col mb-12 items-center">

            <form 
                class="w-full sm:w-1/2 py-1 sm:border-2 border-gray-400 rounded"  
                method="POST" 
                id="paymentForm" 
                action="https://thefurniturewarehouse.com/myaccount/receipt" 
                @submit.prevent="submitPayment"
            >

                <h4 class="mb-2 py-2 border-b border-gray-400 text-center text-xl text-gray-500">
                    Make Payment
                </h4>

                <div class="flex justify-between mx-3 py-2 border-b border-gray-400">
                    <span class="text-gray-500 italic">Amount:</span>
                    <div class="flex justify-end items-center">
                        <span class="mr-1">$</span>
                        <money type="text" name="amount" v-model="balance" v-bind="money" class="float-right px-2 py-1 bg-gray-100 text-right" required>
                        </money>
                    </div>
                </div>

                <div class="m-2 mt-2 p-2 border border-red-500 rounded text-red-700" v-if="displaySubmissionTip">
                    {{ formSubmissionTip }}
                </div>

                <div class="m-4 text-center">
                    <input type="hidden" name="invoice" v-model="invoice">
                    <input type="hidden" name="dataValue" id="dataValue">
                    <input type="hidden" name="dataDescriptor" id="dataDescriptor">
                    <input type="hidden" name="_token" :value="csrf">
                    
                    <div v-if="displayFakeButton">
                        <span class="px-4 py-2 bg-gray-200 rounded border border-gray-400  cursor-not-allowed">
                            Continue To Pay
                        </span>
                    </div>

                    <button 
                        type="button"
                        class="AcceptUI px-4 py-2 bg-blue-500 rounded text-white"
                        data-billingAddressOptions='{"show":true, "required":false}'
                        data-apiLoginID="5YdkE4THn8p"
                        data-clientKey="9ntE4jzta2b5C3k6ASACN3TMAVMPCsk7kuhx2P3qRSH6xX69t5ceDH4523gVL6zP"
                        data-acceptUIFormBtnTxt="Pay Now"
                        data-acceptUIFormHeaderTxt="Card Information"
                        data-responseHandler="responseHandler"
                        id="btnSubmit" 
                        v-bind:class="CursorNotAllowed" 
                        :disabled="disableButton">
                        Continue To Pay
                    </button>
                </div>

            </form>
        </div>
    </div>
</template>

<script>

import {Money} from 'v-money';

export default {

    components: {Money},

    props: [
        'customerinvoice',
        'invoicebalance'
        ],
        
    data: function () {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            invoice: this.customerinvoice,
            balance: this.invoicebalance,
            checked: false,
            disableButton: true,
            displayFakeButton: true,
            displaySubmissionTip: false,
            CursorNotAllowed: 'invisible cursor-not-allowed',
            formSubmissionTip: '',
            money: {
                decimal: '.',
                thousands: '',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },
    watch: {
        checked: function(val, oldVal)
        {
            if (val == true)
            {
                this.checkFormVariables();
                this.checkFormSubmissionTips();

            } else {

                this.disableButton = true;
                this.displaySubmissionTip = true;
                this.formSubmissionTip = 'In order to continue to make a payment you must accept the terms of sale.';
                this.displayFakeButton = true;
                this.CursorNotAllowed = 'invisible cursor-not-allowed';
            }
        },
        balance: function(val, oldVal)
        {
            if (val > 0)
            {
                this.checkFormVariables();
                this.checkFormSubmissionTips();

            } else {
                
                this.disableButton = true;
                this.displaySubmissionTip = true;
                this.formSubmissionTip = 'Amount cannot be 0';
                this.displayFakeButton = true;
                this.CursorNotAllowed = 'invisible cursor-not-allowed';
            }
        },
    },

    methods:{
        checkFormVariables ()
        {
            if(this.checked == true && this.balance > 0)
            {
                this.disableButton = false;
                this.CursorNotAllowed = 'hover:bg-blue-400';
                this.displaySubmissionTip = false;
                this.formSubmissionTip = '';
                this.displayFakeButton = false;

            } else {
                
                this.CursorNotAllowed = 'invisible cursor-not-allowed';
                this.displayFakeButton = true;
                this.displaySubmissionTip = true;
            }
        },
        checkFormSubmissionTips ()
        {
            if (this.balance == 0)
            {
                this.formSubmissionTip = 'Amount cannot be 0';
            }

            if (this.checked == false)
            {

                this.formSubmissionTip = 'In order to continue to make a payment you must accept the terms of sale.';
            }
        },
        submitPayment ()
        {
            // Do nothing
        },
        
    }
}


</script>