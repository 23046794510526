<template>
    <modal name="my-account-accept-terms-modal" height="auto" :adaptive="true" :scrollable="true">

        <div class="w-full px-4 text-base text-gray-700">

            <h2 class="mt-2 mb-5 text-center text-3xl text-blue-700">
                Terms of Sale
            </h2>

            <p class="mb-10 text-base italic">
                In order to continue to make a payment you must accept the terms of sale.
            </p>

            <div class="scrolling-auto overflow-auto h-64 px-2 py-2 sm:px-4 sm:py-4 border-b border-gray-400">
                <div class="mb-10">
                    <h4 class="mb-4 text-center text-xl font-semibold">
                        Internet Terms and Conditions
                    </h4>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Reselection and Return Policy</span> - internet sales merchandise will graciously be exchanged
                        one time for store credit within 7 days of purchase.  We have a no refund policy.  If the merchandise 
                        was delivered by The Furniture Warehouse in our normal delivery area, we will add an additional delivery 
                        fee for reselection plus a 15% or $30 restocking fee whatever is greater.  Merchandise picked up or delivered by an outside freight
                        carrier must be returned in the original factory carton to The Furniture Warehouse at 7155 16th Street East, 
                        Suite 101, Sarasota, FL 34243.  All items must be returned at the customer's expense and must have an 
                        approved return authorization issued prior to being returned.  Assembled items, such as daybeds, bunk beds, 
                        stressless chairs and rails are non-returnable items. Pillows, sheet sets, mattress protectors, 
                        and pillow protectors are also non-returnable.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Damaged or Defective Items</span> - items delivered by The 
                        Furniture Warehouse in our normal delivery area that are damaged will have an expert technician 
                        dispatched to your residence to be repaired.  We will follow manufacturer guidelines and warranties. 
                        All items purchased outside our normal delivery area will have to be returned at the customer's 
                        expense for repair or replacement. Please contact customer service at 941-359-2255 or enter a 
                        service claim online at 
                        <a href= "/contact-us" class="text-blue-600 underline">
                            Contact Customer Service
                        </a>
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">30 Day Bedding Comfort Guarantee</span> 
                        - with the purchase of a mattress protector from The Furniture Warehouse. We offer a 30 Day Comfort 
                        Guarantee excluding size, mattress height, special orders, or floor models. Upon inspection, 
                        you may exchange it for another of equal or greater value within the same manufacture when you pay 
                        the price difference. We offer one-time reselection. All reselections are subject to a reselection 
                        fee and an additional delivery charge. Mattress must be in sanitary condition, no smoke or pet hair.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">One Year Warranty</span> - we warrant that the merchandise you 
                        purchase from us will be free from defects in material and workmanship for a period of one year. 
                        This warranty applies only to the original delivery address. Warranty exclusions: fabric wear, fading, 
                        or shrinkage of any fabric; normal cushion softening; damage or discoloration caused by sunlight; 
                        natural variation in graining or color of leather; furnishings that have been altered by the purchaser; 
                        damage due to misuse, abuse, or accidents; variation of the color or graining of wood or wood products; 
                        surface variations, dimples, or swirl marks in lacquer.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Bedding</span> - body impressions of up to 2 1/2" are normal. 
                        Rotate your mattress as needed, this will promote even wear.  Stains and soiling or other signs of 
                        abuse may void the warranty.  Protect your mattress with a mattress protector, available at any of 
                        our store locations.  All mattress protectors come with a full 10-year limited warranty against 
                        defects in workmanship or materials. The mattress protectors repel body fluids and moisture and 
                        allow vapors to easily breathe through protector.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Leather</span> - each piece of leather is unique and made 
                        up of many hides with its own characteristics, such as lines, stripes, wrinkles, scars or shading 
                        which add to the beauty of this natural product.  Protect your leather investment with our 3 year 
                        leather care warranty against accidental rips and tears, food and beverages, ink stains, and more. 
                        See warranty for complete coverage.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Upholstery</span> - cushions are firm on new products and require 
                        a break-in period to allow air to flow through the cells. These will soften with use and adjust to 
                        you over time. Fiber-filled cushions loose shape conforming to user's seating position and require 
                        shaping or massaging similar to bed pillows. Keep out of direct sunlight. Blot any spills immediately 
                        with a clean dry cloth. Vacuuming your furniture should be done on a regular basis. Dust particles can 
                        cause premature wear and are corrosive to fabric fibers. Protect your investment with our 3 year fabric 
                        protection warranty against accidental rips and tears, food and beverage stains, and other fluids. 
                        See warranty for complete coverage.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Glass</span> - bubbles, blurs, and hairline scratches are normal 
                        characteristics of glass.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Wood</span> - no two pieces of wood are exactly the same as it is 
                        a product of nature. Light and dark shading, knots, and mineral streaking are considered normal. Keep 
                        out of direct sunlight when possible and clean with a lightly dampened cloth. Do not allow water to 
                        stand on top.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Clearance, Floor Model, and 'As-Is'</span> - no refunds. 
                        No warranties or service. No exchanges. Must be picked up or delivered within two weeks of purchase.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Shipping/Freight Quotes.</span> <br />
                        Shipping/Freight quotes are honored if shipped within 7 days.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Out of Area Delivery by Third Party Carrier.</span> <br />
                        If you are not at home or if there is limited access to your delivery address, you will be responsible 
                        for any additional fees incurred. If the delivery cannot be made because of limited access, you may choose 
                        to pick up your items from the terminal destination hub, or if necessary, have them returned to 
                        the Furniture Warehouse at your own expense.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Deposits</span> - merchandise held for 31 to 90 days requires 
                        a minimum 50% deposit and merchandise held for 91 to 120 days must be paid in full at the time of 
                        the sale. Floor model merchandise requires a minimum 50% deposit. All sales must be paid in full 
                        before scheduled for delivery.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Method of Payment</span> - we accept VISA, MasterCard, Discover, 
                        American Express, debit card, check, or cash. 
                        At The Furniture Warehouse you can apply for financing through two different companies Synchrony Financial, 
                        and EasyPay Finance. Also you have lease to own option through Progressive Leasing. 
                        Qualification is subject to credit approval.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Delivery Policy</span> - you will receive an automated call two 
                        days prior to your scheduled delivery to confirm the delivery date and address. You will receive 
                        another automated call one day prior to your scheduled delivery with a four hour window for your 
                        delivery. The delivery drivers will position your new merchandise and remove all packing materials 
                        from your home. The Furniture Warehouse will pick up used bedding only, no used furniture. If needed, 
                        the drivers can relocate furniture that is in the room that we are delivering to only. 
                        For doorway deliveries your merchandise will be dropped in the box outside your home or in the 
                        first entry point of your building.
                    </p>

                    <p class="mb-4">
                        <span class="text-lg font-semibold">Pick up Policy</span> - you may choose to pick up your merchandise 
                        from our Distribution Center, located in Sarasota. All products picked up in factory carton; therefore, 
                        the customer is responsible for any assembly. Warehouse staff will assist in loading merchandise on your vehicle. 
                        We are not responsible for damages that occur during transport or unloading.  If there is hidden damage or missing
                        parts, items may be exchanged or repaired and must be returned in the original factory carton to the distribution 
                        center. We will repair or exchange at our choice. Items must be returned within 7 days.
                    </p>

                </div>

            </div>

            <form @submit.prevent="processAcceptTerms">

                <show-form-validation-errors v-if="validationErrors" :errors="validationErrors" class="my-4">
                </show-form-validation-errors>

                <div class="flex justify-center mb-8 text-center" style="margin-top: 20px;">
                    <input class="mr-3 w-5 h-5" type="checkbox" name="acceptterms" id="acceptterms" v-model="form.accept_terms_of_sale" v-on:change="checkBoxHasChanged"> 
                    <span>I accept the Terms of Sale</span>
                </div>

                <input type="hidden" name="billing_phone" :value="form.billing_phone">
                <input type="hidden" name="invoice" :value="form.invoice">

                
                <div v-if="displaySpinner">
                    <h2 class="text-2xl mb-4 text-blue-700 text-center">
                        Please wait.
                    </h2>
                    <div class="mb-4 text-center">

                        <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                            <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                            c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="2s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                            c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                            L82,35.7z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1.5s" 
                                    from="0 50 50"
                                    to="-360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                </div>

                <div v-if="! displaySpinner" class="mb-6 text-center">
                    <button role="submit" v-if="! disableButton" class="inline-block mx-auto px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-600 uppercase tracking-wider text-white focus:shadow-outline focus:outline-none">
                        Accept
                    </button>
                    
                    <div v-if="disableButton" class="inline-block mx-auto px-6 py-2 mb-6 border border-gray-400 rounded bg-gray-300 uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                        Must check the box
                    </div>
                </div>

            </form>

        </div>

    </modal>
</template>



<script>
import ShowFormValidationErrors from './ShowFormValidationErrors.vue';

export default {

    components: {
        'show-form-validation-errors': ShowFormValidationErrors
    },

    props: [
        'invoice_details',
    ],

    data () {
        return {
            form: {
                billing_phone: this.invoice_details.cusinfo.phone,    // user ID in Frog
                invoice: this.invoice_details.orderinfo.invoicenumber,
                accept_terms_of_sale: false,
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            
            disableButton: true,
            validationErrors: '',
            displaySpinner: false,
        }
    },

    computed: {

        paymentProcess: {
            get() {
                return this.$store.state.myAccountPaymentSteps.paymentSteps;
            }
        }
    },

    // mounted: function () {

    //     this.$nextTick(function () {
    //         // Code that will run only after the
    //         // entire view has been rendered
    //         // check if we have quote from controller
    //         this.updateThisModal();
    //     })
    // },

    // watch:{

    //     'form.accept_terms_of_sale': function(val, oldVal)
    //     {
    //         if (this.form.accept_terms_of_sale == true)
    //         {
    //             this.checkFormVariables();

    //         } else {

    //             this.disableButton = true;
    //         }
    //     }
    // },

    methods: {

        checkBoxHasChanged ()
        {
            if (this.form.accept_terms_of_sale == true)
            {
                this.disableButton = false;
                // this.checkFormVariables();

            } else {

                this.disableButton = true;
            }
        },

        // checkFormVariables ()
        // {
        //     if (this.accept_terms_of_sale == true)
        //     {
        //         this.disableButton = false;
        //     }
        // },


        processAcceptTerms ()
        {
            this.validationErrors = '';
            this.displaySpinner   = true;

            // Update Checkout Steps
            this.updatePaymentSteps();
        },

        // updateThisModal ()
        // {
        //     // Initial state
        //     this.form.billing_phone = this.userBillingShipping.billing_phone; // user ID in Frog
        // },

        updatePaymentSteps() 
        {
            // Update store 
            this.paymentProcess.termsAccepted = 'yes';
            this.$store.dispatch('myAccountPaymentSteps/updatePaymentSteps', this.paymentProcess);

            // Save to the DB
            this.updatePaymentStepsInDatabase();
        },


        updatePaymentStepsInDatabase()
        {
            // Send axios request
            // Update Shopping Cart Process
            // var that = this;
            axios.post('/myaccount/accept-terms-of-sale', this.form)
                .then(() => {
                    this.displaySpinner = false;

                    this.$modal.show('my-account-confirm-billing-modal');
                    this.$modal.hide('my-account-accept-terms-modal');
                })

                .catch(error => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                    this.displaySpinner = false;
                });
        },

    }
}

</script>

