<template>

<div class="flex flex-wrap max-w-screen-xl justify-center mx-auto my-10">

    <!-- Main Content -->
    <div class="w-full md:w-2/3 mb-8 px-2 sm:px-4 text-base text-gray-700">

        <div class="mb-3 pb-3 text-2xl border-b border-gray-300 cursor-pointer" @click="showBilling = !showBilling">
            <div class="flex justify-start content-center">
                <div class="flex-initial mr-4 px-4 bg-green-300 text-green-700 border border-green-400 rounded-full">
                    1
                </div>
                <div class="flex-auto text-green-700">
                    Checkout Billing
                </div>
            </div>  
        </div>

        <div v-show="showBilling" class="flex mb-3 pb-3 text-base border-b border-gray-300">
            <div class="flex-1">
                {{ this.billing.billing_firstname }} {{ this.billing.billing_lastname }} <br>
                {{ this.billing.billing_address }} <br>
                {{ this.billing.billing_city }} <br>
                {{ this.billing.billing_state }} <br>
                {{ this.billing.billing_zip }} <br>
            </div>
            <div class="flex-1">
                <a href="/checkout-billing" class="text-blue-500 hover:cursor-pointer hover:text-blue-700">
                    <i class="fa fa-pencil-square-o mr-1" aria-hidden="true"></i>
                    edit billing
                </a>
            </div>
        </div>


        <div class="mb-3 pb-3 text-2xl border-b border-gray-300 cursor-pointer" @click="showShipping = !showShipping">
            <div class="flex justify-start content-center">
                <div class="flex-initial mr-4 px-4 bg-green-300 text-green-700 border border-green-400 rounded-full">
                    2
                </div>
                <div class="flex-auto text-green-700">
                    Checkout Shipping
                </div>
            </div>  
        </div>

        <div v-show="showShipping" class="flex mb-3 pb-3 text-base border-b border-gray-300">
            <div class="flex-1">
                {{ this.shipping.shipping_firstname }} {{ this.shipping.shipping_lastname }} <br>
                {{ this.shipping.shipping_address }} <br>
                {{ this.shipping.shipping_city }} <br>
                {{ this.shipping.shipping_state }} <br>
                {{ this.shipping.shipping_zip }} <br>
            </div>
            <div class="flex-1">
                <a href="/checkout-shipping" class="text-blue-500 hover:cursor-pointer hover:text-blue-700">
                    <i class="fa fa-pencil-square-o mr-1" aria-hidden="true"></i>
                    edit shipping
                </a>
            </div>
        </div>


        <div class="flex justify-start content-center mb-3 pb-3 text-2xl border-b border-gray-300">
            <div class="flex-initial mr-4 px-4 bg-blue-300 text-blue-700 border border-blue-400 rounded-full">
                3
            </div>
            <div class="flex-auto text-blue-700">
                Checkout Payment
            </div>
        </div>

        <div class="mb-3 pb-10 border-b border-gray-300">

            <h2 class="mb-5 text-xl text-blue-700">
                Terms of Sale
            </h2>

            <slot></slot>

            <div class="flex justify-center mb-8 text-center" style="margin-top: 20px;">
                <input class="mr-3 w-5 h-5" type="checkbox" name="acceptterms" id="acceptterms" v-model="accept_terms_of_sale"> 
                <span>I accept the Terms of Sale</span>
            </div>

            <div v-if="displayFakeButton" class="m-2 mb-4 p-2 bg-blue-200 border border-blue-500 rounded"> 
                <div class="ml-0 sm:ml-4">
                    <p class="text-base text-blue-800">
                        <i class="fa fa-info fa-lg mx-2 text-blue-700"></i> 
                        In order to continue to make a payment you must accept the terms of sale.
                    </p>
                </div>
            </div>

            <div class="flex flex-col mb-12 items-center">
                <form class="w-full sm:w-1/2 py-1 sm:border-2 border-gray-400 rounded"  method="POST" id="paymentForm" action="https://thefurniturewarehouse.com/receipt" @submit.prevent="submitPayment">

                    <h4 class="mb-2 py-2 border-b border-gray-400 text-center text-xl text-gray-500">
                        Make Payment
                    </h4>

                    <div class="m-4 text-center">
                        <input type="hidden" name="dataValue" id="dataValue">
                        <input type="hidden" name="dataDescriptor" id="dataDescriptor">
                        <input type="hidden" name="stage" value="payment">
                        <input type="hidden" name="_token" :value="csrf">
                        
                        <div v-if="displayFakeButton">
                            <span class="px-4 py-2 bg-gray-200 rounded border border-gray-400 uppercase tracking-wider cursor-not-allowed">
                                Continue To Pay
                            </span>
                        </div>

                        <button 
                            type="button"
                            class="AcceptUI px-4 py-2 border border-green-500 rounded bg-green-400 uppercase tracking-wider text-gray-900 hover:bg-green-300 focus:shadow-outline focus:outline-none"
                            data-billingAddressOptions='{"show":true, "required":false}'
                            data-apiLoginID="5YdkE4THn8p"
                            data-clientKey="9ntE4jzta2b5C3k6ASACN3TMAVMPCsk7kuhx2P3qRSH6xX69t5ceDH4523gVL6zP"
                            data-acceptUIFormBtnTxt="Pay Now"
                            data-acceptUIFormHeaderTxt="Card Information"
                            data-responseHandler="responseHandler"
                            id="btnSubmit" 
                            v-bind:class="CursorNotAllowed" 
                            :disabled="disableRealButton">
                            Continue To Pay
                        </button>

                    </div>

                </form>
            </div>

            <div class="m-2 mb-10 p-2 bg-blue-200 border border-blue-500 rounded"> 
                <div class="ml-0 sm:ml-4">
                    <p class="text-base text-blue-800">
                        <i class="fa fa-info fa-lg mx-2 text-blue-700"></i> 
                        Synchrony deferred card will not have deferred interest program if used. 
                        Please call store for acceptance 941-359-2255 ext 305.
                    </p>
                </div>
            </div>

            <div class="flex items-center m-2 mb-10 p-4 bg-red-200 border border-red-500 rounded">
                <div class="hidden sm:block">
                    <i class="fa fa-info fa-2x text-red-700"></i>
                </div>
                <div class="ml-0 sm:ml-4">
                    <p class="text-base text-red-700">
                        Upon delivery or pickup customer must present original credit card. 
                        A picture of the photo ID will be taken at the time of delivery or pickup.
                    </p>
                </div>
            </div>

            <div class="text-center">
                <p class="text-center">
                    We accept all major credit cards
                </p>
                <img src="/img/icons/credit-cards.png" class="" style="display: block; margin-left: auto; margin-right: auto;"  alt="We accept all major credit cards">
            </div>

        </div>
            
    </div>


    <!-- Sidebar -->
    <div class="w-full md:w-1/3 px-4 md:border-l border-gray-300 text-base text-gray-700">

        <h2 class="text-3xl mb-6 text-blue-700 text-center">
            Cart Summary
        </h2>

        <div class="relative mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">

            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <div class="text-gray-600 italic">
                    Subtotal:
                </div>
                
                <div class="text-lg font-semibold">
                    ${{ this.cart_subtotal }}
                </div>     
            </div>

            <div class="mb-2 pb-2 border-b border-gray-400">

                <div class="flex justify-between mb-2"> 
                    <div class="text-gray-600 italic">
                        {{ this.quote_label }}:
                    </div>
                    
                    <div class="text-lg font-semibold">
                        ${{ this.cart_delivery_charge }}
                    </div>     
                </div>

                <div v-if="showQuoteDestinationDetails" class="text-sm text-center">
                    {{ this.quote_city }}, {{ this.quote_state }} {{ this.quote_zip }}
                </div>

            </div>


            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <div class="text-gray-600 italic">
                    Tax:
                </div>
                
                <div class="text-lg font-semibold">
                    ${{ this.cart_tax }}
                </div>     
            </div>

            

            <div class="flex justify-between mb-5 pb-2 border-b border-gray-400"> 
                <div class="text-gray-600 italic">
                    Total:
                </div>
                
                <div class="text-lg font-semibold">
                    ${{ this.cart_total }}
                </div>     
            </div>
        </div>

        <h2 class="text-3xl mb-6 text-blue-700 text-center">
            Cart Items
        </h2>

        <div class="mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">
            <div v-for="cart_item in cart_items" :key="cart_item.skuserial">
                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        SKU Serial:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        {{ cart_item.skuserial }}
                    </span>     
                </div>

                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        Item:
                    </span>

                    <span class="text-lg font-semibold">
                        {{ cart_item.description }}
                    </span>     
                </div>

                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        Quantity:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        {{ cart_item.qty }}
                    </span>     
                </div>
                <div class="mb-2 pb-2 border-b border-gray-400"> 
                    <span class="mr-2 text-gray-600 italic">
                        Price:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        ${{ cart_item.price }}
                    </span>     
                </div>
            </div>

            <div class="mt-8 mb-5 text-center">
                <a href="/shopping-cart" class="px-6 py-2 border border-gray-500 rounded bg-gray-300 tracking-wider text-gray-900 hover:bg-gray-400 focus:shadow-outline focus:outline-none">
                    Edit Shopping Cart
                </a>
            </div>

        </div>
    
        

    </div>

</div>
        
</template>

<script>

export default {
    props: [
        'delivery',
        'shoppingcart',
        'billing',
        'shipping',
        'cartitems',
    ],
    
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            // Check box (accept terms of sale)
            accept_terms_of_sale: false,
            // Shopping Cart Summary
            cart_subtotal: this.shoppingcart.subtotal,
            cart_delivery_charge: this.shoppingcart.delcharge,
            cart_tax: this.shoppingcart.tax,
            cart_total: this.shoppingcart.total,
            // Quote Data
            quote_label: this.delivery.quote_label,
            quote_carrier: this.delivery.quote_carrier,
            quote_city: this.delivery.quote_city,
            quote_state: this.delivery.quote_state,
            quote_zip: this.delivery.quote_zip,
            // Show / Hide Form Button
            disableRealButton: true, // we cannot hide the button, it should be reder in DOM
            CursorNotAllowed: 'invisible cursor-not-allowed', // it makes the button invisible for users but for DOM
            displayFakeButton: true,
            showQuoteDestinationDetails: false, 
            showBilling: false,
            showShipping: false, 
            cart_items: this.cartitems,          
        }
    },

    mounted() {

        if (this.cart_delivery_charge != ".00")
        {
            this.showQuoteDestinationDetails = true;
        }
    },
    
    watch: {
        accept_terms_of_sale: function(val, oldVal)
        {
            if (this.accept_terms_of_sale == true)
            {
                this.disableRealButton = false;
                this.CursorNotAllowed = '';
                this.displayFakeButton = false;

            } else {

                this.disableRealButton = true;
                this.CursorNotAllowed = 'invisible cursor-not-allowed';
                this.displayFakeButton = true;
            }
        }
    },
    
    methods: {
        submitPayment ()
        {
            // Do nothing
            // Autorize dot net JS will do the rest
        }
    },
}

</script>
