<template>
    <button type="button" class="px-4 py-2 bg-yellow-400 rounded border border-yellow-500 shadow-md text-gray-900 hover:bg-yellow-300 hover:cursor-pointer" @click="deleteGroup">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: [
            'removeSkuserial',
        ],
        data() {
            return {
                skuserial: this.removeSkuserial,
                validationErrors: '',
            }
        },
        methods: {
            deleteGroup() {
                Swal.fire({
                    title: 'Processing Your Request',
                    html:
                    '<div style="margin:30px;"><i class="fa fa-spinner fa-pulse fa-5x fa-fw" style="color: #2980b9;"></i></div>' +
                    '<p style="font-size: 18px;">Please wait. Do not refresh the page.</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                });

                this.validationErrors = '';

                axios.post('/delete-shopping-cart-item', {
                        skuserial: this.skuserial   
                    })
                    .then(() => location.reload())
                    .catch(error => {
                        if (error.response.status == 422) {
                            this.validationErrors = error.response.data.errors;
                        }
                        Swal.close();
                    });       
            }
        }
    }
</script>