<template>
    <div class="flex justify-center mb-4">
        <span @click="showProtectionPlanPricingDetails" 
            class="px-6 py-3 rounded-full border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-widest cursor-pointer shadow-lg" 
        >
            Protection Plan Pricing
        </span>

        <protection-plan-pricing-details
            :shield_products="this.shield_products">
        </protection-plan-pricing-details>
    </div>
</template>


<script>

import ProtectionPlanPricingDetails from './modals/ProtectionPlanPricingDetails.vue';

export default {

    components: {
        'protection-plan-pricing-details': ProtectionPlanPricingDetails
    },

    props: [
        'shield_products'
    ],

    methods:{
        
        showProtectionPlanPricingDetails: function()
        {
            this.$modal.show('protection-plan-pricing-details');
        },

        hideProtectionPlanPricingDetails: function()
        {
            this.$modal.hide('protection-plan-pricing-details');
        },

    }
}
</script>
