const state = {
    checkoutSteps: {
        quoteCompleted: 'no',
        userLoggedIn: 'no',
        userBillingConfirmed: 'no',
        userShippingConfirmed: 'no',
        termsAccepted: 'no',
        paymentFailed: 'no',
        recalculateShipping: 'no',
        synchronyOptionSelected: 'no',
        synchronyCode: '',
        synchronyApproved: 'no',
        progressiveApproved: 'no'
    }
};

const getters = {
    getCheckoutSteps: state => {
        return state.checkoutSteps
    }
};

const actions = {
    initializeCheckoutSteps({state, commit}, payload) {
        commit('SET_CHECKOUT_STEPS', payload)
    },
    updateCheckoutSteps({state, commit}, payload) {
        commit('SET_CHECKOUT_STEPS', payload)
    }
};

const mutations = {
    SET_CHECKOUT_STEPS(state, payload) {
        state.checkoutSteps = payload
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}