<template>
    <modal name="protection-policy" height="auto" :adaptive="true" :scrollable="true">

        <div class="w-full px-4 py-4 text-base text-gray-700">

            <div class="flex justify-end">
                <div class="px-2 py-1 bg-gray-700 hover:bg-blue-700 hover:cursor-pointer rounded-md" @click="closeThisModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>

            <div class="flex justify-center mb-6">
                <img src="/img/the-furniture-warehouse-logo.png" alt="The Furniture Warehouse Logo">
            </div>

            <div class="flex justify-center mb-6">
                <img src="/img/shield/we-have-got-you-covered.png" alt="We've Got You Covered Banner">
            </div>

            <p class="mb-6">
                <span class="text-lg font-semibold">
                    The Furniture Warehouse Accidental Protection Plan 
                </span>
                provides <span class="text-red-700 font-bold whitespace-nowrap">3 years</span> 
                of coverage against accidental stains and damages on upholstered
                furniture, including leather, fabric, and vinyl. It also protects against
                mechanical failures in Power, Frame, Mechanisms, and Adjustable
                bases. Enjoy peace of mind, letting your furniture live with you
                worry-free.
            </p>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/food-or-beverage-stains.png" alt="Food Or Beverage Stains">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/stains-from-humans-or-pet-bodily-fluids.png" alt="Stains From Humans Or Pet Bodily Fluids">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/stains-from-grass-and-grease.png" alt="Stains From Grass And Grease">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/stains-from-ballpoint-pen-ink-and-marker.png" alt="Stains From Ballpoint Pen Ink And Marker">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/stains-from-gum-nail-polish-and-nail-polish-remover.png" alt="Stains From Gum Nail Polish And Nail Polish Remover">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/stains-from-cosmetics-lipstick-and-crayon.png" alt="Stains From Cosmetics Lipstick And Crayon">
            </div>

            <div class="flex justify-end mb-6">
                <img src="/img/shield/rips-tears-cuts-burns-and-punctures.png" alt="Rips Tears Cuts Burns And Punctures">
            </div>

            <hr class="mb-6 w-28 mx-auto border-b-2 border-gray-600">

            <div class="flex justify-end mb-2">
                <img src="/img/shield/failure-of-motor-and-associated-components.png" alt="Failure Of Motor And Associated Components">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/breakage-of-frame.png" alt="Breakage Of Frame">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/breakage-of-mechanisms.png" alt="Breakage Of Mechanisms">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/breakage-of-sleeper-recliner-vibrating-mechanism.png" alt="Breakage Of Sleeper, Recliner, Lifting, Power and / or Vibrating Mechanism">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/electrical-components.png" alt="Electrical Components, Including Motors And Wiring">
            </div>

            <div class="flex justify-end mb-2">
                <img src="/img/shield/controllers-of-adjustable-beds.png" alt="Adjustable Beds Controllers">
            </div>

            <div class="flex justify-end mb-10">
                <img src="/img/shield/power-surge.png" alt="Power Surge">
            </div>

            

            <h3 class="mb-2 text-lg font-semibold"> 
                WITHOUT A CLAIM, YOU HAVE MORE TO GAIN!!
            </h3>

            <p class="mb-6">
                Congratulations on your new furniture and the decision to protect your new furniture with the
                Furniture Warehouse protection programs. We want you to enjoy your new furniture investment for 
                years to come without the fear of accidents or mechanical failures.
            </p>

            <p class="mb-4">
                The Furniture Warehouse will issue a 
                <span class=" text-red-700 font-semibold">
                    $100 bonus credit
                </span>
                if there are no claims processed through the life of the protection period. This $100 bonus credit 
                can be used on the purchase of $500 or more before tax and delivery and will be issued at the end
                of the protection program.
            </p>

            <p class="mb-4">
                When your protection program expires, your coupon can be redeemed up to 90 days from your 
                program expiration date.
            </p>

            <p class="mb-6">
                Call 
                <a href="tel:+1-941-359-2255" class="text-blue-600 font-semibold hover:underline">
                    (941) 359-2255
                </a> 
                to report claims.
            </p>


            <p class="mb-6 italic">
                * Some exclusions apply. Accidental coverages are from a single incident. 
                <a href="/furniture-shield" class="text-blue-700 hover:underline whitespace-nowrap">
                    See the coverage details.
                </a>
            </p>

            
            <div class="flex justify-center mb-10">
                <div class="mx-auto px-4 py-2 bg-gray-700 rounded-full text-white tracking-wider uppercase hover:bg-blue-700 hover:cursor-pointer" @click="closeThisModal">
                    Close
                </div>
            </div>

        </div>

    </modal>
</template>



<script>

export default {

    methods: {

        closeThisModal: function()
        {
            this.$modal.hide('protection-policy');
        },

        openThisModal: function()
        {
            this.$modal.open('protection-policy');
        }
    }
}

</script>

