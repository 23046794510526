<template>
    <div class="flex max-w-screen-xl lg:hidden mx-auto px-2 sm:px-4 mb-6">

        <div @click="showMattressFiltersModal"  class="w-10 h-10 mr-4 lg:mr-6 mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="block w-10 h-10 border border-gray-700 rounded">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
            </svg>
        </div>

        <div class="flex flex-wrap items-center text-lg">

            <div v-for="(price, index) in filters.price" :key="index">
                <form v-if="price.checked" :action="pagination.path" method="GET">
                    
                    <div v-for="(brand, index) in filters.brand" :key="'price-brand'+index">
                        <input v-if="brand.checked" type="hidden" name="brand[]" :value="brand.name" />
                    </div>

                    <div v-for="(size, index) in filters.size" :key="'price-size'+index">
                        <input v-if="size.checked" type="hidden" name="size[]" :value="size.value" />
                    </div>

                    <div v-for="(firmness, index) in filters.firmness" :key="'price-firmness'+index">
                        <input v-if="firmness.checked" type="hidden" name="firmness[]" :value="firmness.value" />
                    </div>

                    <input type="hidden" name="page" value="1" />

                    <button type="submit" class="flex items-center pl-4 pr-0 py-0 mr-4 mb-3 text-gray-700 font-semibold border border-gray-700 rounded-full hover:cursor-pointer hover:text-red-700 hover:border-red-700">
                        <div class="mr-2">
                            {{ price.label }}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </form>
            </div>

            <div v-for="(brand, index) in filters.brand" :key="'brand'+index">
                <form :id="'mobile-brand-'+index" v-if="brand.checked" :action="pagination.path" method="GET">

                    <div v-for="(price, index) in filters.price" :key="'brand-price'+index">
                        <input v-if="price.checked" type="hidden" name="price" :value="price.value" />
                    </div>

                    <div v-for="(brand2, index) in filters.brand" :key="'brand-brand'+index">
                        <input v-if="brand2.checked && brand2 != brand" type="hidden" name="brand[]" :value="brand2.name" />
                    </div>

                    <div v-for="(size, index) in filters.size" :key="'brand-size'+index">
                        <input v-if="size.checked" type="hidden" name="size[]" :value="size.value" />
                    </div>

                    <div v-for="(firmness, index) in filters.firmness" :key="'brand-firmness'+index">
                        <input v-if="firmness.checked" type="hidden" name="firmness[]" :value="firmness.value" />
                    </div>
                    
                    <input type="hidden" name="page" value="1" />

                    <button type="submit" class="flex items-center pl-4 pr-0 py-0 mr-4 mb-3 text-gray-700 font-semibold border border-gray-700 rounded-full hover:cursor-pointer hover:text-red-700 hover:border-red-700">
                        <div class="mr-2">
                            {{ brand.label }}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </form>
            </div>

            <div v-for="(size, index) in filters.size" :key="'size'+index">
                <form v-if="size.checked" :action="pagination.path" method="GET">

                    <div v-for="(price, index) in filters.price" :key="'size-price'+index">
                        <input v-if="price.checked" type="hidden" name="price" :value="price.value" />
                    </div>

                    <div v-for="(brand, index) in filters.brand" :key="'size-brand'+index">
                        <input v-if="brand.checked" type="hidden" name="brand[]" :value="brand.name" />
                    </div>

                    <div v-for="(size2, index) in filters.size" :key="'size-size'+index">
                        <input v-if="size2.checked && size2 != size" type="hidden" name="size[]" :value="size2.value" />
                    </div>

                    <div v-for="(firmness, index) in filters.firmness" :key="'size-firmness'+index">
                        <input v-if="firmness.checked" type="hidden" name="firmness[]" :value="firmness.value" />
                    </div>

                    <button type="submit" class="flex items-center pl-4 pr-0 py-0 mr-4 mb-3 text-gray-700 font-semibold border border-gray-700 rounded-full hover:cursor-pointer hover:text-red-700 hover:border-red-700">
                        <div class="mr-2">
                            {{ size.label }}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </form>
            </div>

            <div v-for="(firmness, index) in filters.firmness" :key="'firmness'+index">
                <form v-if="firmness.checked" :action="pagination.path" method="GET">

                    <div v-for="(price, index) in filters.price" :key="'firmness-price'+index">
                        <input v-if="price.checked" type="hidden" name="price" :value="price.value" />
                    </div>

                    <div v-for="(brand, index) in filters.brand" :key="'firmness-brand'+index">
                        <input v-if="brand.checked" type="hidden" name="brand[]" :value="brand.name" />
                    </div>

                    <div v-for="(size, index) in filters.size" :key="'firmness-size'+index">
                        <input v-if="size.checked" type="hidden" name="size[]" :value="size.value" />
                    </div>

                    <div v-for="(firmness2, index) in filters.firmness" :key="'firmness-firmness'+index">
                        <input v-if="firmness2.checked && firmness2 != firmness" type="hidden" name="firmness[]" :value="firmness2.value" />
                    </div>

                    <button type="submit" class="flex items-center pl-4 pr-0 py-0 mr-4 mb-3 text-gray-700 font-semibold border border-gray-700 rounded-full hover:cursor-pointer hover:text-red-700 hover:border-red-700">
                        <div class="mr-2">
                            {{ firmness.label }}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </form>
            </div>

            <form id="reset-all-mobile" :action="pagination.path" method="GET">
                <button type="submit" class="flex items-center pl-4 pr-0 py-0 mr-4 mb-3 text-gray-700 font-semibold border border-gray-700 rounded-full hover:cursor-pointer hover:text-red-700 hover:border-red-700">
                    <div class="mr-2">
                        Clear All
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                        <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clip-rule="evenodd" />
                    </svg>
                </button>
            </form>
        </div>

        <mattress-filters-modal
            :pagination="this.pagination"
            :mattress_filters="this.mattress_filters" >
        </mattress-filters-modal>

    </div>
</template>

<script>

import MattressFiltersMobileModal from './MattressFiltersMobileModal.vue';    

    export default {

        components: {
            'mattress-filters-modal': MattressFiltersMobileModal,
        },

        props: [
            'pagination',
            'mattress_filters'
        ],

        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                filters: this.mattress_filters,
            }
        },
        
        methods:{
            
            showMattressFiltersModal: function()
            {
                this.$modal.show('mattress-filters-mobile-modal');
            }
        
        }
    }
</script>