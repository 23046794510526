<template>
    <div>
        <form method="post" action="/add-to-shopping-cart" v-on:submit="showSpinnerModal" class="">

            <div class="flex flex-wrap justify-start items-center mb-4 pb-2 border-b border-gray-300">

                <div class="mr-12">
                    <p class="block mb-5 text-left whitespace-nowrap">
                        <span class="ml-1 text-3xl font-semibold text-red-700 tracking-wide">
                            ${{ item.retail1 }}
                        </span>
                    </p>

                    <div v-if="item.status == 'FM'" class="mb-6">
                        <p class="mb-2 text-xl text-blue-700">
                            This item is a Floor Model. <br class="sm:hidden">
                            <span class="sm:ml-2 text-base text-gray-700 italic">
                                (You can buy it from our stores only)
                            </span>
                        </p>
                    </div>

                    <div v-else class="mb-6">
                        <input type="hidden" name="_token" :value="csrf" />
                        <input type="hidden" name="skuserial" :value="item.skuserial" />
                        <input type="hidden" name="shield_eligibility" :value="shield_eligibility" />
                        <input 
                            type="submit" 
                            class="px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg" 
                            value="Add to Cart" />
                    </div>
                </div>

                
                    
                <div v-if="item.status != 'FM'" class="">
                    <div v-if="shield_eligibility != 'no'" class="mb-3">
                        <div class="flex flex-wrap">
                            <div class="flex justify-start items-center">
                                <div class="w-5 h-5 mr-4">
                                    <input class="w-5 h-5" 
                                        type="checkbox" 
                                        name="shield_selected" 
                                        id="shield-selected" 
                                        value="yes" />
                                </div>

                                <div class="block mr-4 font-semibold leading-relaxed">
                                    <label for="shield-selected" class="mr-4">
                                        3 year accidental protection 
                                    </label>

                                    <span @click="showProtectionPolicyModal" class="mr-2 text-blue-800 font-semibold whitespace-nowrap hover:cursor-pointer hover:underline">
                                        What's protected?
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </form>

        <protection-policy></protection-policy>

    </div>
</template>

<script>

import ProtectionPolicy from './modals/ProtectionPolicy.vue';

    export default {

        components: {
            'protection-policy': ProtectionPolicy
        },

        props: [
            'item',
            'shield_eligibility'
        ],

        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        
        methods:{
            
            showProtectionPolicyModal: function()
            {
                this.$modal.show('protection-policy');
            },

            hideProtectionPolicyModal: function()
            {
                this.$modal.hide('protection-policy');
            },

            showSpinnerModal: function()
            {
                this.$swal({
                    title: 'Processing ...',
                    html:
                        '<svg class="mx-auto h-32 w-32 mb-4 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">' +
                            '<path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">' +
                                '<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />' +
                            '</path>' +
                            '<path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">' +
                                '<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />' +
                            '</path>' +
                            '<path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5L82,35.7z">' +
                                '<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1.5s" from="0 50 50" to="-360 50 50" repeatCount="indefinite" />' +
                            '</path>' +
                        '</svg>' +
                        '<p class="text-lg leading-relaxed">Please do not refresh the page and wait while we are updating your shopping cart.</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    timer: 4000,
                })
            },

        }
    }
</script>
