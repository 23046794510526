<template>
    <div class="max-w-screen-xl mx-auto mt-8 mb-4">
        <h1 class="text-3xl mb-6 text-blue-800 text-center">
            {{ page_h1_header }} Mattresses
        </h1>
    </div>
</template>

<script>

    export default {


        props: [
            'page_h1_header',
            // 'shield_eligibility'
        ],

        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                // PageH1Header: this.page_h1_header,
            }
        },
        
        methods:{
            
        
        }
    }
</script>