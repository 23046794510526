<template>

    <div v-if="this.checkoutProcess.userLoggedIn == 'yes'" class="mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">

        <div class="flex flex-wrap">

            <div class="w-full lg:w-1/2 lg:pr-1">

                <div class="flex justify-between p-1">
                    <div>
                        <h2 class="text-2xl text-blue-700 ">
                            Bill To:
                        </h2>
                    </div>

                    <div v-if="checkoutProcess.synchronyApproved != 'success'" @click="showConfirmBillingModal">
                        <svg class="h-8 w-8 mt-1 fill-current text-green-700 hover:text-green-400 hover:cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <rect width="512" height="512" rx="76.8"/>
                            <path d="M415.08,100.44C387.61,73,367,77.07,367,77.07L251.71,192.34l-129,129-19.24,90.7,90.72-19.24,129-129L438.47,148.57S442.58,127.94,415.08,100.44ZM188.78,381.92l-30.93,6.66a70.64,70.64,0,0,0-13.14-17.75A71.28,71.28,0,0,0,127,357.67l6.66-30.91,8.95-8.94s16.82.35,35.83,19.35S197.75,373,197.75,373Z" style="fill:#fff"/>
                        </svg>
                    </div>

                    <div v-if="checkoutProcess.synchronyApproved == 'success'">
                        <svg class="h-8 w-8 mt-1 fill-current text-gray-400 hover:cursor-not-allowed"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <rect width="512" height="512" rx="76.8"/>
                            <path d="M415.08,100.44C387.61,73,367,77.07,367,77.07L251.71,192.34l-129,129-19.24,90.7,90.72-19.24,129-129L438.47,148.57S442.58,127.94,415.08,100.44ZM188.78,381.92l-30.93,6.66a70.64,70.64,0,0,0-13.14-17.75A71.28,71.28,0,0,0,127,357.67l6.66-30.91,8.95-8.94s16.82.35,35.83,19.35S197.75,373,197.75,373Z" style="fill:#fff"/>
                        </svg>
                    </div>
                </div>

                
                <div class="w-full mb-6 p-2 sm:p-4 md:p-6 lg:p-2 border border-gray-400 rounded bg-white sm:shadow-all-around-10">

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            First Name:
                        </span> 
                        <span class="inline-block">
                            {{ userBillingShipping.billing_firstname }}
                        </span> 
                    </div>

                    <div class="mb-2">
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Last Name:
                        </span> 
                            {{ userBillingShipping.billing_lastname }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Street:
                        </span> 
                            {{ userBillingShipping.billing_address }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            City:
                        </span> 
                            {{ userBillingShipping.billing_city }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            State:
                        </span> 
                            {{ userBillingShipping.billing_state }}
                    </div>

                    <div class="mb-2">
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Zip Code:
                        </span> 
                            {{ userBillingShipping.billing_zip }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Phone:
                        </span> 
                            {{ userBillingShipping.billing_phone }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Email:
                        </span> 
                            {{ userBillingShipping.billing_email }}
                    </div>
                    
                </div>

                <div class="flex items-center mb-8 lg:mb-4 p-4 bg-red-200 border border-red-500 rounded sm:shadow-all-around-10">
                    <div class="">
                        <p class="text-base text-red-800">
                            Customer billing address must match billing address on credit card.
                        </p>
                    </div>
                </div>

            </div>

            <div class="w-full lg:w-1/2 lg:pl-1">

                <div class="flex justify-between p-1">
                    <div>
                        <h2 class="text-2xl text-blue-700 ">
                            Ship To:
                        </h2>
                    </div>

                    <div v-if="checkoutProcess.synchronyApproved != 'success'" @click="showConfirmShippingModal">
                        <svg class="h-8 w-8 mt-1 fill-current text-green-700 hover:text-green-400 hover:cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <rect width="512" height="512" rx="76.8"/>
                            <path d="M415.08,100.44C387.61,73,367,77.07,367,77.07L251.71,192.34l-129,129-19.24,90.7,90.72-19.24,129-129L438.47,148.57S442.58,127.94,415.08,100.44ZM188.78,381.92l-30.93,6.66a70.64,70.64,0,0,0-13.14-17.75A71.28,71.28,0,0,0,127,357.67l6.66-30.91,8.95-8.94s16.82.35,35.83,19.35S197.75,373,197.75,373Z" style="fill:#fff"/>
                        </svg>
                    </div>

                    <div v-if="checkoutProcess.synchronyApproved == 'success'">
                        <svg class="h-8 w-8 mt-1 fill-current text-gray-400 hover:cursor-not-allowed"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <rect width="512" height="512" rx="76.8"/>
                            <path d="M415.08,100.44C387.61,73,367,77.07,367,77.07L251.71,192.34l-129,129-19.24,90.7,90.72-19.24,129-129L438.47,148.57S442.58,127.94,415.08,100.44ZM188.78,381.92l-30.93,6.66a70.64,70.64,0,0,0-13.14-17.75A71.28,71.28,0,0,0,127,357.67l6.66-30.91,8.95-8.94s16.82.35,35.83,19.35S197.75,373,197.75,373Z" style="fill:#fff"/>
                        </svg>
                    </div>

                </div>
    
                <div class="w-full mb-6 p-2 sm:p-4 md:p-6 lg:p-2 border border-gray-400 rounded bg-white sm:shadow-all-around-10">

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            First Name:
                        </span> 
                        <span class="inline-block">
                            {{ userBillingShipping.shipping_firstname }}
                        </span> 
                    </div>

                    <div class="mb-2">
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Last Name:
                        </span> 
                            {{ userBillingShipping.shipping_lastname }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Street:
                        </span> 
                            {{ userBillingShipping.shipping_address }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            City:
                        </span> 
                            {{ userBillingShipping.shipping_city }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            State:
                        </span> 
                            {{ userBillingShipping.shipping_state }}
                    </div>

                    <div class="mb-2">
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Zip Code:
                        </span> 
                            {{ userBillingShipping.shipping_zip }}
                    </div>

                    <div>
                        <span class="inline-block w-1/3 text-gray-500 italic">
                            Phone:
                        </span> 
                            {{ userBillingShipping.shipping_phone }}
                    </div>
                    
                </div>

                <div class="flex items-center mb-4 p-4 bg-red-200 border border-red-500 rounded sm:shadow-all-around-10">
                    <div class="">
                        <p class="text-base text-red-800">
                            Upon delivery or pickup customer must present original credit card. 
                            A picture of the photo ID will be taken at the time of delivery or pickup.
                        </p>
                    </div>
                </div>
                
            </div>

        </div>

    </div>
    
</template>



<script>

// Import (editBilling and editShipping) Modals
// import ShowFormValidationErrors from './ShowFormValidationErrors.vue';



export default {

    // components: {
    //     // 'show-form-validation-errors': ShowFormValidationErrors
        
    // },

    props: [
        'billing_shipping_details'
    ],

    data () {
        return {
            
        }
    },

    computed: {
        userBillingShipping: {
            get() {
                return this.$store.state.cartUserBillingShipping.userBillingShipping;
            }
        },

        checkoutProcess: {
            get() {
                return this.$store.state.cartCheckoutProcess.checkoutSteps;
            }
        }
    },

    mounted() {
        this.$store.dispatch('cartUserBillingShipping/initializeUserBillingShipping', this.billing_shipping_details);
    },

    watch:{

        // 'form.phone': function(val, oldVal)
        // {
        //     if (this.form.phone.length > 11)
        //     {
        //         this.watch_phone = true;
        //         this.checkFormVariables();

        //     } else {

        //         this.disableButton = true;
        //         this.watch_phone = false;
        //     }
        // },

        // 'form.password': function(val, oldVal)
        // {
        //     if (this.form.password.length > 3)
        //     {
        //         this.watch_password = true;
        //         this.checkFormVariables();

        //     } else {

        //         this.disableButton = true;
        //         this.watch_password = false;
        //     }
        // }
    },

    methods: {

        // processLogin ()
        // {
        //     this.validationErrors = '';
        //     this.displaySpinner   = true;

        //     axios.post('/checkout-login', this.form)
        //         .then(() => {
        //             this.displaySpinner = false;
        //             alert('Success');
        //             this.$modal.hide('login');
        //         })

        //         .catch(error => {
        //             if (error.response.status == 422) {
        //                 this.validationErrors = error.response.data.errors;
        //             }
        //             this.displaySpinner = false;
        //         });

        // },

        showConfirmBillingModal: function()
        {
            this.$modal.show('confirm-billing-modal');
        },

        hideConfirmBillingModal: function()
        {
            this.$modal.hide('confirm-billing-modal');
        },

        showConfirmShippingModal: function()
        {
            this.$modal.show('confirm-shipping-modal');
        },

        hideConfirmShippingModal: function()
        {
            this.$modal.hide('confirm-shipping-modal');
        },
    }
}

</script>

