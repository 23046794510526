<script>

import ShowFormValidationErrors from './ShowFormValidationErrors.vue';

export default {
    components: {
        'show-form-validation-errors': ShowFormValidationErrors
    },
    props: [
        'bill_to_address',
        'bill_to_city',
        'bill_to_state',
        'bill_to_zip'
    ],
    data() {
        return {
            form: {
                address: this.bill_to_address,
                city: this.bill_to_city,
                state: this.bill_to_state,
                zip: this.bill_to_zip
            },
            loading: false,
            validationErrors: '',
            addressWarning: false,
            cityWarning: false,
            stateWarning: false,
            zipWarning: false,
        }
    },

    watch: { 

        'form.address': function(val, oldVal)
        {
            if (val == '')
            {
                this.addressWarning = true;

            } else {

                this.addressWarning = false;
            }
        },

        'form.city': function(val, oldVal)
        {
            if (val == '')
            {
                this.cityWarning = true;

            } else {

                this.cityWarning = false;
            }
        },

        'form.state': function(val, oldVal)
        {
            if (val == '')
            {
                this.stateWarning = true;

            } else {

                this.stateWarning = false;
            }
        },
        
        'form.zip': function(val, oldVal)
        {
            if (val == '')
            {
                this.zipWarning = true;

            } else {

                this.zipWarning = false;
            }
        }, 
        deep: true   
    },
    methods: {
        update_billing() {
            this.loading = true;
            this.validationErrors = '';
            axios.post('/myaccount/update-billing', this.form)
                .then(() => location.reload())
                .catch(error => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                    this.loading = false;
                });
                
        }
    }
}
</script>