import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import cartItems from "./modules/cartItems"
import cartDetails from "./modules/cartDetails"
import cartShipping from "./modules/cartShipping"
import cartCoupon from "./modules/cartCoupon"
import cartShield from "./modules/cartShield"
import cartUserBillingShipping from "./modules/cartUserBillingShipping"
import cartCheckoutProcess from "./modules/cartCheckoutProcess"
import myAccountPaymentSteps from "./modules/myAccountPaymentSteps"

export default new Vuex.Store({
    modules: {
        cartItems,
        cartDetails,
        cartShipping,
        cartCoupon,
        cartShield,
        cartUserBillingShipping,
        cartCheckoutProcess,
        myAccountPaymentSteps
    }

})

