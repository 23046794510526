<template>

    <div id="purchase_options" class="flex">
        <div class="w-full mb-16 text-lg leading-relaxed">

            <ul class="flex list-none">
                <li class="text-center">
                    <a href="#purchase_options" class="block px-5 py-2 sm:px-12 rounded-t-lg text-lg tracking-wider" v-on:click="toggleTabs(1)" v-bind:class="{'bg-blue-100 text-blue-700 cursor-pointer hover:bg-blue-400 hover:text-white': openTab !== 1, ' bg-blue-700 text-white cursor-default': openTab === 1}">
                        Financing
                    </a>
                </li>

                <li class="text-center">
                    <a href="#purchase_options" class="block px-5 py-2 sm:px-12 rounded-t-lg text-lg tracking-wider" v-on:click="toggleTabs(2)" v-bind:class="{'bg-blue-100 text-blue-700 cursor-pointer hover:bg-blue-400 hover:text-white': openTab !== 2, ' bg-blue-700 text-white cursor-default': openTab === 2}">
                        Lease to Own
                    </a>
                </li>
            </ul>

            <div class="relative flex flex-col min-w-0 break-words w-full py-8 border-t-2 border-b-2 border-blue-700">
                <div class="px-0 py-0 md:px-4 md:py-5 flex-auto">
                    <div class="md:tab-content md:tab-space">

                        <!-- 1 Tab Content -->
                        <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">

                            <h1 class="text-3xl mb-8 text-blue-700">
                                Finance Options
                            </h1>
                    
                            <h2 class="text-2xl mb-6 border-b border-blue-200 text-blue-700">
                                Finance and Revolving Credit
                            </h2>
                    
                            <p class="mb-4">
                                At The Furniture Warehouse you can apply for financing through companies listed on this page. 
                                Apply for credit now by clicking on the links provided below.
                                You can always apply for instant credit at any one of our 6 showrooms as well. 
                            </p>

                            <div class="mb-16 text-center">
                                <a href="/locations" 
                                    class="px-4 py-2 mb-4 rounded-md border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300"
                                >
                                    See all locations
                                </a>
                            </div>
                                
                            <img class="mb-5" src="/img/pages/finance_options/synchrony-home-logo.png" alt="Synchrony Home Logo">
                                
                    
                            <h3 class="text-2xl mb-6 text-blue-700 font-semibold">
                                The Furniture Warehouse Synchrony HOME Card Financing Option(s).
                            </h3>


                            <div v-for="(option,i) in options" :key="option.id" class="mb-4">
                                <div class="mb-8 border-b border-gray-300">

                                    <div class="py-2">
                                        <h4 class="text-2xl font-semibold">
                                            {{ option.header }}
                                        </h4>
                                    </div>
                                    
                                    <div class="pl-10 mb-2">
                                        <p>
                                            {{ option.short_description }}
                                        </p>
                                    </div>

                                    <div class="mb-8">

                                        <div class="pl-10 mb-2 text-blue-700 font-semibold hover:underline hover:cursor-pointer" @click="toggle(i)">
                                            <div v-if="clickedItems.includes(i)" class="flex items-center">
                                                <p>
                                                    See less
                                                </p>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 ml-2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                                                </svg>
                                            </div>

                                            <div v-else class="flex items-center">
                                                <p>
                                                    See full details
                                                </p>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 ml-2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div v-if="clickedItems.includes(i)" class="whitespace-pre-line">
                                            {{ option.full_description }}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-wrap justify-center items-center mb-6 sm:mb-6 pb-5">
                                <div class="text-lg mb-4 sm:mb-0 mr-4">
                                    Don't have Synchrony HOME account?
                                </div>

                                <div class="">
                                    <a href="https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=82FDD7321D7D37B53EEB22C90DE0AECD25DF9E052A7F8934&client=The%20Furniture%20Warehouse" 
                                        target="_blank" 
                                        class="inline-block mx-auto px-4 py-1.5 border-2 border-gray-700 bg-gray-700 rounded shadow-md text-white font-semibold tracking-wider hover:bg-gray-50 hover:text-gray-700 cursor-pointer transition ease-in-out duration-300"
                                    >
                                        Apply Now
                                    </a>
                                </div>
                            </div>

                            <p class="mb-8 text-center">
                                Also, you can apply for Synchrony HOME at any of our 6 locations. 
                            </p>

                            <div class="mb-4 text-center">
                                <a href="/locations" 
                                    class="px-4 py-2 mb-4 rounded-md border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300"
                                >
                                    See all locations
                                </a>
                            </div>
                               
                        </div>

                        <!-- 2 Tab Content -->
                        <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">

                            <h2 class="text-3xl mb-8 text-blue-700">
                                Lease to Own Option
                            </h2>

                            <a href="https://approve.me/s/thefurniturewarehousecorporate/33257" target="_blank">
                                <img class="mb-8 rounded-md border border-gray-400 shadow-md" src="/img/pages/finance_options/progressive-leasing-white-banner-with-disclosures.png" alt="Progressive Leasing Banner">
                            </a>

                            <p class="mb-2">
                                Traditional lending options aren't a good fit for everyone which is why Progressive Leasing created a NO CREDIT NEEDED solution.
                                This program provides an alternate solution so you can acquire the item(s) you want or need today.
                            </p>

                            <p class="mb-5 text-center">
                                Please refer to Progressive Leasing for more information.
                            </p>

                            <div class="mb-16 text-center">
                                <a href="https://approve.me/s/thefurniturewarehousecorporate/33257" 
                                    target="_blank"
                                    class="px-4 py-2 mb-4 rounded-md border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300"
                                >
                                    Apply online now
                                </a>
                            </div>

                            <p class="mb-6 lg:mb-5 text-center"> 
                                Also you can apply in-person at any of our 6 locations. 
                            </p>

                            <div class="mb-10 text-center">
                                <a href="/locations" 
                                    class="px-4 py-2 mb-4 rounded-md border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300"
                                >
                                    See all locations
                                </a>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>

            <ul class="flex list-none">
                <li class="text-center">
                    <a href="#purchase_options" class="block px-5 py-2 sm:px-12 rounded-b-lg text-lg tracking-wider" v-on:click="toggleTabs(1)" v-bind:class="{'bg-blue-100 text-blue-700 cursor-pointer hover:bg-blue-400 hover:text-white': openTab !== 1, ' bg-blue-700 text-white cursor-default': openTab === 1}">
                        Financing
                    </a>
                </li>

                <li class="text-center">
                    <a href="#purchase_options" class="block px-5 py-2 sm:px-12 rounded-b-lg text-lg tracking-wider" v-on:click="toggleTabs(2)" v-bind:class="{'bg-blue-100 text-blue-700 cursor-pointer hover:bg-blue-400 hover:text-white': openTab !== 2, ' bg-blue-700 text-white cursor-default': openTab === 2}">
                        Lease to Own
                    </a>
                </li>
            </ul>

        </div>
    </div>

</template>


<script>

    export default {

        props: [

            'financing_options'
        ],

        data() {

            return {
                openTab: 1,
                options: this.financing_options,
                clickedItems:[]
            }
    },

    methods: {

        toggleTabs: function(tabNumber){

            this.openTab = tabNumber
        },

        toggle(i) {
            if (this.clickedItems.includes(i)) {

                this.clickedItems.splice(this.clickedItems.indexOf(i), 1);

            } else {

                this.clickedItems.push(i);
            }
        }
    }

    }
</script>