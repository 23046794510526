const state = {
    shippingQuote: {
        quote_price: 'TBD',
        display_quote_price: 'TBD', // adds a ($) when needed
        quote_label: 'Shipping/Delivery',
        quote_company: '',
        quote_carrier: '',
        quote_number: '',
        quote_address: '',
        quote_city: '',
        quote_state: '',
        quote_zip: '',
        quote_description: '',
        tax_rate: 'TBD',
        local_delivery_area: 'no',
        selected_quote: '',
        quote_count: '',
    }
};
const getters = {
    getShippingQuote: state => {
        return state.shippingQuote
    }
};
const actions = {
    initializeShippingQuote({state, commit}, payload) {
        commit('SET_SHIPPING_QUOTE', payload)
    },
    updateShippingQuote({state, commit}, payload) {
        commit('SET_SHIPPING_QUOTE', payload)
    }
};
const mutations = {
    SET_SHIPPING_QUOTE(state, payload) {
        state.shippingQuote = payload
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}