<template>
    <modal name="protection-plan-pricing-details" height="auto" :adaptive="true" :scrollable="true">

        <div class="w-full px-4 py-4 text-base text-gray-700">

            <div class="flex justify-end mb-2">
                <div class="px-2 py-1 bg-gray-700 hover:bg-blue-700 hover:cursor-pointer rounded-md" @click="closeThisModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            
             <p class="mb-4 text-center text-xl sm:text-2xl text-blue-700 font-semibold"> 
                Accidental Protection Plan Pricing Details
            </p>

            <table class="relative overflow-x-auto w-full mx-auto mb-8 text-left text-gray-500">
                <thead class="text-lg text-gray-900">
                    <tr class="border-b border-gray-300">
                        <th scope="col" class="px-2 sm:px-6 py-4 whitespace-nowrap">
                            All Protected Items <br class="inline-block sm:hidden" /> Subtotal
                        </th>
                        <th scope="col" class="px-2 sm:px-6 py-4">
                            
                        </th>
                        <th scope="col" class="px-2 sm:px-6 py-4 whitespace-nowrap">
                            Plan Fee
                        </th>
                    </tr>
                </thead>
                <tbody class="text-lg text-gray-900 font-semibold">
                    <tr v-for="(shield_product, index) in shield_products" :key="index" class="odd:bg-white even:bg-gray-100 border-b border-gray-300">
                        <td scope="row" class="px-2 sm:px-6  py-4 whitespace-nowrap">
                            ${{ shield_product.min_price_range }} - ${{ shield_product.max_price_range }}
                        </td>
                        <td scope="col" class="px-2 sm:px-6 py-4">
                            
                        </td>
                        <td scope="row" class="px-2 sm:px-6  py-4 whitespace-nowrap">
                            ${{ shield_product.retail }}
                        </td>
                    </tr>
                </tbody>
            </table>

            
            <div class="flex justify-center mb-10">
                <div class="mx-auto px-4 py-2 bg-gray-700 rounded-full text-white tracking-wider uppercase hover:bg-blue-700 hover:cursor-pointer" @click="closeThisModal">
                    Close
                </div>
            </div>

        </div>

    </modal>
</template>


<script>

export default {

    props: [
        'shield_products'
    ],

    methods: {

        closeThisModal: function()
        {
            this.$modal.hide('protection-plan-pricing-details');
        }
    }
}

</script>

