<template>
    <div class="w-full lg:w-1/2 mb-6 text-base text-gray-700">
        <div class="mx-2 my-1 sm:mx-8 lg:mx-3 border border-gray-400 rounded overflow-hidden shadow-md">
            <h2 class="mb-3 px-2 py-1 sm:px-5 bg-gray-200 text-xl text-blue-700">
                Order Summary
            </h2>

            <p class="mx-2 py-1 sm:mx-5 border-b border-gray-300">
                <span class="text-gray-500 italic">Subtotal:</span>
                <span class="float-right">${{ order_info.orderinfo.subtotal }}</span>
            </p>
            
            <p class="mx-2 py-1 sm:mx-5 border-b border-gray-300">
                <span class="text-gray-500 italic">Tax:</span>
                <span class="float-right">${{ order_info.orderinfo.tax }}</span>
            </p>

            <p class="mx-2 py-1 sm:mx-5 border-b border-gray-300">
                <span class="text-gray-500 italic">Delivery Charge:</span>
                <span class="float-right">${{ order_info.orderinfo.delcharge }}</span>
            </p>

            <p class="mx-2 mb-10 py-1 sm:mx-5 border-b border-blue-300">
                <span class="text-gray-500 italic">Order Total:</span>
                <span class="float-right">${{ order_info.orderinfo.total }}</span>
            </p>



            <p class="mb-2 mx-2 py-1 sm:mx-5">
                <span class="text-gray-500 italic">Deposit:</span>
                <span class="float-right">${{ order_info.orderinfo.deposit }}</span>
            </p>

            <p class="mb-3 mx-2 py-1 sm:mx-5">
                <span class="text-gray-500 italic">Amount Due:</span>
                <span class="mb-10 float-right text-red-700">${{ order_info.orderinfo.balance }}</span>
            </p>

            <div class="mx-2 sm:mx-5 mt-10">

                <h2 class="mb-4 px-2 py-1 sm:px-5 bg-gray-200 text-center text-xl text-blue-700">
                    Make Payment
                </h2>
                
                <div class="flex justify-between mb-4">
                    <span class="text-gray-500 italic">Amount:</span>
                    <div class="flex justify-end items-center">
                        <span class="mr-1">$</span>
                        <!-- <currency-input v-model="balance" currency="USD" locale="en" /> -->

                        <money 
                            type="text" 
                            name="amount" 
                            v-model="balance" 
                            v-bind="money" 
                            class="float-right px-2 py-1 bg-gray-100 text-right" 
                            required
                        >
                        </money>
                    </div>
                </div>

                <div class="flex justify-center">
                    <div 
                        v-if="this.displayAcceptTermsButton" 
                        @click="showMyAccountAcceptTermsModal" 
                        class="inline-block mb-4 px-4 py-2 bg-blue-500 rounded border border-blue-500 shadow-md text-center text-white font-semibold tracking-widest uppercase hover:bg-blue-300 hover:text-gray-900 cursor-pointer"
                    >
                        Continue
                    </div>
                </div>

                <div class="flex justify-center">
                    <div 
                        v-if="this.displayConfirmBillingButton" 
                        @click="showMyAccountConfirmBillingModal" 
                        class="inline-block mb-4 px-4 py-2 bg-blue-500 rounded border border-blue-500 shadow-md text-center text-white font-semibold tracking-widest uppercase hover:bg-blue-300 hover:text-gray-900 cursor-pointer"
                    >
                        Continue
                    </div>
                </div>


                <div class="flex justify-center">
                    <div v-if="this.displayPayNowButton"  class="inline-block mx-auto mb-4 ">
                        <div class="mb-2 content-center">
                            
                            <form :action="this.authorize_net.frog_order_payment_url" method="POST" id="paymentForm">
                        
                                <input type="hidden" name="_token" :value="csrf">
                                <input type="hidden" name="dataValue" id="dataValue">
                                <input type="hidden" name="dataDescriptor" id="dataDescriptor">
                                <input type="hidden" name="invoice" :value="order_info.orderinfo.invoicenumber">
                                <input type="hidden" name="subtotal" :value="order_info.orderinfo.subtotal">
                                <input type="hidden" name="tax" :value="order_info.orderinfo.tax">
                                <input type="hidden" name="payment" :value="balance">
                                <input type="hidden" name="delcharge" :value="order_info.orderinfo.delcharge">
                                
                                <button
                                    type="button" 
                                    class="AcceptUI block mx-auto mb-2 px-4 py-2 bg-green-400 rounded border border-green-500 shadow-md text-gray-900 hover:bg-green-300" 
                                    data-billingAddressOptions='{"show":true, "required":false}' 
                                    :data-apiLoginID="this.authorize_net.api_login_id" 
                                    :data-clientKey="this.authorize_net.client_key" 
                                    data-acceptUIFormBtnTxt="Pay Now" 
                                    data-acceptUIFormHeaderTxt="Card Information" 
                                    data-responseHandler="responseHandler" 
                                    id="btnSubmit">
                                    Pay With Credit Card
                                </button>
                            </form>

                        </div>

                        <div class="mb-6 text-center">
                            <p class="text-center italic">
                                We accept all major credit cards
                            </p>
                            <img src="/img/icons/credit-cards.png" class="" style="display: block; margin-left: auto; margin-right: auto;"  alt="We accept all major credit cards">
                        </div>

                    </div>
                </div>

            </div>
            

        </div>

        <my-account-confirm-billing-modal
            :invoice_details="this.invoice_details"
            :states="this.states">
        </my-account-confirm-billing-modal>

        <my-account-accept-terms-modal
            :invoice_details="this.invoice_details">
        </my-account-accept-terms-modal>

    </div> 
</template>

<script>

import {Money} from 'v-money';
import MyAccountAcceptTermsModal from './MyAccountAcceptTermsModal.vue';
import MyAccountConfirmBillingModal from './MyAccountConfirmBillingModal.vue';

export default {

    components: 
    {
        Money,
        'my-account-accept-terms-modal': MyAccountAcceptTermsModal,
        'my-account-confirm-billing-modal': MyAccountConfirmBillingModal
    },

    props: [
        'invoice_details',
        'payment_process',
        'authorize_net',
        'states'
    ],

    data () {
        return {
            order_info: this.invoice_details,
            balance: this.invoice_details.orderinfo.balance,
            // Button Logic Defaults
            displayConfirmBillingButton: false,
            displayAcceptTermsButton: false,
            displayPayNowButton: false,

            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),

            money: {
                decimal: '.',
                thousands: '',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: true
            },
        }
    },

    computed: {

        paymentProcess: {
            get() {
                return this.$store.state.myAccountPaymentSteps.paymentSteps;
            }
        }
    },

    mounted() {

        this.$store.dispatch('myAccountPaymentSteps/initializePaymentSteps', this.payment_process);

        // this.continueCheckoutSteps();  // the watcher does the job

    },

    watch: {

        paymentProcess: {
            handler(val) {
                
                this.continuePaymentSteps();
            },

            deep: true
        }
    },

    methods: {

        continuePaymentSteps: function()
        {
            // logic for payment steps
            // -------------------
            // It changes the button function to display Modals [AcceptTirms, ConfirmBilling,  Pay]
            // Each Modal will call the next one in the chain.
            // If user stops the modals then show continue button on the next step

            // 1) Accept Terms 
            // 2) Confirm Billing
            // 3) Pay 

            if (this.paymentProcess.termsAccepted == 'no')
            {
                // display (Continue) button. It opens (Accept Terms) modal
                this.displayConfirmBillingButton = false;
                this.displayAcceptTermsButton    = true;
                this.displayPayNowButton         = false;

            } else if (this.paymentProcess.termsAccepted == 'yes' && this.paymentProcess.userBillingConfirmed == 'no') {

                // display (Continue) button. It opens (My Account Confirm Billing) modal 
                this.displayConfirmBillingButton = true;
                this.displayAcceptTermsButton    = false;
                this.displayPayNowButton         = false;

            } else if (this.paymentProcess.termsAccepted == 'yes' && this.paymentProcess.userBillingConfirmed == 'yes') {

                // display (Pay with Credit Card) button. It opens (Pay Now) modal
                this.displayConfirmBillingButton = false;
                this.displayAcceptTermsButton    = false;
                this.displayPayNowButton         = true;

                this.loadAuthorizeAcceptUI();

            } else {

                // Initial Stage
                // display (Get Quote) button
                this.displayConfirmBillingButton = false;
                this.displayAcceptTermsButton    = true;
                this.displayPayNowButton         = false;
            }
        },

        submitPayment ()
        {
            // Do nothing
            // Authorize dot net JS will do the rest
            // alert('click');
        },

        showMyAccountConfirmBillingModal: function()
        {
            this.$modal.show('my-account-confirm-billing-modal');
        },

        hideMyAccountConfirmBillingModal: function()
        {
            this.$modal.hide('my-account-confirm-billing-modal');
        },

        showMyAccountAcceptTermsModal: function()
        {
            this.$modal.show('my-account-accept-terms-modal');
        },

        hideMyAccountAcceptTermsModal: function()
        {
            this.$modal.hide('my-account-accept-terms-modal');
        },

        loadAuthorizeAcceptUI()
        {
            var script = document.createElement('script');
            script.src = 'https://js.authorize.net/v3/AcceptUI.js';
            document.body.appendChild(script);

            // Button handler
            var script_2 = document.createElement('script');
            script_2.src = '/js/authorize_net.js';
            document.body.appendChild(script_2);
        },

    },

}

</script>
