<template>

    <vue-flux
        :options="vfOptions"
        :images="vfImages"
        :transitions="vfTransitions"
        ref="slider">

        <template v-slot:preloader>
            <flux-preloader />
        </template>

    </vue-flux>

</template>


<script>

import {
   VueFlux,
   FluxControls,
   FluxIndex,
   FluxPreloader
} from 'vue-flux';

export default {
   props: [
        'slides'
    ],

   components: {
      VueFlux,
      FluxControls,
      FluxIndex,
      FluxPreloader,
   },

   data: () => ({
      vfOptions: {
         autoplay: true,
         delay: 1400
      },
      vfImages: [],
      vfTransitions: ['fade'],
      
   }),

   mounted() {
        this.vfImages = this.slides;
    },
}

</script>