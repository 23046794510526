<template>
    <modal name="create-account-modal" height="auto" :scrollable="true" :adaptive="true">

        <div class="w-full px-4 py-4 text-base text-gray-700">

            <div class="flex justify-end">
                <div class="px-2 py-1 bg-gray-700 hover:bg-blue-700 hover:cursor-pointer rounded-md" @click="closeThisModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>

            <h2 class="text-3xl mt-2 mb-3 text-blue-700">
                Create New Account
            </h2>

            <p class="mb-5">
                Please take a moment to enter the requested information below. 
                Your phone number will be used as your main account number.
            </p>

            <form @submit.prevent="createAccountRequest" autocomplete="off">

                <show-form-validation-errors v-if="validationErrors" :errors="validationErrors" class="my-4">

                </show-form-validation-errors>


                <div class="mb-3">
                <label for="firstname" class="form-label inline-block text-gray-500">
                    First Name
                </label>
                <input
                    type="text"
                    name="firstname"
                    v-model="form.firstname"
                    class="
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-lg
                        font-normal
                        text-gray-700 
                        tracking-wider 
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-400
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 
                        focus:bg-white 
                        focus:border-blue-600 
                        focus:outline-none"
                    id="firstname"
                    required
                    />
                </div>


                <div class="mb-3">
                    <label for="lastname" class="form-label inline-block text-gray-500">
                        Last Name
                    </label>
                    <input
                        type="text"
                        name="lastname"
                        v-model="form.lastname"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="lastname"
                        required
                    />
                </div>


                <div class="mb-3">
                    <label for="phone" class="form-label inline-block text-gray-500">
                        Phone
                    </label>
                    <input
                        type="tel"
                        name="phone"
                        v-model="form.phone"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="phone"
                        v-mask="'###-###-####'" 
                        placeholder="Example: ###-###-####"
                        required
                    />
                </div>


                <div class="mb-3">
                    <label for="email" class="form-label inline-block text-gray-500">
                        Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        v-model="form.email"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="email"
                        required
                    />
                </div>


                <div class="mb-3">
                    <label for="password" class="form-label inline-block text-gray-500">
                        Password
                    </label>
                    <input
                        type="password"
                        name="password"
                        v-model="form.password"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="password"
                        placeholder="must be at least 6 characters long"
                        required
                    />
                </div>


                <div class="mb-8">
                    <label for="password_confirmation" class="form-label inline-block text-gray-500">
                        Confirm Password
                    </label>
                    <input
                        type="password"
                        name="password_confirmation"
                        v-model="form.password_confirmation"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="password_confirmation"
                        required
                    />
                </div>


                <div v-if="displaySpinner">
                    <h2 class="text-2xl mb-4 text-blue-700 text-center">
                        Please wait.
                    </h2>
                    <div class="mb-4 text-center">

                        <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                            <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                            c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="2s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                            c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                            L82,35.7z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1.5s" 
                                    from="0 50 50"
                                    to="-360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                </div>


                <div v-if="! displaySpinner">
                    <button role="submit" 
                            v-if="! disableButton" 
                            class="inline-block px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-700 hover:bg-blue-100 text-lg text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300">
                        Create Account
                    </button>
                    
                    <div v-if="disableButton" 
                        class="inline-block px-6 py-2 mb-6 border border-gray-600 rounded bg-gray-100 text-lg text-gray-600 font-semibold uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                        Fill Out The Form
                    </div>
                </div>

            </form>
                
            <div class="flex justify-end mb-3 pt-4 border-t border-gray-300">
                <button type="button" 
                        class="text-lg text-blue-700 font-semibold hover:underline" 
                        @click="displayLoginModal">
                    Back to Login
                </button>
            </div>

            <div class="flex justify-center mb-6">
                <div class="mx-auto px-4 py-2 bg-gray-700 rounded-full text-white tracking-wider uppercase hover:bg-blue-700 hover:cursor-pointer" 
                    @click="closeThisModal">
                    Close
                </div>
            </div>

        </div>

    </modal>
</template>

<script>

import ShowFormValidationErrors from './ShowFormValidationErrors.vue';


export default {

    components: {
        'show-form-validation-errors': ShowFormValidationErrors
    },

    computed: {
        checkoutProcess: {
            get() {
                return this.$store.state.cartCheckoutProcess.checkoutSteps;
            }
        },

        userBillingShipping: {
            get() {
                return this.$store.state.cartUserBillingShipping.userBillingShipping;
            }
        }
    },

    data: function () {
        return {
            form: {
                firstname: '',
                lastname: '',
                phone: '',
                email: '',
                password: '',
                password_confirmation: '',
            },
            watch_firstname: false,
            watch_lastname: false,
            watch_phone: false,
            watch_email: false,
            watch_password: false,
            watch_password_confirmation: false,
            displaySpinner: false,
            disableButton: true,
            axios_response: '',
            validationErrors: '',
        }
    },

    // mounted: function () {
    //     if(this.phone.length > 11)
    //     {
    //         this.watch_phone = true;
    //     }

    //     if(this.email.length > 6)
    //     {
    //         this.watch_email = true;
    //     }
    // },

    watch:{

        'form.firstname': function(val, oldVal)
        {
            if (this.form.firstname.length > 2) 
            {
                this.watch_firstname = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_firstname = false;
            }
        },

        'form.lastname': function(val, oldVal)
        {
            if (this.form.lastname.length > 2) 
            {
                this.watch_lastname = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_lastname = false;
            }
        },

        'form.phone': function(val, oldVal)
        {
            if (this.form.phone.length > 11) 
            {
                this.watch_phone = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_phone = false;
            }
        },

        'form.email': function(val, oldVal)
        {
            if (this.form.email.length > 6) 
            {
                this.watch_email = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_email = false;
            }
        },

        'form.password': function(val, oldVal)
        {
            if (this.form.password.length > 5)
            {
                this.watch_password = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_password = false;
            }
        },

        'form.password_confirmation': function(val, oldVal)
        {
            if (this.form.password_confirmation.length > 1)
            {
                this.watch_password_confirmation = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_password_confirmation = false;
            }
        },
    },

    methods:{

        checkFormVariables ()
        {
            if(this.watch_firstname == true && this.watch_lastname == true && this.watch_phone == true && this.watch_password == true && this.watch_email == true && this.watch_password_confirmation == true)
            {
                this.disableButton = false;
            }
        },

        createAccountRequest ()
        {
            this.validationErrors = '';
            this.displaySpinner   = true;

            axios.post('/checkout-create-account', this.form)
                .then((response) => {
                    this.axios_response = response.data;
                    this.updateStoreBillingShipping();
                    
                    this.updateStoreCheckoutProcess();
                    this.displaySpinner = false;
                    // TEST
                    // alert('Success');
                    this.$modal.show('confirm-billing-modal');
                    this.$modal.hide('create-account-modal');
                })

                .catch(error => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                    this.displaySpinner = false;
                });

        },

        updateStoreBillingShipping()
        {
            // Billing Info
            this.userBillingShipping.token              = this.axios_response['token'];
            this.userBillingShipping.billing_phone      = this.axios_response['billing_phone'];
            this.userBillingShipping.billing_name       = this.axios_response['billing_name'];
            this.userBillingShipping.billing_firstname  = this.axios_response['billing_firstname'];
            this.userBillingShipping.billing_lastname   = this.axios_response['billing_lastname'];
            this.userBillingShipping.billing_address    = this.axios_response['billing_address'];
            this.userBillingShipping.billing_city       = this.axios_response['billing_city'];
            this.userBillingShipping.billing_state      = this.axios_response['billing_state'];
            this.userBillingShipping.billing_zip        = this.axios_response['billing_zip'];
            this.userBillingShipping.billing_email      = this.axios_response['billing_email'];
            // Shipping Info
            this.userBillingShipping.shipping_phone     = this.axios_response['shipping_phone'];
            // this.userBillingShipping.shipping_name      = this.axios_response['shipping_name'];
            this.userBillingShipping.shipping_name      = ''; // register API doesn't return it
            this.userBillingShipping.shipping_firstname = this.axios_response['shipping_firstname'];
            this.userBillingShipping.shipping_lastname  = this.axios_response['shipping_lastname'];
            this.userBillingShipping.shipping_address   = this.axios_response['shipping_address'];
            this.userBillingShipping.shipping_city      = this.axios_response['shipping_city'];
            this.userBillingShipping.shipping_state     = this.axios_response['shipping_state'];
            this.userBillingShipping.shipping_zip       = this.axios_response['shipping_zip'];

            this.$store.dispatch('cartUserBillingShipping/updateUserBillingShipping', this.userBillingShipping);
        },

        updateStoreCheckoutProcess() 
        {
            // update store (cartCheckoutProcess)
            this.checkoutProcess.userLoggedIn = 'yes';
            this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);

        },

        displayLoginModal ()
        {
            this.$modal.show('login-modal');
            this.$modal.hide('create-account-modal');
        },

        closeThisModal: function()
        {
            this.$modal.hide('create-account-modal');
        }

    }
}

</script>
