<template>
    <div class="relative">
        <div class="">
            <form id="desktop-filters" :action="pagination.path" method="GET">

                <fieldset>
                    <legend class="mb-3 pb-1 border-b border-gray-700 text-xl text-gray-700">
                        Sort by Price:
                    </legend>
                    
                    <ul class="ml-2 mb-6">
                        <li v-for="(price, index) in filters.price" :key="index">
                            <div class="flex items-center mb-2">
                                <input :id="price.value" type="radio" name="price" :value="price.value" :checked="price.checked"  class="w-5 h-5 mr-3 text-gray-600" />
                                <label :for="price.value" class="mr-4">
                                    {{ price.label }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </fieldset>
                
                <fieldset>
                    <legend class="mb-3 pb-1 border-b border-gray-700 text-xl text-gray-700">
                        Sort by Brand:
                    </legend>

                    <ul class="ml-2 mb-6">
                        <li v-for="(brand, index) in filters.brand" :key="index">
                            <div class="flex items-center mb-2">
                                <input class="w-5 h-5 mr-3" 
                                        type="checkbox" 
                                        name="brand[]" 
                                        :id="brand.name" 
                                        :value="brand.name" 
                                        :checked="brand.checked" />
                                <label :for="brand.name" class="mr-4">
                                    {{ brand.label }} 
                                </label>
                            </div>
                        </li>
                    </ul>
                </fieldset>
                
                <fieldset>
                    <legend class="mb-3 pb-1 border-b border-gray-700 text-xl text-gray-700">
                        Sort by Size:
                    </legend>
                
                    <ul class="ml-2 mb-6">
                        <li v-for="(size, index) in filters.size" :key="index">
                            <div class="flex items-center mb-2">
                                <input class="w-5 h-5 mr-3" 
                                        type="checkbox" 
                                        name="size[]" 
                                        :id="size.value" 
                                        :value="size.value" 
                                        :checked="size.checked" />
                                <label :for="size.value" class="mr-4">
                                    {{ size.label }} 
                                </label>
                            </div>
                        </li>
                    </ul>
                </fieldset>
                
                <fieldset>
                    <legend class="mb-3 pb-1 border-b border-gray-700 text-xl text-gray-700">
                        Sort by Firmness:
                    </legend>
                
                    <ul class="ml-2 mb-6">
                        <li v-for="(firmness, index) in filters.firmness" :key="index">
                            <div class="flex items-center mb-2">
                                <input class="w-5 h-5 mr-3" 
                                        type="checkbox" 
                                        name="firmness[]" 
                                        :id="index" 
                                        :value="firmness.value" 
                                        :checked="firmness.checked" />
                                <label :for="index" class="mr-4">
                                    {{ firmness.label }} 
                                </label>
                            </div>
                        </li>
                    </ul>
                </fieldset>

                <div class="flex flex-wrap justify-start items-center">
                    <input type="hidden" id="page" name="page" value="1" />
                    <button type="submit"
                        class="px-4 py-2 mr-4 xl:mr-8 mb-4 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg" 
                    >
                        Sort 
                    </button>
                    
                    <div class="mb-4">
                        <form id="reset" :action="pagination.path" method="GET">
                            <button type="submit"
                                class="px-4 py-2 rounded border border-gray-700 bg-gray-700 hover:bg-gray-100 text-white hover:text-gray-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg" 
                            >
                                Reset All
                            </button>

                        </form>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<script>

    export default {


        props: [
            'pagination',
            'mattress_filters'
        ],

        data: function () {

            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                filters: this.mattress_filters,
            }
        },
        
        methods:{
            
        
        }
    }
</script>