<template>
    <modal name="synchrony-options-modal" height="auto" :adaptive="true" :scrollable="true">
        <div>
            <div class="flex justify-end p-4">
                <div @click="closeThisModal" 
                    class="px-2 py-1 rounded-md bg-gray-700 hover:bg-gray-100 border-2 border-gray-700 text-white hover:text-gray-900 cursor-pointer transition ease-in-out duration-300" 
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>

            <div class="p-4 mb-6 bg-blue-300">
                <div class="flex items-center">
                    <div class="hidden sm:inline-block sm:flex-none">
                        <img src="/img/pages/finance_options/synchrony-home-logo.png" class="object-contain max-h-12" alt="Synchrony Home Logo">
                    </div>

                    <div class="flex-auto">
                        <h2 class="text-2xl text-center text-gray-700">
                            The Furniture Warehouse Synchrony HOME Credit Card Financing Option(s).
                        </h2>
                    </div>
                </div>
            </div>

            <div class="w-full px-4 text-base text-gray-700">

                <div class="sm:flex sm:justify-around sm:items-center mb-8 sm:mb-6 pb-5 border-b border-gray-300">
                    <div class="text-lg mb-4 sm:mb-0">
                        Don't have Synchrony HOME account?
                    </div>

                    <div class="">
                        <a href="https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=82FDD7321D7D37B53EEB22C90DE0AECD25DF9E052A7F8934&client=The%20Furniture%20Warehouse" 
                            target="_blank" 
                            class="inline-block mx-auto mb-2 px-4 py-2 rounded bg-gray-700 hover:bg-gray-100 border border-gray-700 shadow-md text-white hover:text-gray-900 font-semibold tracking-wider cursor-pointer transition ease-in-out duration-300" 
                        >
                            Apply Now
                        </a>
                    </div>
                </div>

                <div class="mb-8 pb-5 border-b border-gray-300">
                    <div class="mb-4 sm:mb-0 text-2xl text-center text-blue-800">
                        Please select available option below to continue.
                    </div>
                </div>

                <form @submit.prevent="processSelectFinancingOption">
                    <div v-for="(option,i) in options" :key="option.id" class="mb-4">
                        
                        <div class="mb-8 border-b border-gray-300">
                            <div class="py-2">
                                <div class="mb-4 text-red-500" v-if="cartSubtotal < option.cart_minimum_subtotal">
                                    This option is not available. Your shopping cart subtotal is less then ${{ option.cart_minimum_subtotal }}.
                                </div>
                                
                                <div class="text-lg font-semibold">
                                    <input class="h-4 w-4 mr-4" 
                                        type="radio" 
                                        :id="option.id" name="syncronyCode" 
                                        :value="option.synchrony_code" 
                                        :disabled="cartSubtotal < option.cart_minimum_subtotal" 
                                        v-model="pickedFinancingOption" 
                                    />
                                    
                                    <label class="text-lg" :for="option.synchrony_code">
                                        {{ option.header }}
                                    </label>
                                </div>
                            </div>
                            
                            <div class="pl-10 mb-2">
                                {{ option.short_description }} 
                                <span class="text-red-700 font-semibold">
                                    (Your cart shipping and sales tax: ${{ taxAndDelivery }})
                                </span>
                            </div>

                            <div class="mb-8">
                                <div class="pl-10 mb-2 text-blue-700 font-semibold hover:underline hover:cursor-pointer" @click="toggle(i)">
                                    <div v-if="clickedItems.includes(i)" class="flex items-center">
                                        <p>
                                            See less
                                        </p>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 ml-2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                                        </svg>
                                    </div>

                                    <div v-else class="flex items-center">
                                        <p>
                                            See full details
                                        </p>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 ml-2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
                                        </svg>
                                    </div>
                                </div>

                                <div v-if="clickedItems.includes(i)">
                                    {{ option.full_description }}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div v-if="displaySpinner">
                        <h2 class="text-2xl mb-4 text-blue-700 text-center">
                            Please wait.
                        </h2>
                        <div class="mb-4 text-center">

                            <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                                <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                                c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                    <animateTransform 
                                        attributeName="transform" 
                                        attributeType="XML" 
                                        type="rotate"
                                        dur="2s" 
                                        from="0 50 50"
                                        to="360 50 50" 
                                        repeatCount="indefinite" />
                                </path>
                                <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                                c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                    <animateTransform 
                                        attributeName="transform" 
                                        attributeType="XML" 
                                        type="rotate"
                                        dur="1s" 
                                        from="0 50 50"
                                        to="360 50 50" 
                                        repeatCount="indefinite" />
                                </path>
                                <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                                L82,35.7z">
                                    <animateTransform 
                                        attributeName="transform" 
                                        attributeType="XML" 
                                        type="rotate"
                                        dur="1.5s" 
                                        from="0 50 50"
                                        to="-360 50 50" 
                                        repeatCount="indefinite" />
                                </path>
                            </svg>
                        </div>
                    </div>

                    <div v-if="! displaySpinner" class="mb-6 text-center">
                        <button role="submit" v-if="displaySubmitButton" 
                            class="inline-block mx-auto mb-6 px-6 py-2 rounded bg-blue-700 hover:bg-blue-100 border border-blue-700 shadow-md text-white hover:text-blue-900 uppercase font-semibold tracking-wider cursor-pointer transition ease-in-out duration-300" 
                            >
                            Submit
                        </button>
                        
                        <div v-if="! displaySubmitButton" class="inline-block mx-auto px-6 py-2 mb-6 border border-gray-400 rounded bg-gray-300 uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                            Select Available Option
                        </div>
                    </div>
                </form>
            </div>

            <div class="flex justify-center mb-8">
                <div class="mx-auto px-4 py-2 rounded-full bg-gray-700 hover:bg-gray-100 border border-gray-700 shadow-md text-white hover:text-gray-900 font-semibold uppercase tracking-wider cursor-pointer transition ease-in-out duration-300" 
                    @click="closeThisModal"
                >
                    Close
                </div>
            </div>
        </div>
    </modal>
</template>



<script>


export default {

    props: [

        'financing_options'
    ],

    data () {
        return {
            
            syncronyCode: '',
            displaySpinner: false,
            disableButton: true,
            displayFullDescription: false,
            clickedItems:[],
            cartSubtotal: '',
            salesTax: '',
            deliveryCharge: '',
            taxAndDelivery: '',
            options: this.financing_options,
            displaySubmitButton: false,
            pickedFinancingOption: '',
            
        }
    },

    computed: {
        shoppingCartSummary: {
            get() {
                return this.$store.state.cartDetails.cartSummary;
            }
        },

        checkoutProcess: {
            get() {
                return this.$store.state.cartCheckoutProcess.checkoutSteps;
            }
        }
    },


    created () {

        this.updateThisComponent();
    },


    mounted: function () {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            // check if we have quote from controller
            this.updateThisComponent();
        })
    },

    
    watch: {
        shoppingCartSummary: {

            handler(val) {
                
                this.updateThisComponent();
            },

            deep: true
        },

        pickedFinancingOption: function(val, oldVal)
        {
            if (this.pickedFinancingOption.length != 0)
            {
                this.displaySubmitButton = true;

            } else {

                this.displaySubmitButton = false;
            }
        }
    },

    methods: {

        toggle(i) {
            if (this.clickedItems.includes(i)) {

                this.clickedItems.splice(this.clickedItems.indexOf(i), 1);

            } else {

                this.clickedItems.push(i);
            }
        },

        updateThisComponent() {

            this.cartSubtotal   = parseFloat(this.shoppingCartSummary.subtotal);
            var tax             = parseFloat(this.shoppingCartSummary.tax);
            if (this.shoppingCartSummary.delcharge != 'FREE')
            {
                var delivery     = parseFloat(this.shoppingCartSummary.delcharge);
                var down_payment = tax + delivery;

            } else {

                var down_payment    = tax;
            }
            this.taxAndDelivery = down_payment.toFixed(2);
        },

        processSelectFinancingOption ()
        {
            // Local Scope
            // this.displaySpinner = true;

            // Update Checkout Process
            this.checkoutProcess.synchronyOptionSelected = 'yes';
            this.checkoutProcess.synchronyCode           = this.pickedFinancingOption;

            this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);

            // Global Scope
            this.$modal.show('spinner');

            this.$modal.hide('synchrony-options-modal');

        },

        closeThisModal: function()
        {
            this.$modal.hide('synchrony-options-modal');
        } 
    }
}

</script>

