<template>

<div class="flex flex-wrap max-w-screen-xl justify-center mx-auto my-10">

     <!-- Main Content  -->
    <div class="w-full md:w-2/3 mb-8 px-2 sm:px-4 text-base text-gray-700">

        <div class="flex justify-start content-center mb-3 pb-3 text-2xl border-b border-gray-300">
            <div class="flex-initial mr-4 px-4 bg-blue-300 text-blue-700 border border-blue-400 rounded-full">
                1
            </div>
            <div class="flex-auto text-blue-700">
                Checkout Billing
            </div>
        </div>

        <div class="mb-3 pb-10 border-b border-gray-300">
            <h2 class="mb-3 text-xl text-blue-700">
                Account Info
            </h2>

            <p class="mb-1 text-left">
                <span class="italic text-gray-500">
                    Phone:
                </span> 
                <span class="ml-1">
                    {{ billing.billing_phone }}
                </span>
            </p>

            <p class="mb-5 text-left">
                <span class="italic text-gray-500">
                    Email:
                </span> 
                <span class="ml-1">
                    {{ billing.billing_email }}
                </span>
            </p>

            <h2 class="mb-3 text-xl text-blue-700">
                Billing Address
            </h2>

            <div class="flex items-center mb-8 p-4 bg-red-200 border border-red-500 rounded">
                <div class="hidden sm:block">
                    <i class="fa fa-exclamation-triangle text-orange-700"></i>
                </div>
                <div class="ml-0 sm:ml-4">
                    <p class="text-base text-red-800">
                        Customer billing address must match billing address on credit card.
                    </p>
                </div>
            </div>

            <slot></slot>
            

            <form class="" action="/checkout-shipping" method="post">
                <input type="hidden" name="_token" :value="csrf">
                <input type="hidden" name="phone" :value="billing_phone">
                <input type="hidden" name="email" :value="billing_email">

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="name" 
                        id="name" 
                        v-model="billtoname" 
                        placeholder="Full Name" 
                        required> 

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="addr1" 
                        v-model="billing_address" 
                        placeholder="Street Address" 
                        required>   
                        
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="city" 
                        v-model="billing_city" 
                        placeholder="City" 
                        required> 

                <select name="state" v-model="billing_state" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  required>
                    <option value="">Choose State</option>

                    <option v-for="(all_state, index) in all_states" :value="all_state.abbr" :key="index">
                        {{ all_state.name }}
                    </option>
                </select>

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="zip" 
                        v-mask="'#####'" 
                        v-model="billing_zip" 
                        placeholder="Zip/Postal Code" 
                        required> 

                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-10 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="phone2"
                        v-mask="'###-###-####'"  
                        v-model="billing_phone_2" 
                        placeholder="Second Phone (optional)"> 

                <div v-if="displayFakeButton" class="mb-8">
                    <span class="px-6 py-3 bg-gray-300 rounded border border-gray-500 uppercase tracking-wider text-gray-700 cursor-not-allowed">
                        Continue to Shipping
                    </span>
                </div>

                <button 
                    type="submit" 
                    class="px-6 py-2 mb-8 border border-green-500 rounded bg-green-400 uppercase tracking-wider text-gray-900 hover:bg-green-300 focus:shadow-outline focus:outline-none" 
                    v-if="displayRealButton">
                    Continue to Shipping
                </button>
                    
            </form>

            <div class="flex items-center mb-8 p-4 bg-red-200 border border-red-500 rounded">
                <div class="hidden sm:block">
                    <i class="fa fa-info fa-2x text-red-700"></i>
                </div>
                <div class="ml-0 sm:ml-4">
                    <p class="text-base text-red-700">
                        Upon delivery or pickup customer must present original credit card. 
                        A picture of the photo ID will be taken at the time of delivery or pickup.
                    </p>
                </div>
            </div>

        </div>

        <div class="flex justify-start content-center mb-3 pb-3 text-2xl border-b border-gray-300 cursor-not-allowed">
            <div class="flex-initial mr-4 px-4 bg-gray-300 text-gray-500 border border-gray-400 rounded-full">
                2
            </div>
            <div class="flex-auto text-gray-500">
                Checkout Shipping
            </div>
        </div>

        <div class="flex justify-start content-center mb-3 pb-3 text-2xl border-b border-gray-300 cursor-not-allowed">
            <div class="flex-initial mr-4 px-4 bg-gray-300 text-gray-500 border border-gray-400 rounded-full">
                3
            </div>
            <div class="flex-auto text-gray-500">
                Checkout Payment
            </div>
        </div>

    </div>

    <!-- Sidebar -->
    <div class="w-full md:w-1/3 px-4 md:border-l border-gray-300 text-gray-700 text-base">

        <h2 class="text-3xl mb-6 text-blue-700 text-center">
            Cart Summary
        </h2>

        <div class="relative mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">

            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <div class="text-base text-gray-600 italic">
                    Subtotal:
                </div>
                
                <div class="text-lg font-semibold">
                    ${{ this.cart_subtotal }}
                </div>     
            </div>

            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <div class="text-base text-gray-600 italic">
                    Estimated Tax:
                </div>
                
                <div class="text-lg font-semibold">
                    TBD
                </div>     
            </div>

            <div class="mb-2 pb-2 border-b border-gray-400">

                <div class="flex justify-between mb-2"> 
                    <div class="text-gray-600 text-lg italic">
                        {{ this.quote_label }}:
                    </div>
                    
                    <div class="text-xl font-semibold">
                        {{ this.show_quote_price }}
                    </div>     
                </div>

                <div v-if="showQuoteDestinationDetails" class="text-sm text-center">
                    {{ this.quote_city }}, {{ this.quote_state }} {{ this.quote_zip }}
                </div>

            </div>

            <div class="flex justify-between mb-5 pb-2 border-b border-gray-400"> 
                <div class="text-gray-600 italic">
                    Estimated Total:
                </div>
                
                <div class="text-lg font-semibold">
                    ${{ this.show_estimated_total }}
                </div>     
            </div>
        </div>

        <h2 class="text-3xl mb-6 text-blue-700 text-center">
            Cart Items
        </h2>

        <div class="mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">
            <div v-for="cart_item in cart_items" :key="cart_item.skuserial">
                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        SKU Serial:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        {{ cart_item.skuserial }}
                    </span>     
                </div>

                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        Item:
                    </span>

                    <span class="text-lg font-semibold">
                        {{ cart_item.description }}
                    </span>     
                </div>

                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        Quantity:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        {{ cart_item.qty }}
                    </span>     
                </div>
                <div class="mb-2 pb-2 border-b border-gray-400"> 
                    <span class="mr-2 text-gray-600 italic">
                        Price:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        ${{ cart_item.price }}
                    </span>     
                </div>
            </div>

            <div class="mt-8 mb-5 text-center">
                <a href="/shopping-cart" class="px-6 py-2 border border-gray-500 rounded bg-gray-300 tracking-wider text-gray-900 hover:bg-gray-400 focus:shadow-outline focus:outline-none">
                    Edit Shopping Cart
                </a>
            </div>

        </div>

    </div>


</div>
        
</template>

<script>

// this is the OLD component
// we used it for our old website
// with XML API

    export default {
        props: [
            'billing',
            'delivery',
            'shoppingcart',
            'states',
            'cartitems',
        ],
        
        data() {
            return {
                billtoname: this.billing.billtoname,
                billing_phone: this.billing.billing_phone,
                billing_phone_2: this.billing.billing_phone_2,
                billing_email: this.billing.billing_email,
                billing_address: this.billing.billing_address,
                billing_city: this.billing.billing_city,
                billing_state: this.billing.billing_state,
                billing_zip: this.billing.billing_zip,
                name: false,
                address: false,
                city: false,
                state: false,
                zip: false,
                displayRealButton: false,
                displayFakeButton: true,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                all_states: this.states,
                cart_items: this.cartitems,
                // Quote Data
                quote_price: this.delivery.quote_price,
                quote_label: this.delivery.quote_label,
                quote_carrier: this.delivery.quote_carrier,
                quote_number: this.delivery.quote_number,
                quote_city: this.delivery.quote_city,
                quote_state: this.delivery.quote_state,
                quote_zip: this.delivery.quote_zip,
                quote_description: this.delivery.quote_description,
                // Shopping Cart Summary
                cart_subtotal: this.shoppingcart.subtotal,
                show_estimated_total: this.shoppingcart.subtotal,
                show_quote_price: '',  // sometimes it has a ($) dollar sign prefix
                showQuoteDestinationDetails: false,
            }
        },
        mounted() {
            if (this.billtoname != '')
            {
                this.name = true;
            }

            if (this.billing_address != '')
            {
                this.address = true;
            }

            if (this.billing_city != '')
            {
                this.city = true;
            }

            if (this.billing_state != '')
            {
                this.state = true;
            }

            if (this.billing_zip != '')
            {
                this.zip = true;
                this.checkoutForm();
            }

            if (this.quote_price == 'TBD' || this.quote_price == 'FREE')
            {
                this.show_quote_price = this.quote_price;
                this.showQuoteDestinationDetails = false;

            } else {

                this.show_quote_price = '$' + this.quote_price;
                this.showQuoteDestinationDetails = true;
                this.calculateTotal();
            }

        },
        watch: {
            billtoname: function(val, oldVal)
            {
                if (this.billtoname.length != 0)
                {
                    this.name = true;
                    this.checkoutForm();

                } else {

                    this.name = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            billing_address: function(val, oldVal)
            {
                if (this.billing_address.length != 0)
                {
                    this.address = true;
                    this.checkoutForm();

                } else {

                    this.address = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            billing_city: function(val, oldVal)
            {
                if (this.billing_city.length != '')
                {
                    this.city = true;
                    this.checkoutForm();

                } else {

                    this.city = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            billing_state: function(val, oldVal)
            {
                if (this.billing_state.length != '')
                {
                    this.state = true;
                    this.checkoutForm();

                } else {

                    this.state = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            billing_zip: function(val, oldVal)
            {
                if (this.billing_zip.length == 5)
                {
                    this.zip = true;
                    this.checkoutForm();

                } else {

                    this.zip = false;
                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            

            

        },
        
        methods: {
            checkoutForm ()
            {
                if (this.name == true && this.address == true && this.city == true && this.state == true && this.zip == true)
                {
                    this.displayFakeButton = false;
                    this.displayRealButton = true;

                } else {

                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                }
            },

            calculateTotal: function ()
            {
                if (this.quote_price == 'TBD' || this.quote_price == 'FREE')
                {
                    this.show_estimated_total = this.cart_subtotal;

                } else {

                    var subtotal_num = parseFloat(this.cart_subtotal);
                    var deliverypice_num = parseFloat(this.quote_price);
                    var total = subtotal_num + deliverypice_num;
                    this.show_estimated_total = total.toFixed(2);
                }
            },
        },


    }
</script>
