<template>
    <div class="flex">
        <div class="w-full">

            <ul class="flex list-none mb-2">
                <li class="flex-auto text-center cursor-pointer">
                    <a class="block text-xs px-3 py-2 border rounded-t-lg" v-on:click="toggleTabs(1)" v-bind:class="{'border-gray-400 text-blue-600 bg-white': openTab !== 1, 'border-blue-600 text-white bg-blue-600': openTab === 1}">
                        Page 1
                    </a>
                </li>
                <li class="flex-auto text-center cursor-pointer">
                    <a class="block text-xs px-3 py-2 border rounded-t-lg" v-on:click="toggleTabs(2)" v-bind:class="{'border-gray-400 text-blue-600 bg-white': openTab !== 2, 'border-blue-600 text-white bg-blue-600': openTab === 2}">
                        Page 2
                    </a>
                </li>
                <li class="flex-auto text-center cursor-pointer">
                    <a class="block text-xs px-3 py-2 border rounded-t-lg" v-on:click="toggleTabs(3)" v-bind:class="{'border-gray-400 text-blue-600 bg-white': openTab !== 3, 'border-blue-600 text-white bg-blue-600': openTab === 3}">
                        Page 3
                    </a>
                </li>
                <li class="flex-auto text-center cursor-pointer">
                    <a class="block text-xs px-3 py-2 border rounded-t-lg" v-on:click="toggleTabs(4)" v-bind:class="{'border-gray-400 text-blue-600 bg-white': openTab !== 4, 'border-blue-600 text-white bg-blue-600': openTab === 4}">
                        Page 4
                    </a>
                </li>
            </ul>

            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 sm:border sm:border-gray-300 sm:shadow-all-around-15 rounded">
                <div class="px-0 py-0 md:px-4 md:py-5 flex-auto">
                    <div class="md:tab-content md:tab-space">

                        <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
                            <img class="" :src="insert.page1" alt="The Furniture Warehouse Newspaper - Page 1">
                        </div>

                        <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
                            <img class="" :src="insert.page2" alt="The Furniture Warehouse Newspaper - Page 2">
                        </div>
                        
                        <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
                            <img class="" :src="insert.page3" alt="The Furniture Warehouse Newspaper - Page 3">
                        </div>

                        <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}">
                            <img class="" :src="insert.page4" alt="The Furniture Warehouse Newspaper - Page 4">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  props: [
    'insert'
        ],
  data() {
    return {
      openTab: 1
    }
  },
  methods: {
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    }
  }
}
</script>