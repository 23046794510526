<template>
    <modal name="login-modal" height="auto" :adaptive="true" :scrollable="true">

        <div class="w-full px-4 py-4 text-base text-gray-700">

            <div class="flex justify-end">
                <div class="px-2 py-1 bg-gray-700 hover:bg-blue-700 hover:cursor-pointer rounded-md" @click="closeThisModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>

            <h2 class="text-3xl mt-2 mb-3 text-blue-700">
                Please Login
            </h2>

            <p class="mb-5">
                In order to proceed, please Login. If you do not have an account with us, 
                please create a new one by following the link below.
            </p>

            <form @submit.prevent="processLogin">

                <show-form-validation-errors v-if="validationErrors" :errors="validationErrors" class="my-4">

                </show-form-validation-errors>

                <div class="mb-3">
                    <label for="phone" class="form-label inline-block text-gray-500">
                        Phone
                    </label>
                    <input
                        type="tel"
                        name="phone"
                        v-model="form.phone"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="phone"
                        v-mask="'###-###-####'" 
                        placeholder="Example: ###-###-####"
                        autocomplete="phone"
                        required
                    />
                </div>


                <div class="mb-8">
                    <label for="password" class="form-label inline-block text-gray-500">
                        Password
                    </label>
                    <input
                        type="password"
                        name="password"
                        v-model="form.password"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="password" 
                        autocomplete="current-password" 
                        required
                    />
                </div>

                <input type="hidden" name="_token" :value="csrf" />
                
                <div v-if="displaySpinner">
                    <h2 class="text-2xl mb-4 text-blue-700 text-center">
                        Please wait.
                    </h2>
                    <div class="mb-4 text-center">

                        <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                            <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                            c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="2s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                            c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                            L82,35.7z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1.5s" 
                                    from="0 50 50"
                                    to="-360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                </div>

                <div v-if="! displaySpinner">
                    <button role="submit" v-if="! disableButton" 
                        class="inline-block px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-700 hover:bg-blue-100 text-lg text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300">
                        Sign in
                    </button>
                    
                    <div v-if="disableButton" 
                        class="inline-block px-6 py-2 mb-6 border border-gray-600 rounded bg-gray-100 text-lg text-gray-600 font-semibold uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                        Fill Out The Form
                    </div>
                </div>
            </form>


            <div class="flex justify-between mb-4 pt-4 border-t border-gray-300">
                <button type="button" class="text-lg text-blue-700 font-semibold hover:underline" @click="displayCreateAccountModal">
                    Create Account
                </button>
                
                <button type="button" class="text-lg text-blue-700 font-semibold hover:underline" @click="displayResetPasswordModal">
                    Forgot Password?
                </button>
            </div>

            <div class="flex justify-center mb-6">
                <div class="mx-auto px-4 py-2 bg-gray-700 rounded-full text-white tracking-wider uppercase hover:bg-blue-700 hover:cursor-pointer" @click="closeThisModal">
                    Close
                </div>
            </div>
        </div>

    </modal>
</template>



<script>
// check if user logged in
// 

import ShowFormValidationErrors from './ShowFormValidationErrors.vue';

export default {

    components: {
        'show-form-validation-errors': ShowFormValidationErrors
    },

    data () {
        return {
            form: {
                phone: '',
                password: '',
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            watch_phone: false,
            watch_password: false,
            disableButton: true,
            validationErrors: '',
            displaySpinner: false,
            userDetails: this.userBillingShipping,
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
    },

    computed: {
        
        checkoutProcess: {
            get() {
                return this.$store.state.cartCheckoutProcess.checkoutSteps;
            }
        },

        userBillingShipping: {
            get() {
                return this.$store.state.cartUserBillingShipping.userBillingShipping;
            }
        }
    },

    watch:{

        'form.phone': function(val, oldVal)
        {
            if (this.form.phone.length > 11)
            {
                this.watch_phone = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_phone = false;
            }
        },

        'form.password': function(val, oldVal)
        {
            if (this.form.password.length > 3)
            {
                this.watch_password = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_password = false;
            }
        }
    },

    methods: {

        checkFormVariables ()
        {
            if (this.watch_phone == true && this.watch_password == true)
            {
                this.disableButton = false;
            }
        },

        displayResetPasswordModal ()
        {
            this.$modal.show('reset-password-modal');
            this.$modal.hide('login-modal');
        },

        displayCreateAccountModal ()
        {
            this.$modal.show('create-account-modal');
            this.$modal.hide('login-modal');
        },

        processLogin ()
        {
            this.validationErrors = '';
            this.displaySpinner   = true;

            axios.post('/checkout-login', this.form)

            .then((response) => {
                this.checkoutProcess.userLoggedIn = 'yes';
                if (this.checkoutProcess.synchronyApproved == 'success')
                {
                    this.checkoutProcess.userBillingConfirmed = 'yes';
                    this.checkoutProcess.userShippingConfirmed = 'yes';
                    this.checkoutProcess.termsAccepted = 'yes';
                }
                this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);

                this.userDetails = response.data;
                this.$store.dispatch('cartUserBillingShipping/updateUserBillingShipping', this.userDetails);

                this.displaySpinner = false;
                // this.$modal.show('confirm-billing-modal');
                this.$modal.hide('login-modal');
            })

            .catch(this.loginFormReturnsError);

        },

        loginFormReturnsError: function(error)
        {
            this.displaySpinner = false;

            if (error.response.status == 422) {
                
                this.validationErrors = error.response.data.errors;
            }

            if (error.response.status == 419) {
                
                Swal.fire({
                    title: 'Error.',
                    html:
                        '<div style="margin:30px;"></div>' +
                        '<p class="text-lg leading-relaxed">' +
                        'Your session has expired due to idle timeout. Please refresh the page.' +
                        '</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(true);
                    }
                });
            }
        },

        openThisModal: function()
        {
            this.$modal.open('login-modal');
        },

        closeThisModal: function()
        {
            this.$modal.hide('login-modal');
        }
    }
}

</script>

