<template>
    <div class="mx-auto" style="max-width: 1920px;">
        <nav class="mx-2 mt-2" aria-label="Breadcrumb">
            <ul class="flex flex-wrap items-center text-base text-gray-700 ">
                <li class="flex items-center mr-2">
                    <a href="/" class="flex items-center capitalize hover:text-blue-700 hover:underline">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5 mr-2">
                            <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                            <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                        </svg>
                        <span class="">
                            Home
                        </span>
                    </a>
                </li>

                <li v-if="breadcrumbs['first_level_link'] && breadcrumbs['second_level_name']" class="flex items-center mr-2">
                    <a :href="breadcrumbs['first_level_link']" class="flex items-center capitalize hover:text-blue-700 hover:underline">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-5 w-5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                        </svg>
                        <span class="whitespace-nowrap">
                            {{ breadcrumbs['first_level_name'] }}
                        </span>
                    </a>
                </li>
               
                <li v-else-if="breadcrumbs['first_level_link']" aria-current="page">
                    <div class="flex items-center capitalize">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-5 w-5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                        </svg>
                        <span class="whitespace-nowrap">
                            {{ breadcrumbs['first_level_name'] }}
                        </span>
                    </div>
                </li>

                <li v-if="breadcrumbs['second_level_link'] && breadcrumbs['third_level_name']" class="flex items-center mr-2">
                    <a :href="breadcrumbs['second_level_link']" class="flex items-center capitalize hover:text-blue-700 hover:underline">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-5 w-5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                        </svg>
                        <span class="whitespace-nowrap">
                            {{ breadcrumbs['second_level_name'] }}
                        </span>
                    </a>
                </li>
               
                <li v-else-if="breadcrumbs['second_level_name']" aria-current="page">
                    <div class="flex items-center capitalize">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-5 w-5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                        </svg>
                        <span class="whitespace-nowrap">
                            {{ breadcrumbs['second_level_name'] }}
                        </span>
                    </div>
                </li>
                
                <li v-if="breadcrumbs['third_level_name'] != null" class="flex items-center" aria-current="page">
                    <div class="flex items-center capitalize">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-5 w-5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                        </svg>
                        <span class="">
                            {{ breadcrumbs['third_level_name'] }}
                        </span>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>

    export default {


        props: [
            'breadcrumbs'
            // 'shield_eligibility'
        ],

        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        
        methods:{
            
        
        }
    }
</script>