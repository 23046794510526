const state = {
    userBillingShipping: {
        token: '',
        billing_address: '',
        billing_city: '',
        billing_email: '',
        billing_firstname: '',
        billing_lastname: '',
        billing_name: '',
        billing_phone: '',
        billing_state: '',
        billing_zip: '',
        shipping_address: '',
        shipping_city: '',
        shipping_firstname: '',
        shipping_lastname: '',
        shipping_name: '',
        shipping_phone: '',
        shipping_state: '',
        shipping_zip: ''
    }
};
const getters = {
    getUserBillingShipping: state => {
        return state.userBillingShipping
    }
};

const actions = {
    initializeUserBillingShipping({state, commit}, payload) {
        commit('SET_USER_BILLING_SHIPPING', payload)
    },

    updateUserBillingShipping({state, commit}, payload) {
        commit('SET_USER_BILLING_SHIPPING', payload)
    }
};

const mutations = {
    SET_USER_BILLING_SHIPPING(state, payload) {
        state.userBillingShipping = payload
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}