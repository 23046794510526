<template>
    <modal name="confirm-shipping-modal" height="auto" :adaptive="true" :scrollable="true">

        <div class="w-full px-4 py-4 text-base text-gray-700">

            <div class="flex justify-end">
                <div class="px-2 py-1 bg-gray-700 hover:bg-blue-700 hover:cursor-pointer rounded-md" @click="closeThisModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>

            <h2 class="mt-2 mb-3 text-center text-3xl text-blue-700">
                Update / Confirm Shipping
            </h2>

            <p class="mb-5">
                Upon delivery or pickup customer must present original credit card. A picture of the photo ID will be taken at the time of delivery or pickup.
            </p>


            <form @submit.prevent="processConfirmShipping" autocomplete="off">

                <show-form-validation-errors v-if="validationErrors" :errors="validationErrors" class="my-4">

                </show-form-validation-errors>

                <div class="mb-3">
                    <label for="shipping_phone" class="form-label inline-block text-gray-500">
                        Phone
                    </label>
                    <input
                        type="text"
                        name="shipping_phone"
                        v-model="form.shipping_phone" 
                        v-mask="'###-###-####'" 
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="shipping_phone" 
                        placeholder="Shipping Phone Number" 
                        required
                    />
                </div>


                <div class="mb-3">
                    <label for="shipping_firstname" class="form-label inline-block text-gray-500">
                        First Name
                    </label>
                    <input
                        type="text"
                        name="shipping_firstname"
                        v-model="form.shipping_firstname"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="shipping_firstname"
                        required
                    />
                </div>


                <div class="mb-3">
                    <label for="shipping_lastname" class="form-label inline-block text-gray-500">
                        Last Name
                    </label>
                    <input
                        type="text"
                        name="shipping_lastname"
                        v-model="form.shipping_lastname"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="shipping_lastname"
                        required
                    />
                </div>


                <div class="mb-3">
                    <label for="shipping_address" class="form-label inline-block text-gray-500">
                        Address
                    </label>
                    <input
                        type="text"
                        name="shipping_address"
                        v-model="form.shipping_address"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="shipping_address"
                        required
                    />
                </div>


                <div class="mb-3">
                    <label for="shipping_city" class="form-label inline-block text-gray-500">
                        City
                    </label>
                    <input
                        type="text"
                        name="shipping_city"
                        v-model="form.shipping_city"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="shipping_city"
                        required
                    />
                </div>


                <div class="mb-3">
                    <label for="shipping_state" class="form-label inline-block text-gray-500">
                        State
                    </label>
                    <select 
                        v-model="form.shipping_state" 
                        class="form-select appearance-none
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700 
                            tracking-wider
                            bg-gray-100 bg-clip-padding bg-no-repeat
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                        id="shipping_state"
                        required
                    >
                        <option>Choose State</option>

                        <option v-for="(all_state, index) in all_states" :value="all_state.abbr" :key="index">
                            {{ all_state.name }}
                        </option>
                    </select>
                </div>


                <div class="mb-8">
                    <label for="shipping_zip" class="form-label inline-block text-gray-500">
                        Zip
                    </label>
                    <input
                        type="text"
                        name="shipping_zip"
                        v-model="form.shipping_zip"
                        class="
                            form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-lg
                            font-normal
                            text-gray-700 
                            tracking-wider 
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-400
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 
                            focus:bg-white 
                            focus:border-blue-600 
                            focus:outline-none"
                        id="shipping_zip"
                        required
                    />
                </div>

                
                <div v-if="displaySpinner">
                    <h2 class="text-2xl mb-4 text-blue-700 text-center">
                        Please wait.
                    </h2>
                    <div class="mb-4 text-center">

                        <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                            <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                            c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="2s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                            c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                            L82,35.7z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1.5s" 
                                    from="0 50 50"
                                    to="-360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                </div>

                <div v-if="! displaySpinner" class="mb-6 text-center">
                    <button role="submit" v-if="! disableButton" class="inline-block mx-auto px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-600 uppercase tracking-wider text-white focus:shadow-outline focus:outline-none">
                        Update/Confirm Shipping
                    </button>
                    
                    <div v-if="disableButton" class="inline-block mx-auto px-6 py-2 mb-6 border border-gray-400 rounded bg-gray-300 uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                        Fill Out The Form
                    </div>
                </div>
            </form>

            <div class="flex justify-center mb-6">
                <div class="mx-auto px-4 py-2 bg-gray-700 rounded-full text-white tracking-wider uppercase hover:bg-blue-700 hover:cursor-pointer" @click="closeThisModal">
                    Close
                </div>
            </div>

        </div>

    </modal>
</template>



<script>
import ShowFormValidationErrors from './ShowFormValidationErrors.vue';

export default {

    components: {
        'show-form-validation-errors': ShowFormValidationErrors
    },

    props: [
        'states',
    ],

    data () {

        return {
            form: {
                billing_phone: '',        // user ID in Frog
                shipping_phone: '',
                shipping_firstname: '',
                shipping_lastname: '',
                shipping_address: '',
                shipping_city: '',
                shipping_state: '',
                shipping_zip: '',
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            // Logic Variables
            shipping_phone: false,
            shipping_firstname: false,
            shipping_lastname: false,
            shipping_address: false,
            shipping_city: false,
            shipping_state: false,
            shipping_zip: false,
            disableButton: true,
            validationErrors: '',
            displaySpinner: false,
            recalculateShipping: 'no',
            all_states: this.states,
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
    },

    computed: {
        checkoutProcess: {
            get() {
                return this.$store.state.cartCheckoutProcess.checkoutSteps;
            }
        },

        userBillingShipping: {
            get() {
                return this.$store.state.cartUserBillingShipping.userBillingShipping;
            }
        },

        shippingQuote: {
            get() {
                return this.$store.state.cartShipping.shippingQuote;
            }
        }
    },

    mounted: function () {

        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            // check if we have quote from controller
            this.updateThisModal();
        })
    },

    watch:{

        userBillingShipping: {
            handler(val) {
                
                this.updateThisModal();
            },

            deep: true
        },

        'form.shipping_firstname': function(newVal, oldVal)
        {
            if (this.form.shipping_firstname.length > 1)
            {
                this.shipping_firstname = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.shipping_firstname = false;
            }
        },

        'form.shipping_lastname': function(newVal, oldVal)
        {
            if (this.form.shipping_lastname.length > 1)
            {
                this.shipping_lastname = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.shipping_lastname = false;
            }
        },

        'form.shipping_address': function(newVal, oldVal)
        {
            if (this.form.shipping_address.length > 1)
            {
                this.shipping_address = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.shipping_address = false;
            }

            // Shipping Recalculation Logic
            if (newVal != this.shippingQuote.quote_address)
            {
                this.recalculateShipping = 'yes';
            }

        },

        'form.shipping_city': function(newVal, oldVal)
        {
            if (this.form.shipping_city.length > 1)
            {
                this.shipping_city = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.shipping_city = false;
            }

            // Shipping Recalculation Logic
            if (newVal != this.shippingQuote.quote_city)
            {
                this.recalculateShipping = 'yes';
            }
        },

        'form.shipping_state': function(newVal, oldVal)
        {
            if (this.form.shipping_state.length > 1)
            {
                this.shipping_state = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.shipping_state = false;
            }

            // Shipping Recalculation Logic
            if (newVal != this.shippingQuote.quote_state)
            {
                this.recalculateShipping = 'yes';
            }
        },

        'form.shipping_zip': function(newVal, oldVal)
        {
            if (this.form.shipping_zip.length > 1)
            {
                this.shipping_zip = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.shipping_zip = false;
            }

            // Shipping Recalculation Logic
            if (newVal != this.shippingQuote.quote_zip)
            {
                this.recalculateShipping = 'yes';
            }
        },

        'form.shipping_phone': function(newVal, oldVal)
        {
            if (this.form.shipping_phone.length > 1)
            {
                this.shipping_phone = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.shipping_phone = false;
            }
        },

    },

    methods: {

        checkFormVariables ()
        {
            if (this.shipping_firstname == true && this.shipping_lastname == true && this.shipping_address == true && this.shipping_city == true && this.shipping_state == true && this.shipping_zip == true && this.shipping_phone == true )
            {
                this.disableButton = false;
            }
        },


        processConfirmShipping ()
        {
            this.validationErrors = '';
            this.displaySpinner   = true;

            axios.post('/checkout-confirm-shipping', this.form)

                .then(() => {
                    // Update Checkout Steps
                    this.updateStoreBillingShipping();
                    this.updateStoreShippingQuote();
                    this.updateCheckoutProcess();

                    this.displaySpinner = false;

                    this.$modal.show('accept-terms-modal');
                    this.$modal.hide('confirm-shipping-modal');
                })

                .catch(error => {
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                    }
                    this.displaySpinner = false;
                });

        },

        updateThisModal ()
        {
            // Initial state
            this.form.billing_phone      = this.userBillingShipping.billing_phone;      // user ID in Frog
            this.form.shipping_phone     = this.userBillingShipping.shipping_phone;
            this.form.shipping_firstname = this.userBillingShipping.shipping_firstname;
            this.form.shipping_lastname  = this.userBillingShipping.shipping_lastname;
            this.form.shipping_address   = this.userBillingShipping.shipping_address;
            this.form.shipping_city      = this.userBillingShipping.shipping_city;
            this.form.shipping_state     = this.userBillingShipping.shipping_state;
            this.form.shipping_zip       = this.userBillingShipping.shipping_zip;
        },

        updateStoreBillingShipping ()
        {
            // Billing Info
            this.userBillingShipping.shipping_phone      = this.form.shipping_phone;
            this.userBillingShipping.shipping_firstname  = this.form.shipping_firstname;
            this.userBillingShipping.shipping_lastname   = this.form.shipping_lastname;
            this.userBillingShipping.shipping_address    = this.form.shipping_address;
            this.userBillingShipping.shipping_city       = this.form.shipping_city;
            this.userBillingShipping.shipping_state      = this.form.shipping_state;
            this.userBillingShipping.shipping_zip        = this.form.shipping_zip;

            this.$store.dispatch('cartUserBillingShipping/updateUserBillingShipping', this.userBillingShipping);
        },

        updateStoreShippingQuote ()
        {
            this.shippingQuote.quote_address = this.form.shipping_address;
            this.shippingQuote.quote_city    = this.form.shipping_city;
            this.shippingQuote.quote_state   = this.form.shipping_state;
            this.shippingQuote.quote_zip     = this.form.shipping_zip;
            // update Store
            this.$store.dispatch('cartShipping/updateShippingQuote', this.shippingQuote);
        },

        updateCheckoutProcess () 
        {
            if (this.recalculateShipping == 'yes')
            {
                this.checkoutProcess.recalculateShipping = 'yes';
            }

            // update store (cartCheckoutProcess)
            this.checkoutProcess.userShippingConfirmed = 'yes';
            this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);

            // save to the DB
            this.updateCheckoutProcessInDatabase();

            // Default value
            this.recalculateShipping = 'no';
        },

        updateCheckoutProcessInDatabase ()
        {
            axios.post('/update-checkout-process', {
                user_shipping_confirmed: 'yes',
                _token: this.csrf,
            })

            .catch(this.updateCheckoutProcessReturnsError);
        },

        
        updateCheckoutProcessReturnsError: function(error)
        {
            this.$modal.hide('accept-terms-modal');

            location.reload(true);
        },

        shippingAddressChanged ()
        {
            this.recalculateShipping = 'yes';
        },

        openThisModal: function()
        {
            this.$modal.open('confirm-shipping-modal');
        },

        closeThisModal: function()
        {
            this.$modal.hide('confirm-shipping-modal');
        }
    }
}

</script>

