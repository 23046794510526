<template>

<div class="flex flex-wrap max-w-screen-xl justify-center mx-auto my-10">

    <!-- Main Content -->
    <div class="w-full md:w-2/3 mb-8 px-2 sm:px-4 text-base text-gray-700">

        <div class="mb-3 pb-3 text-2xl border-b border-gray-300 cursor-pointer" @click="showBilling = !showBilling">
            <div class="flex justify-start content-center">
                <div class="flex-initial mr-4 px-4 bg-green-300 text-green-700 border border-green-400 rounded-full">
                    1
                </div>
                <div class="flex-auto text-green-700">
                    Checkout Billing
                </div>
            </div>  
        </div>

        <div v-show="showBilling" class="flex mb-3 pb-3 text-base border-b border-gray-300">
            <div class="flex-1">
                {{ this.billing.billtoname }} <br>
                {{ this.billing.billing_address }} <br>
                {{ this.billing.billing_city }} <br>
                {{ this.billing.billing_state }} <br>
                {{ this.billing.billing_zip }} <br>
            </div>
            <div class="flex-1">
                <a href="/checkout-billing" class="text-blue-500 hover:cursor-pointer hover:text-blue-700">
                    <i class="fa fa-pencil-square-o mr-1" aria-hidden="true"></i>
                    edit billing
                </a>
            </div>
        </div>

        <div class="flex justify-start content-center mb-3 pb-3 text-2xl border-b border-gray-300">
            <div class="flex-initial mr-4 px-4 bg-blue-300 text-blue-700 border border-blue-400 rounded-full">
                2
            </div>
            <div class="flex-auto text-blue-700">
                Checkout Shipping
            </div>
        </div>

        <div class="mb-3 pb-10 border-b border-gray-300">

            <h2 class="mb-5 text-xl text-blue-700">
                Shipping Address
            </h2>

            <slot></slot>

            <div class="flex justify-start mb-5">
                <input class="mr-3 w-5 h-5" type="checkbox" name="shiptobilling" id="shiptobilling" v-model="same_as_billing"> 
                <span>Same as Billing</span>
            </div>
            
            <form class="" action="/checkout-payment" method="post">
                <input type="hidden" name="_token" :value="csrf">

                <p class="text-red-500" v-if="!name">
                    * Required field
                </p>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="name" 
                        id="name" 
                        v-model="shiptoname" 
                        placeholder="Full Name" 
                        required> 

                <p class="text-red-500" v-if="!phone">
                    * Required field
                </p>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="phone" 
                        id="phone" 
                        v-mask="'###-###-####'" 
                        v-model="shipping_phone" 
                        placeholder="Phone" 
                        required> 

                <p class="text-red-500" v-if="!address">
                    * Required field
                </p>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="addr1" 
                        id="addr1"
                        v-model="shipping_address" 
                        placeholder="Street Address" 
                        required>   

                <p class="text-red-500" v-if="!city">
                    * Required field
                </p>      
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="city" 
                        id="city"
                        v-model="shipping_city" 
                        placeholder="City" 
                        required> 
                        

                <p class="text-red-500" v-if="!state">
                    * Required field
                </p>  
                <select name="state" v-model="shipping_state" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required>
                    <option value="">Choose State</option>

                    <option v-for="(all_state, index) in all_states" :value="all_state.abbr" :key="index">
                        {{ all_state.name }}
                    </option>
                </select>


                <p class="text-red-500" v-if="!zip">
                    * Required field
                </p>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-10 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        type="text" 
                        name="zip" 
                        id="zip" 
                        v-mask="'#####'" 
                        v-model="shipping_zip" 
                        placeholder="Zip/Postal Code" 
                        required> 

                <input type="hidden" name="quote_price" v-model="quote_price">
                <input type="hidden" name="quote_label" v-model="quote_label">
                <input type="hidden" name="quote_carrier" v-model="quote_carrier">
                <input type="hidden" name="quote_number" v-model="quote_number">
                <input type="hidden" name="quote_city" v-model="quote_city">
                <input type="hidden" name="quote_state" v-model="quote_state">
                <input type="hidden" name="quote_zip" v-model="quote_zip">
                <input type="hidden" name="quote_description" v-model="quote_description">

                

                <div v-if="displayFakeButton" class="mb-3">
                    <span class="px-6 py-3 mb-6 bg-gray-300 rounded border border-gray-500 uppercase tracking-wider text-gray-700 cursor-not-allowed">
                        Continue to Payment
                    </span>
                </div>

                <button 
                    type="submit" 
                    class="px-6 py-2 mb-6 border border-green-500 rounded bg-green-400 uppercase tracking-wider text-gray-900 hover:bg-green-300 focus:shadow-outline focus:outline-none" 
                    v-if="displayRealButton">
                    Continue to Payment
                </button>

                <div class="flex items-center mb-6 p-4 bg-red-200 border border-red-500 rounded" v-if="displayRealButton">
                    <div class="hidden sm:block">
                        <i class="fa fa-info fa-2x text-red-700"></i>
                    </div>
                    <div class="ml-0 sm:ml-4">
                        <p class="text-base text-red-700">
                            Upon delivery or pickup customer must present original credit card. 
                            A picture of the photo ID will be taken at the time of delivery or pickup.
                        </p>
                    </div>
                </div>
                    
            </form>

            <div v-if="displayRecalculateForm">
                <form action="" method="POST" @submit.prevent="sendRequest">
                    <button  
                        class="block mb-2 px-4 py-2 bg-blue-600 rounded border border-blue-700 shadow-md text-white hover:bg-blue-400" 
                        type="submit">
                        Calculate Shipping/Delivery
                    </button>
                </form>
            </div>

            <template v-if="displaySpinner">
                <h2 class="text-2xl mb-4 text-blue-700 text-center">
                    Getting Quote ...
                </h2>
                <div class="mb-4 text-center">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw text-blue-700"></i>
                </div>
                <p class="text-center">
                    Please wait.
                </p>
            </template>


            <template v-if="displayNewQoute" class="max-w-sm mx-auto">
                <h2 class="mb-1 text-2xl text-blue-700 text-center">
                    Shipping/Delivery Quote
                </h2>

                <p class="mb-6 text-sm text-center">
                    To: {{ shipping_city }}, {{ shipping_state }} {{ shipping_zip }}
                </p>

                <template>
                    <div v-for="(quote, index) in quotes" :key="index">
                        <div class="border-b border-blue-500 text-xl text-blue-600">
                            <h4 class="text-center">{{ quote.header }}</h4>
                        </div>

                        <template v-if="quote.error == 0">
                            <div class="mb-8 border-b border-blue-500">
                                <div class="flex justify-between py-2 border-b border-gray-400">
                                    <p>{{ quote.lable }}</p>
                                    <div class="text-right text-lg font-semibold">
                                        <template v-if="quote.cost == 'FREE'">
                                            <label class="mr-2 text-lg" :for="quote.lable">
                                                {{ quote.cost }}
                                            </label>
                                        </template>
                                        <template v-else>
                                            <label class="mr-2 text-lg" :for="quote.lable">
                                                ${{ quote.cost }}
                                            </label>
                                        </template>
                                        <input type="radio" :id="quote.number" name="childQuoteID" :value="quote.id" v-model="childQuoteID" v-on:change="updateState" class="w-4 h-4">
                                    </div>
                                </div>
                                
                                <p class="py-2 text-sm text-center">
                                    {{ quote.description }}
                                </p>
                            </div>
                        </template>

                        <template v-else>
                            <div class="mb-8 border-b border-red-500 text-center">
                                <span class="text-red-500">Warning! </span> {{ quote.error }}
                            </div>
                        </template>
                    </div>
                </template>
                
            </template>

        </div>


        <div class="flex justify-start content-center mb-3 pb-3 text-2xl border-b border-gray-300 cursor-not-allowed">
            <div class="flex-initial mr-4 px-4 bg-gray-300 text-gray-500 border border-gray-400 rounded-full">
                3
            </div>
            <div class="flex-auto text-gray-500">
                Checkout Payment
            </div>
        </div>

    </div>


    <!-- Sidebar -->
    <div class="w-full md:w-1/3 px-4 md:border-l border-gray-300 text-base text-gray-700">

        <h2 class="text-3xl mb-6 text-blue-700 text-center">
            Cart Summary
        </h2>

        <div class="relative mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">

            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <div class="text-gray-600 italic">
                    Subtotal:
                </div>
                
                <div class="text-lg font-semibold">
                    ${{ this.cart_subtotal }}
                </div>     
            </div>

            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <div class="text-gray-600 italic">
                    Estimated Tax:
                </div>
                
                <div class="text-lg font-semibold">
                    TBD
                </div>     
            </div>

            <div class="mb-2 pb-2 border-b border-gray-400">

                <div class="flex justify-between mb-2"> 
                    <div class="text-gray-600 italic">
                        {{ this.quote_label }}:
                    </div>
                    
                    <div class="text-lg font-semibold">
                        {{ this.show_quote_price }}
                    </div>     
                </div>

                <div v-if="showQuoteDestinationDetails" class="text-sm text-center">
                    {{ this.quote_city }}, {{ this.quote_state }} {{ this.quote_zip }}
                </div>

            </div>

            <div class="flex justify-between mb-5 pb-2 border-b border-gray-400"> 
                <div class="text-gray-600 italic">
                    Estimated Total:
                </div>
                
                <div class="text-lg font-semibold">
                    ${{ this.show_estimated_total }}
                </div>     
            </div>
        </div>

        <h2 class="text-3xl mb-6 text-blue-700 text-center">
            Cart Items
        </h2>

        <div class="mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">

            <div v-for="cart_item in cart_items" :key="cart_item.skuserial">
                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        SKU Serial:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        {{ cart_item.skuserial }}
                    </span>     
                </div>

                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        Item:
                    </span>

                    <span class="text-lg font-semibold">
                        {{ cart_item.description }}
                    </span>     
                </div>

                <div class=""> 
                    <span class="mr-2 text-gray-600 italic">
                        Quantity:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        {{ cart_item.qty }}
                    </span>     
                </div>
                <div class="mb-2 pb-2 border-b border-gray-400"> 
                    <span class="mr-2 text-gray-600 italic">
                        Price:
                    </span>
                    
                    <span class="text-lg font-semibold">
                        ${{ cart_item.price }}
                    </span>     
                </div>
            </div>

            <div class="mt-8 mb-5 text-center">
                <a href="/shopping-cart" class="px-6 py-2 border border-gray-500 rounded bg-gray-300 tracking-wider text-gray-900 hover:bg-gray-400 focus:shadow-outline focus:outline-none">
                    Edit Shopping Cart
                </a>
            </div>

        </div>

    </div>

</div>
        
</template>

<script>
    export default {
        props: [
            'billing',
            'shipping',
            'delivery',
            'shoppingcart',
            'states',
            'cartitems',
        ],
        
        data() {
            return {
                // Check box as billing
                same_as_billing: false,
                // Form Variables
                shiptoname: '',
                shipping_phone: '',
                shipping_address: '',
                shipping_city: this.delivery.quote_city,
                shipping_state: this.delivery.quote_state,
                shipping_zip: this.delivery.quote_zip,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                // Variables for Form logic
                name: false,
                phone: false,
                address: false,
                city: false,
                state: false,
                zip: false,
                // Show / Hide Form Button
                displayRealButton: false,
                displayFakeButton: true,
                // Quote Data
                quote_price: this.delivery.quote_price,
                quote_label: this.delivery.quote_label,
                quote_carrier: this.delivery.quote_carrier,
                quote_number: this.delivery.quote_number,
                quote_city: this.delivery.quote_city,
                quote_state: this.delivery.quote_state,
                quote_zip: this.delivery.quote_zip,
                quote_description: this.delivery.quote_description,
                // Shopping Cart Summary
                cart_subtotal: this.shoppingcart.subtotal,
                show_estimated_total: this.shoppingcart.subtotal,
                show_quote_price: '',  // sometimes it has a ($) dollar sign prefix
                recalculationNeeded: false,
                displayRecalculateForm: false,
                displaySpinner: false,
                displayNewQoute: false,
                shippingFormIsCompleted: false,
                showQuoteDestinationDetails: false,
                quotes: [],
                selectedQuoteID: '',
                childQuoteID: '',
                all_states: this.states,
                cart_items: this.cartitems,
                showBilling: false,
                
            }
        },
        mounted() {
            if (this.shiptoname != '')
            {
                this.name = true;
            }

            if (this.shipping_phone != '')
            {
                this.phone = true;
            }

            if (this.shipping_address != '')
            {
                this.address = true;
            }

            if (this.shipping_city != '')
            {
                this.city = true;
            }

            if (this.shipping_state != '')
            {
                this.state = true;
            }

            if (this.shipping_zip != '')
            {
                this.zip = true;
                this.moduleLogic();
            }

            if (this.quote_price == 'TBD' || this.quote_price == 'FREE')
            {
                this.show_quote_price = this.quote_price;
                this.calculateTotal();

            } else {

                this.show_quote_price = '$' + this.quote_price;
                this.calculateTotal();
            }

        },
        watch: {
            shiptoname: function(val, oldVal)
            {
                if (this.shiptoname.length != 0)
                {
                    this.name = true;
                    this.moduleLogic();

                } else {

                    this.name = false;
                    this.moduleLogic();
                }
            },

            shipping_phone: function(val, oldVal)
            {
                if (this.shipping_phone.length != 0)
                {
                    this.phone = true;
                    this.moduleLogic();

                } else {

                    this.phone = false;
                    this.moduleLogic();
                }
            },

            shipping_address: function(val, oldVal)
            {
                if (this.shipping_address.length != 0)
                {
                    this.address = true;
                    this.moduleLogic();

                } else {

                    this.address = false;
                    this.moduleLogic();
                }
            },

            shipping_city: function(val, oldVal)
            {
                if (this.shipping_city.length != '')
                {
                    this.city = true;
                    this.moduleLogic();

                } else {

                    this.city = false;
                    this.moduleLogic();
                }
            },

            shipping_state: function(val, oldVal)
            {
                if (this.shipping_state.length != '')
                {
                    this.state = true;
                    this.moduleLogic();

                } else {

                    this.state = false;
                    this.moduleLogic();
                }
            },

            shipping_zip: function(val, oldVal)
            {
                if (this.shipping_zip.length == 5)
                {
                    this.zip = true;
                    this.moduleLogic();

                } else {

                    this.zip = false;
                    this.moduleLogic();
                }
            },

            same_as_billing: function(val, oldVal)
            {
                if (this.same_as_billing == true)
                {
                    this.shiptoname       = this.billing.billtoname;
                    this.shipping_phone   = this.billing.billing_phone;
                    this.shipping_address = this.billing.billing_address;
                    this.shipping_city    = this.billing.billing_city;
                    this.shipping_state   = this.billing.billing_state;
                    this.shipping_zip     = this.billing.billing_zip;
                    // Check if we need to recalculate delivery quote

                } else if (this.same_as_billing == false && this.quote_zip != '') {

                    this.shiptoname       = '';
                    this.shipping_phone   = '';
                    this.shipping_address = '';
                    this.shipping_city    = this.quote_city;
                    this.shipping_state   = this.quote_state;
                    this.shipping_zip     = this.quote_zip;

                } else{

                    this.shiptoname       = '';
                    this.shipping_phone   = '';
                    this.shipping_address = '';
                    this.shipping_city    = '';
                    this.shipping_state   = '';
                    this.shipping_zip     = '';
                    // calculate delivery quote
                }
            },

            

            

        },
        
        methods: {
            moduleLogic ()
            {
                this.checkShippingForm();
                this.checkIfRecalculationNeeded();
                this.calculateTotal();

                if (this.shippingFormIsCompleted == true && this.recalculationNeeded == false)
                {
                    this.displayFakeButton = false;
                    this.displayRealButton = true;
                    this.displayRecalculateForm = false;

                } else if (this.shippingFormIsCompleted == true && this.recalculationNeeded == true) {

                    this.displayFakeButton = false;
                    this.displayRealButton = false;
                    this.displayRecalculateForm = true;

                } else  {

                    this.displayFakeButton = true;
                    this.displayRealButton = false;
                    this.displayRecalculateForm = false;

                }


                // Logic to continue to Payment
            },

            checkShippingForm ()
            {
                if(this.name == true && this.phone == true && this.address == true && this.city == true && this.state == true && this.zip == true)
                {
                    this.shippingFormIsCompleted = true;

                } else {

                    this.shippingFormIsCompleted = false;
                }
            },

            checkIfRecalculationNeeded ()
            {
                if (this.shipping_city.toUpperCase() != this.quote_city.toUpperCase() || this.shipping_state != this.quote_state || this.shipping_zip != this.quote_zip)
                {
                    this.recalculationNeeded = true;

                } else {

                    this.recalculationNeeded = false;
                }
            },

            calculateTotal: function ()
            {

                if (this.recalculationNeeded == true || this.quote_price == '')
                {
                    this.show_estimated_total = this.cart_subtotal;
                    this.quote_label = 'Shipping/Delivery';
                    this.show_quote_price = 'TBD';
                    this.showQuoteDestinationDetails = false;

                } else {

                    if (this.quote_price == 'TBD' || this.quote_price == 'FREE')
                    {
                        this.show_quote_price     = this.quote_price;
                        this.show_estimated_total = this.cart_subtotal;
                        this.showQuoteDestinationDetails = false;

                    } else {

                        this.show_quote_price = '$' + this.quote_price;
                        // this.quote_label = this.delivery.quote_label;
                        var subtotal_num = parseFloat(this.cart_subtotal);
                        var deliverypice_num = parseFloat(this.quote_price);
                        var total = subtotal_num + deliverypice_num;
                        this.show_estimated_total = total.toFixed(2);
                        this.showQuoteDestinationDetails = true;
                    }
                }
                
            },

            sendRequest ()
            {
                var that = this;
                this.displayRecalculateForm = false;
                this.displaySpinner    = true;
                this.displayNewQoute   = false;
                this.selectedQuoteID   = '';
                axios.post('/get-shipping-quote', {
                    city:  this.shipping_city,
                    state: this.shipping_state,
                    zip:   this.shipping_zip,
                    subtotal: this.cart_subtotal,
                })

                .then(this.onSuccess)

                .catch(function (error) {
                    console.log(error);
                    this.displaySpinner  = false;
                    this.displayRecalculateForm = false;
                    this.displayNewQoute = true;
                });

            },

            onSuccess (response)
            {
                this.quotes = response.data;
                this.displayNewQoute = true;
                this.selectedQuoteID   = '';
                this.show_quote_price = 'TBD';
                this.quote_label = 'Shipping/Delivery';
                this.displayRecalculateForm = false;
                this.displaySpinner = false;
            },

            updateState: function()
            {
                this.selectedQuoteID = this.childQuoteID;

                // Rest of Quote Variables
                this.quote_price   = this.quotes[this.selectedQuoteID]['cost'];
                this.quote_label   = this.quotes[this.selectedQuoteID]['label'];
                this.quote_carrier = this.quotes[this.selectedQuoteID]['carrier'];
                this.quote_number  = this.quotes[this.selectedQuoteID]['number'];
                this.quote_city    = this.quotes[this.selectedQuoteID]['city'];
                this.quote_state   = this.quotes[this.selectedQuoteID]['state'];
                this.quote_zip     = this.quotes[this.selectedQuoteID]['zip'];
                this.quote_description = this.quotes[this.selectedQuoteID]['description'];

                this.displayNewQoute = false;
                this.moduleLogic();
            },

        },


    }
</script>
