<template>
    <div>
        <div class="mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">
            
            <h2 class="text-2xl mb-6 text-blue-700 text-center">
                Cart Summary
            </h2>
            
            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400">
                <p class="text-gray-600 italic">
                    Subtotal:
                </p>
                
                <p class="text-lg font-semibold">
                    ${{ shoppingCartSummary.subtotal }}
                </p>     
            </div>

            <div v-if="shoppingCartSummary.shield != 0" class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <p class="text-gray-600 italic">
                    Protection:
                </p>
                
                <p class="text-lg font-semibold">
                    ${{ shoppingCartSummary.shield }}
                </p>     
            </div>

            <div v-if="checkoutProcess.synchronyApproved == 'success'" class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <p class="text-gray-600 italic">
                    Synchrony Credit:
                </p>
                
                <p class="text-lg font-semibold">
                    - ${{ shoppingCartSummary.synchrony_amount }}
                </p>     
            </div>

            <div v-if="this.coupon != ''" class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <p class="text-gray-600 italic">
                    Discount:
                </p>
                
                <p class="text-lg font-semibold">
                    -$0.00
                </p>     
            </div>

            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <p class="text-gray-600 italic">
                    {{ taxLable }}
                </p>
                
                <p class="text-lg font-semibold">
                    {{ showTax }}
                </p>     
            </div>

            <div class="flex justify-between mb-2 pb-2 border-b border-gray-400"> 
                <p class="text-gray-600 italic">
                    {{ shippingQuote.quote_label }}:
                </p>
                
                <p class="text-lg font-semibold">
                    {{ shippingQuote.display_quote_price }}
                </p>     
            </div>

            <div class="flex justify-between mb-10 pb-2 border-b border-gray-400"> 
                <p class="text-gray-600 italic">
                    {{ estimatedTotalLabel }}
                </p>
                
                <p class="text-lg font-semibold">
                    ${{ estimatedTotal }}
                </p>     
            </div>

            <div class="mb-4 text-center" v-if="shippingQuote.display_quote_price === 'TBD'">
                <a href="#calculate-shipping" 
                    class="inline-block px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300">
                    Get Shipping Quote
                </a>
            </div>

            <div class="text-center" v-if=" shippingQuote.display_quote_price != 'TBD' ">

                <div v-if="this.displayLoginButton" 
                    @click="showLoginModal" 
                    class="inline-block mx-auto mb-4 px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300">
                    Checkout
                </div>

                <div v-if="this.displayConfirmBillingButton" 
                    @click="showConfirmBillingModal" 
                    class="inline-block mx-auto mb-4 px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300">
                    Continue Checkout
                </div>

                <div v-if="this.displayConfirmShippingButton" 
                    @click="showConfirmShippingModal" 
                    class="inline-block mx-auto mb-4 px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300">
                    Continue Checkout
                </div>

                <div v-if="this.displayAcceptTermsButton" 
                    @click="showAcceptTermsModal" 
                    class="inline-block mx-auto mb-4 px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300">
                    Continue Checkout
                </div>

                <div v-if="this.displayPayNowButton"  class="inline-block mx-auto mb-4 ">
                    <div class="mb-4 content-center">
                        
                        <form :action="this.authorize_net.cart_payment_url" method="POST" id="paymentForm">
                    
                            <input type="hidden" name="_token" :value="csrf">
                            <input type="hidden" name="dataValue" id="dataValue">
                            <input type="hidden" name="dataDescriptor" id="dataDescriptor">
                            <input type="hidden" name="cart_id" :value="cartDetails.cart_id">
                            <input type="hidden" name="subtotal" :value="cartDetails.subtotal">
                            <input type="hidden" name="shield" :value="cartDetails.shield">
                            <input type="hidden" name="discount" :value="cartDetails.discount">
                            <input type="hidden" name="sub_subtotal" :value="cartDetails.sub_subtotal">
                            <input type="hidden" name="synchrony_amount" :value="cartDetails.synchrony_amount">
                            <input type="hidden" name="tax" :value="cartDetails.tax">
                            <input type="hidden" name="total" :value="cartDetails.total">
                            <input type="hidden" name="delcharge" :value="cartDetails.delcharge">
                            
                            <button
                                type="button"
                                class="AcceptUI px-4 py-2 rounded border border-blue-700 bg-blue-700 hover:bg-blue-100 text-white hover:text-blue-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300" 
                                data-billingAddressOptions='{"show":true, "required":false}' 
                                :data-apiLoginID="this.authorize_net.api_login_id" 
                                :data-clientKey="this.authorize_net.client_key" 
                                data-acceptUIFormBtnTxt="Pay Now" 
                                data-acceptUIFormHeaderTxt="Card Information" 
                                data-responseHandler="responseHandler" 
                                id="btnSubmit">
                                Pay With Credit Card
                            </button>
                        </form>

                    </div>

                    <div class="mb-8 text-center">
                        <p class="mb-2 text-center italic">
                            We accept all major credit cards
                        </p>
                        <img src="/img/icons/credit-cards.webp" 
                            class="h-8 mx-auto" 
                            alt="Acceptable credit card logos" />
                    </div>


                    <div v-if="checkoutProcess.synchronyApproved != 'success' && ! this.progressiveLeasingApproved" class="content-center">
                        <div class="flex justify-center items-center mb-6 text-2xl text-center text-gray-500">
                            <hr class="block w-full mx-4 border-b border-gray-400">
                            <p class="text-sm whitespace-nowrap">
                                OR
                            </p>
                            <hr class="block w-full mx-4 border-b border-gray-400">
                        </div>

                        <p class="mb-2 text-lg">
                            Pay with Synchrony HOME card.
                        </p>

                        <div @click="chooseSynchronyOption" class="inline-block mb-4 cursor-pointer">
                            <img src="/img/icons/pay-with-synchrony.png" 
                                alt="Pay With Synchrony HOME Card" 
                                class="object-contain transform transition duration-500 hover:scale-105 hover:shadow-xl" 
                            />
                        </div>

                        <div class="mb-6 text-center">
                            <div class="mb-2 text-lg">
                                Don't have Synchrony HOME account?
                            </div>
                            <a 
                                href="https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=82FDD7321D7D37B53EEB22C90DE0AECD25DF9E052A7F8934&client=The%20Furniture%20Warehouse" 
                                target="_blank" 
                                class="inline-block mx-auto px-4 py-2 rounded border border-gray-600 bg-gray-600 hover:bg-gray-100 text-white hover:text-gray-900 font-semibold uppercase tracking-wider cursor-pointer shadow-lg transition ease-in-out duration-300" 
                            >
                               Apply Now
                            </a>
                        </div>
                    </div>

                    <div v-if="this.displayProgressiveLeasing && checkoutProcess.synchronyApproved != 'success'">
                        <div class="flex justify-center items-center mb-6 text-2xl text-center text-gray-500">
                            <hr class="block w-full mx-4 border-b border-gray-400">
                            <p class="text-sm whitespace-nowrap">
                                OR
                            </p>
                            <hr class="block w-full mx-4 border-b border-gray-400">
                        </div>

                        <div class="mb-4 text-center">
                            <prog-connect></prog-connect>
                        </div>
                    </div>

                    <div v-if="checkoutProcess.synchronyApproved == 'success'" class="flex items-center mb-2 lg:mb-4 p-4 bg-red-200 border border-red-500 rounded sm:shadow-all-around-10">
                        <div class="text-base text-red-800">
                            Your shopping cart is locked for modification after Synchrony Bank approval.
                            <br>
                            Please pay the remaining balance.
                        </div>
                    </div>

                </div>

            </div>

            <div id="dbuymodel3"></div>

        </div>

        <login-modal></login-modal>
        <reset-password-modal></reset-password-modal>
        <create-account-modal></create-account-modal>
        <confirm-billing-modal :states="this.states"></confirm-billing-modal>
        <confirm-shipping-modal :states="this.states"></confirm-shipping-modal>
        <accept-terms-modal></accept-terms-modal>
        <synchrony-options-modal
            :financing_options="this.financing_options">
        </synchrony-options-modal>

    </div>
</template>

<script>

import LoginModal from './LoginModal.vue';
import ResetPasswordModal from './ResetPasswordModal.vue';
import CreateAccountModal from './CreateAccountModal.vue';
import ConfirmBillingModal from './ConfirmBillingModal.vue';
import ConfirmShippingModal from './ConfirmShippingModal.vue';
import AcceptTermsModal from './AcceptTermsModal.vue';
import SynchronyOptionsModal from './SynchronyFinancingOptionsModal.vue';

import { initializeProgressive } from '../progressive_connect.js';

export default {

    components: {
        'login-modal': LoginModal,
        'reset-password-modal': ResetPasswordModal,
        'create-account-modal': CreateAccountModal,
        'confirm-billing-modal': ConfirmBillingModal,
        'confirm-shipping-modal': ConfirmShippingModal,
        'accept-terms-modal': AcceptTermsModal,
        'synchrony-options-modal': SynchronyOptionsModal,
        },

    props: [
        'states',
        'cart_summary',
        'authorize_net',
        'checkout_process',
        'financing_options',
        'cart_items',
        'cart_shield_product',
        'all_shield_products',
        'cart_settings'
    ],

    data: function () {
        return {
            sales_tax: '',  // (without $ sign) to pass further
            taxLable: 'Estimated Tax:',
            showTax: 'TBD',  // It can contain a ($) when calculated
            coupon: '',
            taxAndDelivery: '',

            // Button Logic Defaults
            displayGetQuoteButton: true,
            displayLoginButton: false,
            displayConfirmBillingButton: false,
            displayConfirmShippingButton: false,
            displayAcceptTermsButton: false,
            displayPayNowButton: false,

            estimatedTotal: this.cart_summary.subtotal,
            estimatedTotalLabel: 'Estimated Total:',
            
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),

            // for Vuex store cartDetails
            cartDetails: {
                subtotal: this.cart_summary.items_subtotal,
                shield: this.cart_summary.shield,
                discount: this.cart_summary.discount,
                sub_subtotal: this.cart_summary.subtotal,
                synchrony_amount: this.cart_summary.synchrony_amount,
                delcharge: 'TBD',
                tax: 'TBD',
                total: 'TBD',
                cart_id: this.cart_summary.cart_id,
            },

            // For Synchrony Modals
            auth_api: '',
            status_api: '',
            tokenId: '',
            merchantID: '',
            clientTransId: '',  // Timestamp plus Shopping Cart ID

            displayProgressiveLeasing: this.cart_settings.progressive_module,
            progressiveLeasingApproved: false,

            
            progressiveLeasing: {
                demoKey: '04dc4cb8-92d4-11ee-b046-02db1b8c3607'
            },

            progLeasingApi: {},
            // ProgConnect: {
                
            // },
            // checkout: {},
        }
    },
    
    computed: {

        shoppingCartSummary: {
            get() {
                return this.$store.state.cartDetails.cartSummary;
            }
        },

        shippingQuote: {
            get() {
                return this.$store.state.cartShipping.shippingQuote;
            }
        },

        userInfo: {
            get() {
                return this.$store.state.cartUserBillingShipping.userBillingShipping;
            }
        },

        checkoutProcess: {
            get() {
                return this.$store.state.cartCheckoutProcess.checkoutSteps;
            }
        },

        shieldProduct: {
            get() {
                return this.$store.state.cartShield.shieldProduct;
            }
        }
    },

    mounted() {

        this.$store.dispatch('cartDetails/initializeCartSummary', this.cart_summary);

        this.$store.dispatch('cartCheckoutProcess/initializeCheckoutSteps', this.checkout_process);

        this.$store.dispatch('cartShield/initializeCartShield', this.cart_shield_product);

        this.progLeasingApi = initializeProgressive(this.progressiveLeasing);

        // this.continueCheckoutSteps();  // the watcher does the job

    },

    created() {

        // Listener for Synchrony modal close event
        this.modalEventHandler();
    },

    watch: {

        shippingQuote: {
            handler(val) {
                
                this.calculateTotal();
            },

            deep: true
        },

        shoppingCartSummary: {
            handler(val) {
                
                this.calculateTotal();
            },

            deep: true
        },

        checkoutProcess: {
            handler(val) {
                
                this.continueCheckoutSteps();
            },

            deep: true
        }
    },

    methods:{

        calculateTotal: function ()
        {
            this.calculateTax();

            let subtotal_num      = parseFloat(this.shoppingCartSummary.subtotal);
            let shield_num        = parseFloat(this.shoppingCartSummary.shield);
            // let sub_subtotal_num  = parseFloat(this.shoppingCartSummary.sub_subtotal);
            let synchrony_amount  = parseFloat(this.shoppingCartSummary.synchrony_amount);
            let deliveryprice_num = parseFloat(this.shippingQuote.quote_price);

            let sub_subtotal_num = subtotal_num + shield_num;

            if (this.shippingQuote.quote_price != 'TBD' && this.shippingQuote.quote_price != 'FREE') 
            {

                if (this.showTax != 'TBD' && this.showTax != '$0.00') 
                {
                    let tax_total = parseFloat(this.sales_tax);

                    let total = subtotal_num + shield_num - synchrony_amount + deliveryprice_num + tax_total;
                    
                    this.estimatedTotal      = total.toFixed(2);
                    this.estimatedTotalLabel = 'Total:';

                } else {

                    let total = subtotal_num + shield_num - synchrony_amount + deliveryprice_num;
                    
                    this.estimatedTotal      = total.toFixed(2);
                    this.estimatedTotalLabel = 'Total:';
                }

            } else {

                if (this.showTax != 'TBD' && this.showTax != '$0.00') 
                {
                    // let subtotal_num      = parseFloat(this.shoppingCartSummary.subtotal);
                    // let synchrony_amount  = parseFloat(this.shoppingCartSummary.synchrony_amount);
                    let tax_total         = parseFloat(this.sales_tax);

                    let total = subtotal_num + shield_num - synchrony_amount + tax_total;
                    
                    this.estimatedTotal = total.toFixed(2);
                    this.estimatedTotalLabel = 'Total:';

                } else {

                    let total = subtotal_num + shield_num;
                    
                    this.estimatedTotal      = total.toFixed(2);

                    // this.estimatedTotal = this.shoppingCartSummary.subtotal;
                    this.estimatedTotalLabel = 'Estimated Total:';
                }
            }

            // update store
            this.cartDetails.sub_subtotal = sub_subtotal_num.toFixed(2);
            this.cartDetails.delcharge    = this.shippingQuote.quote_price,
            this.cartDetails.tax          = this.sales_tax,
            this.cartDetails.total        = this.estimatedTotal,

            this.$store.dispatch('cartDetails/updateCartSummary', this.cartDetails);

        },

        calculateTax: function ()
        {
            if (this.shippingQuote.tax_rate != 'TBD' && this.shippingQuote.tax_rate != '0') {
                
                let subtotal_num     = parseFloat(this.shoppingCartSummary.subtotal);
                let shield_num       = parseFloat(this.shoppingCartSummary.shield);
                let sub_subtotal_num = parseFloat(subtotal_num + shield_num);
                let tax_total        = parseFloat(sub_subtotal_num * this.shippingQuote.tax_rate / 100);
                this.sales_tax       = tax_total.toFixed(2);
                this.showTax         = '$' + this.sales_tax;
                this.taxLable        = 'Tax:';
            }

            if (this.shippingQuote.tax_rate == 0) {

                this.sales_tax   = 0;
                this.showTax     = '$0.00';
                this.taxLable    = 'Tax:';
            }

            if (this.shippingQuote.tax_rate == 'TBD') {

                this.sales_tax   = 0;
                this.showTax     = 'TBD';
                this.taxLable    = 'Tax:';
            }
        },

        continueCheckoutSteps: function()
        {
            // logic for checkout
            // -------------------
            // It changes the button function to display Modals [Login, ConfirmBilling, ConfirmSipping, AcceptTerms, Pay]
            // Each Modal will call the next one in the chain.
            // If user stops the modals then show continue button on the next step

            // 1) Make a Quote (if user logged-in, calculation is in the Controller)
            // 2) Log-in user 
            // 3) Confirm Billing
            // 4) Confirm Shipping
            // 5) Accept Terms
            // 6) Pay 

            if (this.checkoutProcess.quoteCompleted == 'yes' && this.checkoutProcess.userLoggedIn == 'no')
            {
                // display (Checkout) button. It opens (Log-in form) modal 
                this.displayGetQuoteButton        = false;
                this.displayLoginButton           = true;
                this.displayConfirmBillingButton  = false;
                this.displayConfirmShippingButton = false;
                this.displayAcceptTermsButton     = false;
                this.displayPayNowButton          = false;

            } else if (this.checkoutProcess.quoteCompleted == 'yes' && this.checkoutProcess.userLoggedIn == 'yes' && this.checkoutProcess.userBillingConfirmed == 'no' && this.checkoutProcess.synchronyApproved != 'success') {

                // display (Checkout) button. It opens (Confirm Billing) modal 
                this.displayGetQuoteButton        = false;
                this.displayLoginButton           = false;
                this.displayConfirmBillingButton  = true;
                this.displayConfirmShippingButton = false;
                this.displayAcceptTermsButton     = false;
                this.displayPayNowButton          = false;

            } else if (this.checkoutProcess.quoteCompleted == 'yes' && this.checkoutProcess.userLoggedIn == 'yes' && this.checkoutProcess.userBillingConfirmed == 'yes' && this.checkoutProcess.userShippingConfirmed == 'no' && this.checkoutProcess.synchronyApproved != 'success') {

                // display (Checkout) button. It opens (Confirm Shipping) modal
                this.displayGetQuoteButton        = false;
                this.displayLoginButton           = false;
                this.displayConfirmBillingButton  = false;
                this.displayConfirmShippingButton = true;
                this.displayAcceptTermsButton     = false;
                this.displayPayNowButton          = false;

            } else if (this.checkoutProcess.quoteCompleted == 'yes' && this.checkoutProcess.userLoggedIn == 'yes' && this.checkoutProcess.userBillingConfirmed == 'yes' && this.checkoutProcess.userShippingConfirmed == 'yes' && this.checkoutProcess.termsAccepted == 'no' && this.checkoutProcess.synchronyApproved != 'success') {

                // display (Checkout) button. It opens (Accept Terms) modal
                this.displayGetQuoteButton        = false;
                this.displayLoginButton           = false;
                this.displayConfirmBillingButton  = false;
                this.displayConfirmShippingButton = false;
                this.displayAcceptTermsButton     = true;
                this.displayPayNowButton          = false;

            } else if (this.checkoutProcess.quoteCompleted == 'yes' && this.checkoutProcess.userLoggedIn == 'yes' && this.checkoutProcess.userBillingConfirmed == 'yes' && this.checkoutProcess.userShippingConfirmed == 'yes' && this.checkoutProcess.termsAccepted == 'yes' && this.checkoutProcess.paymentFailed == 'no') {

                // display (Checkout) button. It opens ( Pay Now) modal
                this.displayGetQuoteButton        = false;
                this.displayLoginButton           = false;
                this.displayConfirmBillingButton  = false;
                this.displayConfirmShippingButton = false;
                this.displayAcceptTermsButton     = false;
                this.displayPayNowButton          = true;

                this.loadAuthorizeAcceptUI();

            } else if (this.checkoutProcess.paymentFailed == 'yes') {

                // if payment failed display (confirm Billing)
                this.displayGetQuoteButton        = false;
                this.displayLoginButton           = false;
                this.displayConfirmBillingButton  = true;
                this.displayConfirmShippingButton = false;
                this.displayAcceptTermsButton     = false;
                this.displayPayNowButton          = false;

            } else {

                // Initial Stage
                // display (Get Quote) button
                this.displayGetQuoteButton        = true;
                this.displayLoginButton           = false;
                this.displayConfirmBillingButton  = false;
                this.displayConfirmShippingButton = false;
                this.displayAcceptTermsButton     = false;
                this.displayPayNowButton          = false;
            }

            // On Synchrony Changes
            if (this.checkoutProcess.synchronyOptionSelected == 'yes' && this.checkoutProcess.synchronyApproved != 'success')
            {
                // Show Synchrony Modal
                this.payWithSynchronyCard();
            }

            // On Synchrony Changes
            if (this.checkoutProcess.synchronyApproved == 'success')
            {
                // Recalculate Total
                this.calculateTotal();
            }
        },

        loadAuthorizeAcceptUI()
        {
            let script = document.createElement('script');
            script.src = 'https://js.authorize.net/v3/AcceptUI.js';
            document.body.appendChild(script);

            // Button handler
            let script_2 = document.createElement('script');
            script_2.src = '/js/authorize_net.js';
            document.body.appendChild(script_2);
        },

        submitPayment ()
        {
            // Do nothing
            // Authorize dot net JS will do the rest
            // alert('click');
        },

        // Modals
        showLoginModal: function()
        {
            this.$modal.show('login-modal');
        },

        hideLoginModal: function()
        {
            this.$modal.hide('login-modal');
        },

        showConfirmBillingModal: function()
        {
            this.$modal.show('confirm-billing-modal');
        },

        hideConfirmBillingModal: function()
        {
            this.$modal.hide('confirm-billing-modal');
        },

        showConfirmShippingModal: function()
        {
            this.$modal.show('confirm-shipping-modal');
        },

        hideConfirmShippingModal: function()
        {
            this.$modal.hide('confirm-shipping-modal');
        },

        showAcceptTermsModal: function()
        {
            this.$modal.show('accept-terms-modal');
        },

        hideAcceptTermsModal: function()
        {
            this.$modal.hide('accept-terms-modal');
        },

        showPayNowModal: function()
        {
            this.$modal.show('pay-now-modal');
        },

        hidePayNowModal: function()
        {
            this.$modal.hide('pay-now-modal');
        },

        showSynchronyOptionsModal: function()
        {
            this.$modal.show('synchrony-options-modal');
        },

        hideSynchronyOptionsModal: function()
        {
            this.$modal.hide('synchrony-options-modal');
        },

        chooseSynchronyOption: function()
        {
            this.$modal.show('synchrony-options-modal');
        },

        payWithSynchronyCard: function()
        {
            let that = this;
            // this.hideSynchronyOptionsModal();
            // this.showSpinnerModal();

            // Update Checkout Process
            // to stop firing this function on every checkoutProcess change
            this.checkoutProcess.synchronyOptionSelected = 'no';
            this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);

            axios.post('/synchrony-authentication', {
                _token:   this.csrf,
                synchrony_code: this.checkoutProcess.synchronyCode,
            })

            .then(this.onSuccess)

            .catch(error => {
                if (error.response.status == 422) {
                    
                    this.hideSpinnerModal();

                    Swal.fire({
                        title: 'Error.',
                        html:
                            '<div style="margin:30px;"></div>' +
                            '<p class="text-lg leading-relaxed">' +
                            error.response.data.errors +
                            '</p>',
                        focusConfirm: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: true,
                    });
                }
            });

            // .catch(function (error) {
            //     // console.log(error);
            //     this.hideSpinnerModal();

            //     Swal.fire({
            //         title: 'Error.',
            //         html:
            //             '<div style="margin:30px;"></div>' +
            //             '<p class="text-lg leading-relaxed">' +
            //             'We are experiencing network connectivity issue. Our team is working to resolve the problem. ' + 
            //             '<br><br>Please try again later.' +
            //             '</p>',
            //         focusConfirm: false,
            //         allowOutsideClick: false,
            //         allowEscapeKey: false,
            //         showConfirmButton: true,
            //     });
            // });
        },

        onSuccess (response)
        {
            this.hideSpinnerModal();

            this.auth_api = response.data;

            // Get Response Vars (they are valid for 30 min)
            
            let JSONObject = {};
            // REQUIRED
            JSONObject.tokenId       = this.auth_api['tokenId'];
            JSONObject.merchantID    = this.auth_api['merchantID'];
            JSONObject.processInd    = 3;
            JSONObject.clientTransId = this.auth_api['clientTransId'];
            JSONObject.custAddress1  = this.userInfo.billing_address;
            JSONObject.custCity      = this.userInfo.billing_city;
            JSONObject.custState     = this.userInfo.billing_state;
            JSONObject.custZipCode   = this.userInfo.billing_zip;
            // TEST
            // JSONObject.custAddress1  = '950 FORRER BLVD';
            // JSONObject.custCity      = 'KETTERING';
            // JSONObject.custState     = 'OH';
            // JSONObject.custZipCode   = '45420';
            JSONObject.transPromo1   = this.checkoutProcess.synchronyCode;
            JSONObject.transAmount1  = this.shoppingCartSummary.subtotal;
            // OPTIONAL
            // JSONObject.custFirstName = this.auth_api['custFirstName'];
            // JSONObject.custLastName  = this.auth_api['custLastName'];
            // JSONObject.custFirstName = 'HERNAN';
            // JSONObject.custLastName  = 'CORTES';
            JSONObject.iniPurAmt     = this.shoppingCartSummary.subtotal;
            // JSONObject.phoneNumber   = this.auth_api['phoneNumber'];
            // JSONObject.emailAddress  = this.auth_api['emailAddress'];

            this.tokenId       = this.auth_api['tokenId'];
            this.merchantID    = this.auth_api['merchantID'];
            this.clientTransId = this.auth_api['clientTransId'];

            // Call Synchrony Modal 3
            syfDBuy.calldBuyProcess(null, JSONObject);

        },

        showSpinnerModal: function()
        {
            this.$modal.show('spinner');
        },

        hideSpinnerModal: function()
        {
            this.$modal.hide('spinner');
        },

        modalEventHandler: function()
        {
            window.addEventListener("message", function(event) 
            {
                // let self = this;

                if (typeof event.data == 'string' && (event.data == 'Close Model' || event.data == 'Return To Merchant Shipping')) 
                {
                    console.log('SYNCHRONY MODAL CLOSED', event);
                    
                    this.onModalClose();
                    
                }

            }.bind(this));
        },

        onModalClose: function()
        {
            let that = this;
            this.showSpinnerModal();

            axios.post('/synchrony-status', {
                _token:   this.csrf,
                tokenId: this.tokenId,
                merchantID: this.merchantID,
                clientTransId: this.clientTransId,
                transactionAmount: this.cartDetails.sub_subtotal,
                billing_address: this.userInfo.billing_address,
                billing_city: this.userInfo.billing_city,
                billing_state: this.userInfo.billing_state,
                billing_zip: this.userInfo.billing_zip,
                // TEST //
                // billing_address: '950 FORRER BLVD',
                // billing_city: 'KETTERING',
                // billing_state: 'OH',
                // billing_zip: '45420',
                subtotal: this.shoppingCartSummary.subtotal,
                shield: this.cartDetails.shield,
                discount: this.cartDetails.discount,
                sub_subtotal: this.cartDetails.sub_subtotal,
                tax: this.cartDetails.tax,
                delcharge: this.cartDetails.delcharge,
                total: this.cartDetails.total,
                promotionCode: this.checkoutProcess.synchronyCode,
            })

            .then(this.onStatusSuccess)

            .catch(this.onStatusError);
        },

        onStatusError: function(error)
        {
            this.hideSpinnerModal();

            if (error.response.status == 422) {
                
                Swal.fire({
                    title: 'Error.',
                    html:
                        '<div style="margin:30px;"></div>' +
                        '<p class="text-lg leading-relaxed">' +
                        error.response.data.errors +
                        '</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: true,
                });
            }
        },

        onStatusSuccess: function(response)
        {
            this.hideSpinnerModal();

            // Display Message
            this.status_api = response.data;
            // alert(this.status_api['responseDesc']);

            if (this.status_api['statusCode'] == '000')
            {
                // Calculate remaining ballance
                let tax = parseFloat(this.shoppingCartSummary.tax);
                let down_payment = 0;

                if (this.shoppingCartSummary.delcharge != 'FREE')
                {
                    let delivery     = parseFloat(this.shoppingCartSummary.delcharge);
                    down_payment = tax + delivery;

                } else {

                    down_payment = tax;
                }

                this.taxAndDelivery = down_payment.toFixed(2);

                Swal.fire({
                    title: 'Success!',
                    html:
                        '<div style="margin:30px;"></div>' +
                        '<p class="text-lg leading-relaxed">' +
                        'Synchrony Bank approved the transaction of $' + 
                        this.status_api['amount'] +
                        '<br><br>Please pay the remaining balance of $' +
                        this.taxAndDelivery +
                        ' by bank credit card.' +
                        '</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: true,
                });

                // Update Checkout Process
                this.checkoutProcess.synchronyApproved = 'success';
                this.$store.dispatch('cartCheckoutProcess/updateCheckoutSteps', this.checkoutProcess);

                // Update Cart Details
                this.cartDetails.synchrony_amount = this.status_api['amount'];
                this.$store.dispatch('cartDetails/updateCartSummary', this.cartDetails);

            } else if (this.status_api['statusCode'] == '001') {

                Swal.fire({
                    title: 'We are sorry.',
                    html:
                        '<div style="margin:30px;"></div>' +
                        '<p class="text-lg leading-relaxed">' +
                        'Your request is declined. Please choose the alternative payment method. ' +
                        '<br><br>Additional information may be needed please call us at 941-359-2255 ext 305.' +
                        '</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: true,
                });

            } else if (this.status_api['statusCode'] == '111') {

                Swal.fire({
                    title: 'Error.',
                    html:
                        '<div style="margin:30px;"></div>' +
                        '<p class="text-lg leading-relaxed">' +
                        this.status_api['message'] +
                        '</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: true,
                });

            } else {

                Swal.fire({
                    title: 'Error.',
                    html:
                        '<div style="margin:30px;"></div>' +
                        '<p class="text-lg leading-relaxed">' +
                        this.status_api['StatusMessage'] + 
                        '</p>',
                    focusConfirm: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: true,
                });
            }


        },

        formatItemTotal (value)
        {
            return parseFloat(value).toFixed(2);
        },

        // progLeasingApi.checkout.onCancel ()
        // {
        //     Swal.fire({
        //         title: 'Success!',
        //         html:
        //             '<div style="margin:30px;"></div>' +
        //             '<p class="text-lg leading-relaxed">' +
        //             'Your API demo key ' +
        //             '<br><br>Continue to the next step.' +
        //             '</p>',
        //         focusConfirm: false,
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         showConfirmButton: true,
        //     });
        // },


        // testErrorMessage: function()
        // {
        //     Swal.fire({
        //         title: 'We are sorry.',
        //         html:
        //             '<div style="margin:30px;"></div>' +
        //             '<p class="text-lg leading-relaxed">' +
        //             'Your request is declined. Please choose the alternative payment method. ' +
        //             '<br><br>Additional information may be needed please call us at 941-359-2255 ext 305.' +
        //             '</p>',
        //         focusConfirm: false,
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         showConfirmButton: true,
        //     });
        // },  
        
    },

    

}



</script>
