<template>
    <nav v-if="pagination && data_length != 0 && pagination.last_page != 1" class="mb-8"  aria-label="pagination">
        <div class="flex justify-center text-gray-700 text-lg">

            <!-- Pagination Elements -->
            <div v-for="(element, index) in pagination.links" :key="index" class="flex justify-center items-center">

                <!-- Previous Page Link -->
                <div v-if="index == 0">
                    <a v-if="pagination.prev_page_url !== null" class="" :href="pagination.prev_page_url" rel="prev" aria-label="Previous Page">
                        <div class="px-3 py-1 border border-gray-300 hover:bg-blue-300 hover:border-blue-300">
                            &lsaquo;
                        </div>
                    </a>
                </div>

                <!-- Next Page Link -->
                <div v-else-if="index == (pagination_length - 1)">
                    <a v-if="pagination.current_page != pagination.last_page" class="" :href="pagination.next_page_url" rel="next" aria-label="Next Page">
                        <div class="px-3 py-1 border border-gray-300 hover:bg-blue-300 hover:border-blue-300">
                            &rsaquo;
                        </div>
                    </a>
                </div>

                <!-- Array of Links -->
                <div v-else class="flex justify-center items-center">
                    <!-- Current Page -->
                    <div v-if="element.label == pagination.current_page">
                        <!-- First Item -->
                        <div v-if="element.label == 1" class="flex justify-center items-center">
                            <div class="px-3 py-1 border border-blue-700 bg-blue-700 text-white" aria-current="page">
                                <span class="page-link">
                                    {{ element.label }}
                                </span>
                            </div>
                            <div class="sm:hidden px-3 py-1 border border-gray-300 disabled" aria-disabled="true">
                                <span class="page-link">
                                    ...
                                </span>
                            </div>
                        </div>
                        <!-- Last Item -->
                        <div v-else-if="element.label == pagination.last_page" class="flex justify-center items-center">
                            <div class="sm:hidden px-3 py-1 border border-gray-300 disabled" aria-disabled="true">
                                <span class="page-link">
                                    ...
                                </span>
                            </div>
                            <div class="px-3 py-1 border border-blue-700 bg-blue-700 text-white" aria-current="page">
                                <span class="page-link">
                                    {{ element.label }}
                                </span>
                            </div>
                        </div>

                        <!-- Between Links -->
                        <div v-else class="flex justify-center items-center">
                            <div class="sm:hidden px-3 py-1 border border-gray-300 disabled" aria-disabled="true">
                                <span class="page-link">
                                    ...
                                </span>
                            </div>
                            <div class="px-3 py-1 border border-blue-700 bg-blue-700 text-white" aria-current="page">
                                <span class="page-link">
                                    {{ element.label }}
                                </span>
                            </div>
                            <div class="sm:hidden px-3 py-1 border border-gray-300 disabled" aria-disabled="true">
                                <span class="page-link">
                                    ...
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                         <a class="hidden sm:inline-block" :href="element.url">
                            <div class="px-3 py-1 border border-gray-300 hover:bg-blue-300 hover:border-blue-300">
                                {{ element.label }}
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </nav>
</template>

<script>

    export default {


        props: [
            'pagination',
            // 'shield_eligibility'
        ],

        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                pagination_length: this.pagination.links.length,
                data_length: this.pagination.data.length,
            }
        },
        
        methods:{
            
        
        }
    }
</script>