function initializeProgressive(progressiveLeasing) {

    ProgConnect.onPaymentClick(() => {
        var checkout = {
            merchant: {
                key: progressiveLeasing.demoKey, 
                siteConfig: {
                    cardNumber: "#card-number-selector",
                    cvv: "#cvv-selector",
                    expiration: "#expiration-selector",
                    name: "#name-on-card-selector",
                    address: "#address-on-card-selector",
                    city: "#city-on-card-selector",
                    state: "#state-on-card-selector",
                    zip: "#zip-selector"
                }
            },
            customer: {
                id: "32355323456",
                firstName: "John",
                lastName: "Doe",
                email: "john.doe@example.com",
                phone: "5555555554",
                address: {
                    shipping: {
                        line1: "123 Main Street",
                        city: "No Where",
                        state: "UT",
                        zip: "84555"
                    },
                    billing: {}
                }
            },
            cart: {
                orderId: "5423344523",
                items: [
                    {
                        brand: "Apple",
                        description: "iPhone SE 64GB Midnight",
                        category: "Cell Phones",
                        identifier: "MMX53LL/A",
                        imgUrl: "",
                        unitPrice: 429.00,
                        tax: 31.10,
                        quantity: 1,
                        condition: "new",
                        deliveryDate: "2023-08-30"
                    },
                    {
                        brand: "Apple",
                        description: "AppleCare+ for iPhone SE (3rd generation)",
                        category: "Warranty",
                        identifier: "sEH62LL/A",
                        imgUrl: "",
                        unitPrice: 79.00,
                        tax: 0,
                        quantity: 1,
                        condition: "new",
                        deliveryDate: "2023-08-21"
                    }
                ],
                deliveryCost: 10.99
            },
            callbacks: {
                onFailure: function (e) {
                    // handle the failure as needed
                    console.log(e);
                    alert('Something has gone wrong.');
                },
                onSuccess: function (s) {
                    SaveLeaseId(s.id);
                    SaveDeliveryConfirmationUrl(s.deliveryConfirmationUrl);
                    SaveOrderConfirmationUrl(s.orderConfirmationUrl);   
                },
                onCancel: function () {
                    console.log(c);
                    alert('Customer terminated the application.');
                    progressiveLeasing.progressiveCanceled();
                    let demoKey = progressiveLeasing.demoKey;
                    console.log(demoKey);
                }
            }
        };
        return checkout;
    });

};

export { initializeProgressive };