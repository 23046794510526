<template>
    <div v-if="displayCouponModule" class="mb-10 px-2 py-3 border border-gray-400 rounded bg-gray-100">
        <h2 class="text-2xl mb-3 text-blue-700 text-center">
            Enter Coupon Code
        </h2>

        <form action="/apply-coupon" method="post" @submit.prevent="applyCoupon">
            
            <input type="text" v-model="coupon_code" class="appearance-none block w-full text-gray-700 border border-gray-400 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:border-gray-500" placeholder="Coupon Code">

            <div v-if="displayFakeButton" class="flex justify-center">
                <span class="px-4 py-2 mb-2 bg-gray-200 rounded border border-gray-400 cursor-not-allowed">
                    Enter A Valid Code
                </span>
            </div>

            <button
                v-if="displayRealButton" 
                class="block mx-auto mb-2 px-4 py-2 bg-green-400 rounded border border-green-500 shadow-md text-gray-900 hover:bg-green-300"
                type="submit">
                Apply Coupon
            </button>
        </form>
    </div>
</template>

<script>
    export default {
        mounted() {
            if (this.shoppingCartSummary.subtotal > 499.98) {

                this.displayCouponModule = true;

            } else {

                this.displayCouponModule = false;
            }
        },

        data: function() {
            return {
                displayCouponModule: '',
                displayFakeButton: true,
                displayRealButton: false,
                coupon_code: '',

            }
        },

        watch: {
            coupon_code: function(val, oldVal)
            {
                if (this.coupon_code.length < 7)
                {
                    this.displayFakeButton = true;
                    this.displayRealButton = false;

                } else {

                    this.displayFakeButton = false;
                    this.displayRealButton = true;
                }
            },
        },

        computed: {
            shoppingCartSummary: {
                get() {
                    return this.$store.state.cartDetails.cartSummary;
                }
            },
        }
    }
</script>
