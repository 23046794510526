<template>
    <div class="max-w-screen-sm mx-auto my-8 p-1 sm:my-12 sm:p-2 sm:border sm:border-gray-500 sm:rounded sm:shadow-all-around-15">
    
        <div class="w-full px-2 sm:px-4 text-base text-gray-700">

            <h1 class="text-3xl mt-2 mb-3 text-blue-700">
                Change Password
            </h1>

            <p class="mb-5">
                Your new password must be at least 8 characters long.
            </p>

             <div v-if="displaySuccessMessage" class="flex items-center mb-8 p-4 bg-green-200 border border-green-500 rounded">
                <div class="ml-0 sm:ml-4">
                    <h4 class="mb-2 text-xl text-green-700">Your password has been updated.</h4>
                </div>
            </div>

            <show-form-validation-errors v-if="validationErrors" :errors="validationErrors" class="my-4">

            </show-form-validation-errors>

            <form @submit.prevent="processPasswordUpdate">

                <slot></slot>
                
                <input 
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                    type="password" 
                    name="password" 
                    v-model="form.password" 
                    placeholder="Password (min 8 characters)" 
                    required>

                <input 
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                    type="password" 
                    name="password_confirmation" 
                    v-model="form.password_confirmation" 
                    placeholder="Confirm Password" 
                    required>


                <div v-if="displaySpinner">
                    <h2 class="text-2xl mb-4 text-blue-700 text-center">
                        Please wait.
                    </h2>
                    <div class="mb-4 text-center">

                        <svg class="mx-auto h-16 w-16 fill-current text-blue-700" version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                            <path d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
                            c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="2s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
                            c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1s" 
                                    from="0 50 50"
                                    to="360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                            <path d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
                            L82,35.7z">
                                <animateTransform 
                                    attributeName="transform" 
                                    attributeType="XML" 
                                    type="rotate"
                                    dur="1.5s" 
                                    from="0 50 50"
                                    to="-360 50 50" 
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                </div>

                <div v-if="! displaySpinner">
                    <button role="submit" v-if="! disableButton" class="inline-block px-6 py-2 mb-6 border border-blue-700 rounded bg-blue-600 uppercase tracking-wider text-white focus:shadow-outline focus:outline-none">
                        Update My Password
                    </button>
                    
                    <div v-if="disableButton" class="inline-block px-6 py-2 mb-6 border border-gray-400 rounded bg-gray-300 uppercase tracking-wider focus:shadow-outline focus:outline-none cursor-not-allowed">
                        Fill Out The Form
                    </div>
                </div>

            </form>

        </div>

    </div>
</template>


<script>

import ShowFormValidationErrors from './ShowFormValidationErrors.vue';

export default {

    components: {
        'show-form-validation-errors': ShowFormValidationErrors
    },

    data: function () {

        return {
            form: {
                password: '',
                password_confirmation: '',
            },
            watch_password: false,
            watch_password_confirmation: false,
            disableButton: true,
            displaySpinner: false,
            displaySuccessMessage: false,
            validationErrors: '',
        }
    },

    watch:{

        'form.password': function(val, oldVal)
        {
            if (this.form.password.length > 7)
            {
                this.watch_password = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_password = false;
            }
        },

        'form.password_confirmation': function(val, oldVal)
        {
            if (this.form.password_confirmation.length > 7)
            {
                this.watch_password_confirmation = true;
                this.checkFormVariables();

            } else {

                this.disableButton = true;
                this.watch_password_confirmation = false;
            }
        },
    },

    methods:{

        checkFormVariables ()
        {
            if(this.watch_password == true && this.watch_password_confirmation == true)
            {
                this.disableButton = false;
            }
        },

        processPasswordUpdate ()
        {
            this.validationErrors = '';
            this.displaySpinner   = true;

            axios.post('/myaccount/change-password', this.form)
                .then((response) => {

                    this.displaySuccessMessage      = true;
                    this.displaySpinner             = false;
                    this.disableButton              = true;
                    this.form.password_confirmation = '';
                    this.form.password              = '';

                })

                .catch((error) => {
                    
                    this.validationErrors = error.response.data.errors;
                    this.displaySpinner   = false;
                });
        },

    }
}

</script>
